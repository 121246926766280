import React, { useEffect } from "react";
import { Button, Space, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { fetchUpdateMe } from "../../../util/api";

export function SetupComplete({
  setPreviousStep,
}: {
  setPreviousStep: () => void;
}) {
  const navigate = useNavigate();

  useEffect(() => {
    fetchUpdateMe({ is_onboarded: true });
  }, []);

  return (
    <Space direction="vertical" size="large" style={{ display: "flex" }}>
      <div>
        <Typography.Title level={4} style={{ marginTop: 0 }}>
          {"You're all set up on Swyft 🎉"}
        </Typography.Title>

        <Typography.Text>
          Swyft runs in the background and will notify you with insights after
          your next recorded meeting. To view your recent meetings, click the
          button below.
        </Typography.Text>
      </div>

      <Space>
        <Button type="primary" onClick={() => navigate("/dashboard/meetings")}>
          View Meetings
        </Button>

        <Button type="link" onClick={() => setPreviousStep()}>
          Previous Step
        </Button>
      </Space>
    </Space>
  );
}
