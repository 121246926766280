import React from "react";
import { Typography, Form, Switch, FormInstance, Input, Space } from "antd";
import {
  InfoCircleOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
} from "@ant-design/icons";
import { FieldConfiguration } from "../../../../util/types";
import { DefaultValue } from "./DefaultValue";

interface Props {
  fieldConfiguration: FieldConfiguration;
  isAutomationEnabled: boolean;
  isEditing: boolean;
  form: FormInstance;
}

export function ScreeningConfig({
  fieldConfiguration,
  isAutomationEnabled,
  isEditing,
  form,
}: Props) {
  const isScreeningEnabled = Form.useWatch("screening_enabled", form);
  const isDefaultEnabled = Form.useWatch("screening_default_enabled", form);
  const isBooleanType = fieldConfiguration.field_type === "boolean";

  const screeningChange = (isScreening: boolean) => {
    form.setFieldValue("screening_enabled", isScreening);
    if (!isScreening) {
      // Clear form errors when toggle is turned off
      form.validateFields();
      defaultChange(false);
    }
  };

  const defaultChange = (isDefaulting: boolean) => {
    form.setFieldValue("screening_default_enabled", isDefaulting);
    if (!isDefaulting) {
      // Clear form errors when toggle is turned off
      form.validateFields();
    }
  };

  if (isBooleanType) {
    return (
      <Typography.Text italic>Cannot screen for boolean fields</Typography.Text>
    );
  }

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {isEditing ? (
        <>
          <Form.Item
            label={
              <Space>
                <Typography.Text>Enable Field Screening?</Typography.Text>
                <Typography.Link
                  type="secondary"
                  href={
                    "https://help.swyftai.com/en/articles/9811085-screening-prompts"
                  }
                  target="_blank"
                >
                  <InfoCircleOutlined />
                </Typography.Link>
              </Space>
            }
            name="screening_enabled"
            valuePropName={"checked"}
          >
            <Switch
              disabled={!isAutomationEnabled}
              onChange={screeningChange}
            />
          </Form.Item>

          <Form.Item
            label="Screening Prompt"
            name="screening_prompt"
            rules={[
              {
                required: isAutomationEnabled && isScreeningEnabled,
              },
            ]}
            extra={
              <div style={{ marginTop: "0.25rem" }}>
                <Typography.Link
                  type="secondary"
                  href={
                    "https://help.swyftai.com/en/articles/9811085-screening-prompts"
                  }
                  target="_blank"
                >
                  <InfoCircleOutlined /> Need help writing screening prompts?
                </Typography.Link>
              </div>
            }
            hidden={!isScreeningEnabled}
          >
            <Input.TextArea
              rows={3}
              maxLength={2000}
              placeholder="Enter a prompt to determine if the meeting contains information relevant to this field. Prompt should be answered with a yes or no."
            />
          </Form.Item>

          <Form.Item
            label="Apply default value if field is screened?"
            name="screening_default_enabled"
            valuePropName={"checked"}
            hidden={!isScreeningEnabled}
          >
            <Switch
              disabled={!isAutomationEnabled || !isScreeningEnabled}
              onChange={defaultChange}
            />
          </Form.Item>
        </>
      ) : (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              display: "flex",
              marginRight: "1rem",
              marginBottom: "1rem",
              marginTop: ".4rem",
              alignItems: "center",
            }}
          >
            {fieldConfiguration.screening_enabled ? (
              <>
                <CheckCircleTwoTone />
                <Typography.Text style={{ marginLeft: "0.5rem" }}>
                  Screening meeting to ensure insights exist before generation.
                  If topic is not discussed, insights will not be generated.
                </Typography.Text>
              </>
            ) : (
              <>
                <CloseCircleTwoTone twoToneColor="#ff4d4f" />
                <Typography.Text style={{ marginLeft: "0.5rem" }}>
                  Not screening meeting before generating content. Insight
                  generation will always be attempted.
                </Typography.Text>
              </>
            )}
          </div>
          <Typography.Text type="secondary" style={{ marginBottom: "1rem" }}>
            Screening Prompt:{" "}
            <Typography.Text>
              {" "}
              {fieldConfiguration.screening_prompt || "--"}
            </Typography.Text>
          </Typography.Text>
          <div
            style={{
              display: "flex",
              marginRight: "1rem",
              marginBottom: "1rem",
              marginTop: ".4rem",
              alignItems: "center",
            }}
          >
            {fieldConfiguration.screening_default_enabled ? (
              <>
                <CheckCircleTwoTone />
                <Typography.Text style={{ marginLeft: "0.5rem" }}>
                  Setting default value as insight generation if topic is not
                  discussed.
                </Typography.Text>
              </>
            ) : (
              <>
                <CloseCircleTwoTone twoToneColor="#ff4d4f" />
                <Typography.Text style={{ marginLeft: "0.5rem" }}>
                  Not setting a default value if the topic is not discussed.
                </Typography.Text>
              </>
            )}
          </div>
        </div>
      )}

      <DefaultValue
        fieldConfiguration={fieldConfiguration}
        isEditing={isEditing}
        isScreeningEnabled={isScreeningEnabled}
        isDefaultEnabled={isDefaultEnabled}
      />
    </div>
  );
}
