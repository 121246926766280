import React, { useEffect, useState } from "react";
import { Button, Form, message, Modal, Space } from "antd";
import { CRMFieldFormItem } from "../CRMFieldFormItem";
import { AutoGeneratedField, CRMField } from "../../../util/types";
import { useForm } from "antd/es/form/Form";
import { parseCRMValue } from "../../../util/parseCRMValue";
import {
  convertContentToJson,
  isContentPresent,
} from "../../../util/auto_generated_field_helpers";

interface AutoGeneratedFieldEditorModalProps {
  open: boolean;
  containerId: string;
  autoGeneratedField: AutoGeneratedField;
  crmField: CRMField;
  setOpen: (open: boolean) => void;
  handleAutoGeneratedFieldUpdate: (
    attributes: Partial<AutoGeneratedField>
  ) => void;
}

export function AutoGeneratedFieldEditorModal({
  open,
  containerId,
  autoGeneratedField,
  crmField,
  setOpen,
  handleAutoGeneratedFieldUpdate,
}: AutoGeneratedFieldEditorModalProps) {
  const [form] = useForm();

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const isAutoGeneratedValuePresent = isContentPresent(
    autoGeneratedField.content,
    autoGeneratedField.field_configuration.field_type
  );

  const parsedAutoGeneratedValue = parseCRMValue(
    autoGeneratedField.field_configuration.field_type,
    autoGeneratedField.content
  );

  const parsedCrmValue = parseCRMValue(
    crmField.crm_field_type,
    crmField.crm_field_value
  );

  useEffect(() => {
    if (!open) return;

    const value = isAutoGeneratedValuePresent
      ? parsedAutoGeneratedValue
      : parsedCrmValue;

    form.setFieldsValue({ [crmField.crm_field_name]: value });
  }, [open]);

  const handleSubmit = async () => {
    try {
      setIsSaving(true);

      const content = convertContentToJson(
        crmField.crm_field_type,
        form.getFieldValue(crmField.crm_field_name)
      );

      await handleAutoGeneratedFieldUpdate({
        accepted: true,
        user_modified_content: content,
      });

      setOpen(false);
    } catch {
      message.error("An error occurred. Please try again.");
    }

    setIsSaving(false);
  };

  const handleCancel = () => setOpen(false);

  return (
    <Modal
      getContainer={document.getElementById(containerId)}
      title="Edit new CRM value"
      open={open}
      onCancel={handleCancel}
      footer={null}
      destroyOnClose
      maskClosable={false}
    >
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <CRMFieldFormItem containerId={containerId} crmField={crmField} />

        <Space style={{ marginTop: 15 }}>
          <Form.Item>
            <Button loading={isSaving} htmlType="submit" type="primary">
              Save
            </Button>
          </Form.Item>

          <Form.Item>
            <Button onClick={handleCancel}>Cancel</Button>
          </Form.Item>
        </Space>
      </Form>
    </Modal>
  );
}
