import React from "react";
import { Button, Skeleton, Space, Typography } from "antd";
import { useIntegrationWindow } from "../../hooks/useIntegrationWindow";
import { useMyIntegrations } from "../../../util/data_hooks";
import { getFriendlyIntegrationLabel } from "../../../helpers/label_maps";
import { IntegrationLevel } from "../../../util/enums";

export function ConnectMeetingProvider({
  setNextStep,
}: {
  setNextStep: () => void;
}) {
  const { meetingIntegrations, isLoading } = useMyIntegrations();
  const [openIntegrationWindow] = useIntegrationWindow();

  if (isLoading) return <Skeleton active />;

  const meetingIntegration = meetingIntegrations.find(
    ({ level }) => level === IntegrationLevel.User
  );

  const meetingProviderLabel = getFriendlyIntegrationLabel(
    meetingIntegration?.name
  );

  if (!meetingIntegration) setNextStep();

  return (
    <Space direction="vertical" size="large" style={{ display: "flex" }}>
      <div>
        <Typography.Title level={4} style={{ marginTop: 0 }}>
          Connect {meetingProviderLabel}
        </Typography.Title>

        <Typography.Text>
          Swyft automatically transcribes and analyzes your recorded meetings to
          find values for fields in your CRM.
        </Typography.Text>
      </div>

      <Button
        type="primary"
        onClick={async () => {
          await openIntegrationWindow(meetingIntegration?.name);
          setNextStep();
        }}
      >
        Connect {meetingProviderLabel}
      </Button>
    </Space>
  );
}
