import React from "react";
import { Form, Input, InputNumber, Select, Switch } from "antd";

import { CRMFieldType } from "../../util/enums";

import DatePicker from "../shared/DatePicker";
import TimePicker from "../shared/TimePicker";
import { CRMField } from "../../util/types";

interface CRMFieldFormItemProps {
  containerId?: string;
  crmField: CRMField;
}

export function CRMFieldFormItem({
  containerId,
  crmField,
}: CRMFieldFormItemProps) {
  const {
    crm_field_name,
    crm_field_label,
    crm_field_type,
    crm_field_picklist_values,
    crm_field_required,
    crm_field_length,
  } = crmField;

  const validationRules = [];

  if (crm_field_required) {
    validationRules.push({
      required: true,
    });
  }

  let formItem = <></>;
  let valuePropName = "value";

  switch (crm_field_type) {
    case CRMFieldType.String:
    case CRMFieldType.Textarea:
    case CRMFieldType.HTML:
    case CRMFieldType.EncryptedString: {
      formItem = (
        <Input.TextArea
          placeholder={crm_field_label}
          autoSize={{ maxRows: 19 }}
        />
      );
      validationRules.push({
        type: "string",
        max: crm_field_length || 255,
        message: "${label} must be up to ${max} characters",
      });
      break;
    }
    case CRMFieldType.Phone: {
      formItem = <Input placeholder={crm_field_label} />;
      validationRules.push({
        type: "string",
        pattern: /^(\+\d{1,2}\s*)?\(?\d{3}\)?[\s.-]*\d{3}[\s.-]*\d{4}$/,
        message:
          "${crm_field_label} must be a valid phone number (+1 234 567 8910)",
      });
      break;
    }
    case CRMFieldType.Email: {
      formItem = <Input placeholder={crm_field_label} />;
      validationRules.push({
        type: "email",
        message: "Please enter a valid email address",
      });
      break;
    }
    case CRMFieldType.Url: {
      formItem = <Input placeholder={crm_field_label} />;
      validationRules.push({
        type: "url",
        warningOnly: true,
        message: "Not a valid URL",
      });
      break;
    }
    case CRMFieldType.Date: {
      formItem = (
        <DatePicker
          placeholder={crm_field_label}
          style={{ width: "100%" }}
          format="MM/DD/YYYY"
        />
      );
      break;
    }
    case CRMFieldType.DateTime: {
      formItem = (
        <DatePicker
          placeholder={crm_field_label}
          showTime
          use12Hours
          format="MM/DD/YYYY h:mm A"
          style={{ width: "100%" }}
        />
      );
      break;
    }
    case CRMFieldType.Time: {
      formItem = (
        <TimePicker
          placeholder={crm_field_label}
          use12Hours
          format="h:mm A"
          style={{ width: "100%" }}
        />
      );
      break;
    }
    case CRMFieldType.Currency:
    case CRMFieldType.Double:
    case CRMFieldType.Percent: {
      formItem = (
        <InputNumber
          step={1}
          placeholder={crm_field_label}
          style={{ width: "100%" }}
          changeOnWheel={false}
        />
      );
      break;
    }
    case CRMFieldType.Boolean: {
      formItem = <Switch />;
      valuePropName = "checked";
      break;
    }
    case CRMFieldType.Picklist:
    case CRMFieldType.Reference: {
      formItem = (
        <Select
          getPopupContainer={() => document.getElementById(containerId)}
          options={crm_field_picklist_values}
        />
      );
      break;
    }
    case CRMFieldType.Multipicklist: {
      formItem = (
        <Select
          getPopupContainer={() => document.getElementById(containerId)}
          mode="multiple"
          options={crm_field_picklist_values}
        />
      );
      break;
    }
  }

  return (
    <Form.Item
      name={crm_field_name}
      rules={validationRules}
      valuePropName={valuePropName}
      label={crm_field_label}
    >
      {formItem}
    </Form.Item>
  );
}
