import React, { useState } from "react";
import {
  useAutoGeneratedFields,
  useFindCRMRecordById,
  useMeeting,
  useOrganizationMeetings,
  useTeamFieldConfigurationGroups,
} from "../../util/data_hooks";
import {
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Drawer,
  Row,
  Space,
  Tag,
  Typography,
} from "antd";
import { getContentPreview } from "../../util/auto_generated_field_helpers";
import { getFormattedDate } from "../../helpers/date_helpers";
import { MeetingStatus } from "../shared/MeetingStatus";
import { CRMRecordTag } from "../shared/CRMRecordTag";
import { EmailAvatarLabel } from "../shared/EmailAvatarLabel";
import { useNavigate, useParams } from "react-router-dom";
import { parseCRMValue } from "../../util/parseCRMValue";
import Markdown from "react-markdown";
import { SwyftIcon } from "../shared/SwyftIcon";
import { SyncedCRMObject } from "../../util/types";
import { usePageTitle } from "../hooks/usePageTitle";
import { CheckCircleOutlined, StopOutlined } from "@ant-design/icons";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import SkeletonInput from "antd/es/skeleton/Input";

function RecordLink({
  syncedCRMObject,
  crmRecordName,
  crmRecordId,
  autoAssociated = false,
}: {
  syncedCRMObject: SyncedCRMObject;
  crmRecordName: string;
  crmRecordId: string;
  autoAssociated?: boolean;
}) {
  const { record, isLoading } = useFindCRMRecordById(
    syncedCRMObject.crm_object_type,
    syncedCRMObject.searchable_crm_field_name,
    crmRecordId
  );

  if (isLoading) return null;

  return (
    <CRMRecordTag
      autoAssociated={autoAssociated}
      showBackground
      crm={syncedCRMObject.crm_provider_name}
      name={`${crmRecordName} (${syncedCRMObject.crm_object_label})`}
      url={record.crm_record_url}
    />
  );
}

function FormattedFieldValue({
  label,
  value,
  accepted,
  context,
}: {
  label: string;
  value: string;
  context: string;
  accepted: boolean | null;
}) {
  let statusTag;

  if (accepted === true) {
    statusTag = (
      <Tag color="success" icon={<CheckCircleOutlined />}>
        Approved
      </Tag>
    );
  } else if (accepted === false) {
    statusTag = (
      <Tag color="error" icon={<StopOutlined />}>
        Rejected
      </Tag>
    );
  }

  const markdownComponents = {
    p: ({ ...rest }) => {
      return (
        <p style={{ fontSize: 14, marginTop: 0, lineHeight: 1.5 }} {...rest} />
      );
    },
    li: ({ ...rest }) => (
      <li
        style={{
          fontSize: 14,
          lineHeight: 1.5,
          paddingBottom: 5,
        }}
        {...rest}
      />
    ),
    ul: ({ ...rest }) => (
      <ul style={{ paddingLeft: 15, marginTop: 0 }} {...rest} />
    ),
    ol: ({ ...rest }) => (
      <ul style={{ paddingLeft: 15, marginTop: 0 }} {...rest} />
    ),
  };

  return (
    <Card title={label} size="small" extra={statusTag}>
      <Markdown components={markdownComponents}>
        {value || "*Not discussed*"}
      </Markdown>

      {context && (
        <>
          <Divider style={{ margin: "10px 0px" }}></Divider>

          <Typography.Paragraph
            type="secondary"
            style={{ fontSize: 12 }}
            ellipsis={{
              rows: 2,
              expandable: "collapsible",
            }}
          >
            <b>Context:</b> {context}
          </Typography.Paragraph>
        </>
      )}
    </Card>
  );
}

export function MeetingDrawer() {
  const navigate = useNavigate();
  const { meetingId } = useParams();

  const [isOpen, setIsOpen] = useState<boolean>(true);
  const { meeting, isLoading: isMeetingLoading } = useMeeting(meetingId);

  const {
    fieldConfigurationGroups,
    isLoading: isFieldConfigurationGroupsLoading,
  } = useTeamFieldConfigurationGroups(meeting?.user_team_uuid);

  const { autoGeneratedFields, isLoading: isAutoGeneratedFieldsLoading } =
    useAutoGeneratedFields(meetingId, false);

  const { organizationMeetings, isLoading: isMeetingsLoading } =
    useOrganizationMeetings({
      shouldPaginate: false,
    });

  usePageTitle(meeting?.topic);

  const currentIndex = organizationMeetings?.findIndex(
    ({ uuid }) => uuid === meeting?.uuid
  );

  const isLoading =
    isMeetingLoading ||
    isMeetingsLoading ||
    isFieldConfigurationGroupsLoading ||
    isAutoGeneratedFieldsLoading;

  return (
    <Drawer
      destroyOnClose
      title={
        isLoading ? (
          <SkeletonInput />
        ) : (
          <Row align="middle" justify="space-between">
            <Col>
              <Typography.Title level={5} style={{ margin: 0 }}>
                {meeting?.topic}
              </Typography.Title>
            </Col>

            <Col>
              <Space>
                <Button
                  disabled={currentIndex === 0}
                  onClick={() => {
                    const prevInstance = organizationMeetings[currentIndex - 1];
                    if (!prevInstance) return;
                    navigate(`/dashboard/team-meetings/${prevInstance.uuid}`);
                  }}
                  shape="circle"
                  icon={<LeftOutlined />}
                />

                <Button
                  onClick={() => {
                    const nextInstance = organizationMeetings[currentIndex + 1];
                    if (!nextInstance) return;
                    navigate(`/dashboard/team-meetings/${nextInstance.uuid}`);
                  }}
                  disabled={currentIndex === organizationMeetings?.length - 1}
                  shape="circle"
                  icon={<RightOutlined />}
                />
              </Space>
            </Col>
          </Row>
        )
      }
      placement="right"
      width="75rem"
      open={isOpen}
      onClose={() => setIsOpen(false)}
      afterOpenChange={(open) => !open && navigate("/dashboard/team-meetings")}
      loading={isLoading}
    >
      <Descriptions size="middle" colon={false} layout="vertical" column={2}>
        <Descriptions.Item label="User">
          {meeting && <EmailAvatarLabel email={meeting.user_email} />}
        </Descriptions.Item>

        <Descriptions.Item label="When">
          <Typography.Text>
            {getFormattedDate(meeting?.start_time)}
          </Typography.Text>
        </Descriptions.Item>

        <Descriptions.Item label="Associated Records">
          {meeting?.associated_crm_records.length ? (
            <Row gutter={[6, 6]}>
              {meeting.associated_crm_records.map(
                ({
                  crm_record_id,
                  crm_record_name,
                  synced_crm_object,
                  auto_associated,
                }) => (
                  <Col key={crm_record_id}>
                    <CRMRecordTag
                      crm={synced_crm_object.crm_provider_name}
                      name={`${crm_record_name} (${synced_crm_object.crm_object_label})`}
                      autoAssociated={auto_associated}
                    />
                  </Col>
                )
              )}
            </Row>
          ) : (
            <Typography.Text italic>Not selected</Typography.Text>
          )}
        </Descriptions.Item>

        <Descriptions.Item label="Status">
          <MeetingStatus meeting={meeting} />
        </Descriptions.Item>
      </Descriptions>

      <Divider />

      {fieldConfigurationGroups.map((fieldConfigurationGroup) => {
        const associatedCRMRecord = meeting?.associated_crm_records?.find(
          (associatedCRMRecord) =>
            associatedCRMRecord.synced_crm_object.uuid ===
            fieldConfigurationGroup.synced_crm_object?.uuid
        );

        return (
          <div key={fieldConfigurationGroup.uuid} style={{ marginBottom: 25 }}>
            <Row align="middle" gutter={[12, 12]}>
              <Col>
                <Space size="small">
                  <Typography.Title level={3} style={{ margin: 0 }}>
                    {fieldConfigurationGroup.label}
                  </Typography.Title>

                  {!fieldConfigurationGroup.synced_crm_object && (
                    <SwyftIcon inverted width={30} />
                  )}
                </Space>
              </Col>

              {associatedCRMRecord && (
                <Col>
                  <RecordLink
                    syncedCRMObject={fieldConfigurationGroup.synced_crm_object}
                    crmRecordId={associatedCRMRecord.crm_record_id}
                    crmRecordName={associatedCRMRecord.crm_record_name}
                    autoAssociated={associatedCRMRecord.auto_associated}
                  />
                </Col>
              )}

              {associatedCRMRecord?.last_synced_at && (
                <Col span={24}>
                  <Typography.Text type="secondary">
                    Manually synced to CRM on{" "}
                    {getFormattedDate(associatedCRMRecord.last_synced_at)}
                  </Typography.Text>
                </Col>
              )}

              {associatedCRMRecord?.auto_synced_at && (
                <Col span={24}>
                  <Typography.Text type="secondary">
                    Auto-Synced to CRM on{" "}
                    {getFormattedDate(associatedCRMRecord.auto_synced_at)}.{" "}
                    {associatedCRMRecord?.workflow_instance?.workflow_uuid && (
                      <Typography.Link
                        href={`/dashboard/settings/workflows/${associatedCRMRecord?.workflow_instance?.workflow_uuid}/workflow-instances/${associatedCRMRecord?.workflow_instance?.uuid}`}
                        target="_blank"
                      >
                        View Workflow
                      </Typography.Link>
                    )}
                  </Typography.Text>
                </Col>
              )}

              <Col span={24}>
                <Row gutter={[30, 30]}>
                  {fieldConfigurationGroup.field_configurations.map(
                    (fieldConfiguration) => {
                      const autoGeneratedField = autoGeneratedFields.find(
                        ({ field_configuration_uuid }) =>
                          field_configuration_uuid === fieldConfiguration.uuid
                      );

                      const value = autoGeneratedField?.content || null;

                      const parsedContent = parseCRMValue(
                        fieldConfiguration.field_type,
                        value
                      );

                      const previewableContent = getContentPreview(
                        parsedContent,
                        fieldConfiguration.field_type,
                        fieldConfiguration.picklist
                      );

                      if (!fieldConfiguration.automated) return null;

                      return (
                        <Col
                          key={fieldConfiguration.uuid}
                          span={previewableContent.length > 500 ? 24 : 12}
                        >
                          <FormattedFieldValue
                            label={fieldConfiguration.label}
                            value={previewableContent}
                            accepted={autoGeneratedField?.accepted}
                            context={
                              previewableContent
                                ? autoGeneratedField?.context
                                : null
                            }
                          />
                        </Col>
                      );
                    }
                  )}
                </Row>
              </Col>
            </Row>

            <Divider />
          </div>
        );
      })}
    </Drawer>
  );
}
