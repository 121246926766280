import React, { useEffect, useState } from "react";
import {
  Button,
  Drawer,
  Form,
  Input,
  Skeleton,
  Space,
  Spin,
  message,
} from "antd";
import { WorkflowTransition } from "../../../../util/types";
import {
  fetchCreateWorkflowTransition,
  fetchDeleteWorkflowTransition,
  fetchUpdateWorkflowTransition,
} from "../../../../util/api";
import { useWorkflow } from "../../../../util/data_hooks";
import { WorkflowQueryEditor } from "../action_editors/shared/WorkflowQueryEditor";
import { WorkflowEditorQueryParam } from "./WorkflowStateEditorDrawer";
import { useSearchParams } from "react-router-dom";
import { get } from "lodash";

enum WorkflowTransitionFieldName {
  Name = "name",
  Conditions = "conditions",
}

interface WorkflowTransitionEditorDrawerProps {
  workflowUuid: string;
  workflowStateUuid: string;
  workflowTransition: WorkflowTransition;
  shouldCreateWorkflowTransition: boolean;
  open: boolean;
  setOpen: (open: boolean) => void;
  afterSave: () => void;
}

export function WorkflowTransitionEditorDrawer({
  workflowUuid,
  workflowStateUuid,
  workflowTransition,
  shouldCreateWorkflowTransition,
  open,
  setOpen,
  afterSave,
}: WorkflowTransitionEditorDrawerProps) {
  const [form] = Form.useForm();

  const { isLoading } = useWorkflow(workflowUuid);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (open) {
      setSearchParams((params) => {
        params.set(
          WorkflowEditorQueryParam.WorkflowStateUuid,
          workflowStateUuid
        );

        params.set(
          WorkflowEditorQueryParam.IsCurrentWorkflowStateUpstream,
          "true"
        );

        return params;
      });

      if (shouldCreateWorkflowTransition) {
        form.resetFields();
      } else {
        form.setFieldsValue(workflowTransition);
      }
    } else {
      setSearchParams((params) => {
        params.delete(WorkflowEditorQueryParam.WorkflowStateUuid);
        params.delete(WorkflowEditorQueryParam.IsCurrentWorkflowStateUpstream);
        return params;
      });
    }
  }, [open]);

  const handleSubmit = async () => {
    await form.validateFields();

    setIsSaving(true);

    const values = form.getFieldsValue();

    try {
      if (workflowTransition) {
        await fetchUpdateWorkflowTransition(workflowTransition.uuid, values);
      } else {
        await fetchCreateWorkflowTransition({
          ...values,
          workflow_state_uuid: workflowStateUuid,
        });
      }

      afterSave();

      message.success(
        workflowTransition ? "Condition updated" : "Condition created"
      );

      setOpen(false);
    } catch (e) {
      message.error(
        get(
          e,
          "response.data.message",
          "Oops, something is wrong on our end! Please try again later."
        )
      );
    }

    setIsSaving(false);
  };

  const handleDelete = async () => {
    try {
      setIsSaving(true);
      await fetchDeleteWorkflowTransition(workflowTransition.uuid);
      await afterSave();
      setOpen(false);
      message.success("Condition deleted");
    } catch (e) {
      message.error(
        get(
          e,
          "response.data.message",
          "Oops, something is wrong on our end! Please try again later."
        )
      );
    }

    setIsSaving(false);
  };

  const showQueryEditor =
    shouldCreateWorkflowTransition ||
    !!form.getFieldValue(WorkflowTransitionFieldName.Conditions);

  if (isLoading) return <Skeleton active />;

  return (
    <Drawer
      forceRender
      destroyOnClose
      width="80rem"
      open={open}
      onClose={() => setOpen(false)}
      title={
        shouldCreateWorkflowTransition ? "Create Condition" : "Edit Condition"
      }
      footer={
        <Space>
          <Button type="primary" onClick={handleSubmit} disabled={isSaving}>
            Save
          </Button>

          {!shouldCreateWorkflowTransition && (
            <Button danger onClick={handleDelete} disabled={isSaving}>
              Delete
            </Button>
          )}
        </Space>
      }
    >
      <Spin spinning={isSaving}>
        <Form layout="vertical" form={form}>
          <Form.Item
            label="Condition name"
            name="name"
            rules={[{ required: true }]}
            style={{ width: "100%" }}
          >
            <Input placeholder="Add a name for this condition..." />
          </Form.Item>

          {showQueryEditor && (
            <Form.Item
              label="Conditions"
              name={WorkflowTransitionFieldName.Conditions}
            >
              <WorkflowQueryEditor
                fields={[]}
                defaultQuery={form.getFieldValue(
                  WorkflowTransitionFieldName.Conditions
                )}
                onChange={(query) =>
                  form.setFieldValue(
                    WorkflowTransitionFieldName.Conditions,
                    query
                  )
                }
              />
            </Form.Item>
          )}
        </Form>
      </Spin>
    </Drawer>
  );
}
