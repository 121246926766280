import React, { useEffect, useState } from "react";
import {
  Button,
  Drawer,
  Form,
  Input,
  Select,
  Space,
  Spin,
  message,
} from "antd";
import { WorkflowState, WorkflowTransition } from "../../../../util/types";
import {
  fetchCreateWorkflowState,
  fetchCreateWorkflowTransition,
  fetchDeleteWorkflowState,
  fetchUpdateWorkflowState,
  fetchUpdateWorkflowTransition,
} from "../../../../util/api";
import { getFriendlyWorkflowActionLabel } from "../../../../helpers/label_maps";
import { WorkflowActionType } from "../../../../util/enums";
import { useForm } from "antd/es/form/Form";
import { FindCRMRecord } from "../action_editors/FindCRMRecord";
import { UpdateCRMRecord } from "../action_editors/UpdateCRMRecord";
import { CreateCRMRecord } from "../action_editors/CreateCRMRecord";
import { useSearchParams } from "react-router-dom";
import { CloudUploadOutlined } from "@ant-design/icons";
import { useWorkflow } from "../../../../util/data_hooks";
import { SendSlackMessage } from "../action_editors/SendSlackMessage";
import { CreateSlackChannel } from "../action_editors/CreateSlackChannel";

export enum WorkflowEditorQueryParam {
  WorkflowStateUuid = "workflow_state_uuid",
  IsCurrentWorkflowStateUpstream = "is_current_workflow_state_upstream",
}

export enum WorkflowStateEditorFieldName {
  Name = "name",
  ActionType = "action_type",
  Variables = "variables",
}

function ActionEditor({
  selectedActionType,
  workflowState,
}: {
  selectedActionType: WorkflowActionType;
  workflowState: WorkflowState;
}) {
  switch (selectedActionType) {
    case WorkflowActionType.FindCRMRecord:
      return <FindCRMRecord />;
    case WorkflowActionType.UpdateCRMRecord:
      return <UpdateCRMRecord workflowState={workflowState} />;
    case WorkflowActionType.CreateCRMRecord:
      return <CreateCRMRecord workflowState={workflowState} />;
    case WorkflowActionType.CreateSlackChannel:
      return <CreateSlackChannel />;
    case WorkflowActionType.SendSlackMessage:
      return <SendSlackMessage />;
    default:
      return null;
  }
}

interface WorkflowStateEditorDrawerProps {
  workflowUuid: string;
  workflowState: WorkflowState;
  workflowTransition?: WorkflowTransition;
  shouldCreateNewWorkflowState: boolean;
  isFallback?: boolean;
  open: boolean;
  setOpen: (open: boolean) => void;
  afterSave: () => void;
}

export function WorkflowStateEditorDrawer({
  workflowUuid,
  workflowState,
  workflowTransition,
  shouldCreateNewWorkflowState,
  isFallback,
  open,
  setOpen,
  afterSave,
}: WorkflowStateEditorDrawerProps) {
  const [form] = useForm();

  const { workflow } = useWorkflow(workflowUuid);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = useSearchParams();

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [selectedActionType, setSelectedActionType] =
    useState<WorkflowActionType>();

  useEffect(() => {
    setSearchParams((params) => {
      if (open) {
        params.set(
          WorkflowEditorQueryParam.WorkflowStateUuid,
          workflowState?.uuid
        );

        params.set(
          WorkflowEditorQueryParam.IsCurrentWorkflowStateUpstream,
          shouldCreateNewWorkflowState.toString()
        );
      } else {
        params.delete(WorkflowEditorQueryParam.WorkflowStateUuid);
        params.delete(WorkflowEditorQueryParam.IsCurrentWorkflowStateUpstream);
      }

      return params;
    });

    if (shouldCreateNewWorkflowState) {
      form.resetFields();
      setSelectedActionType(null);
    } else {
      form.setFieldsValue(workflowState);
      setSelectedActionType(workflowState.action_type);
    }
  }, [open]);

  const handleSubmit = async (values) => {
    await form.validateFields();

    setIsSaving(true);

    try {
      const { name, action_type, variables } = values;

      const workflowStateValues: Partial<WorkflowState> = {
        name,
        action_type,
        variables,
      };

      if (shouldCreateNewWorkflowState) {
        const newWorkflowState = await fetchCreateWorkflowState(
          workflowUuid,
          workflowStateValues
        );

        if (workflowTransition) {
          const updateWorkflowTransitionValues: Partial<WorkflowTransition> =
            isFallback
              ? { fallback_target_workflow_state_uuid: newWorkflowState.uuid }
              : { target_workflow_state_uuid: newWorkflowState.uuid };

          await fetchUpdateWorkflowTransition(
            workflowTransition.uuid,
            updateWorkflowTransitionValues
          );
        } else if (workflowState) {
          await fetchCreateWorkflowTransition({
            workflow_state_uuid: workflowState.uuid,
            target_workflow_state_uuid: newWorkflowState.uuid,
          });
        }
      } else {
        await fetchUpdateWorkflowState(
          workflow.uuid,
          workflowState.uuid,
          workflowStateValues
        );
      }

      await afterSave();

      message.success(
        shouldCreateNewWorkflowState
          ? "Workflow action created"
          : "Workflow action updated"
      );

      setOpen(false);
    } catch (e) {
      message.error(e.message);
    }

    setIsSaving(false);
  };

  const handleDelete = async () => {
    try {
      setIsSaving(true);
      await fetchDeleteWorkflowState(workflow.uuid, workflowState.uuid);
      await afterSave();
      message.success("Workflow action deleted");
      setOpen(false);
    } catch (e) {
      message.error(e.message);
    }

    setIsSaving(false);
  };

  return (
    <Drawer
      forceRender
      destroyOnClose
      width="80rem"
      open={open}
      onClose={() => setOpen(false)}
      title={shouldCreateNewWorkflowState ? "Create New Action" : "Edit Action"}
      footer={
        <Space>
          <Button
            type="primary"
            disabled={isSaving}
            onClick={() => handleSubmit(form.getFieldsValue())}
            icon={<CloudUploadOutlined />}
          >
            Commit and Save
          </Button>

          {!shouldCreateNewWorkflowState && (
            <Button danger onClick={handleDelete} disabled={isSaving}>
              Delete
            </Button>
          )}
        </Space>
      }
    >
      <Spin spinning={isSaving}>
        <Form form={form} layout="vertical">
          <Form.Item
            label="Action Type"
            name={WorkflowStateEditorFieldName.ActionType}
            style={{ width: "100%" }}
            rules={[{ required: true }]}
          >
            <Select
              options={Object.values(WorkflowActionType)
                .filter(
                  (value) =>
                    [
                      WorkflowActionType.Trigger,
                      WorkflowActionType.FindCRMRecord, // Not yet supported
                    ].includes(value) === false
                )
                .map((value) => {
                  return {
                    value,
                    label: getFriendlyWorkflowActionLabel(value),
                  };
                })}
              onChange={setSelectedActionType}
              placeholder="Select an action type..."
            />
          </Form.Item>

          <Form.Item
            label="Name"
            name={WorkflowStateEditorFieldName.Name}
            style={{ width: "100%" }}
            rules={[{ required: true }]}
            hidden={!selectedActionType}
          >
            <Input placeholder="Enter a name for this action..." />
          </Form.Item>

          <ActionEditor
            selectedActionType={selectedActionType}
            workflowState={workflowState}
          />
        </Form>
      </Spin>
    </Drawer>
  );
}
