import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  Row,
  Space,
  TablePaginationConfig,
  Tooltip,
  Typography,
  message,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import {
  CloseCircleTwoTone,
  CheckCircleTwoTone,
  WarningTwoTone,
  EyeOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import {
  FieldConfiguration,
  FieldConfigurationGroup,
  Team,
} from "../../../util/types";
import { useNavigate } from "react-router-dom";
import { CRMIcon, getCRMColor } from "../../shared/CRMIcon";
import { SwyftIcon } from "../../shared/SwyftIcon";
import {
  deactivateFieldConfigurationGroup,
  refreshOrganizationCRMFields,
  updateFieldConfigurationOrder,
} from "../../../util/api";
import { FieldConfigEditorModal } from "./FieldConfigEditorModal";
import { SortableTable } from "../../shared/SortableTable";
import { MoreOutlined } from "@ant-design/icons";
import { useTeamFieldConfigurationGroups } from "../../../util/data_hooks";
import { useSearchParamsState } from "../../hooks/useSearchParamsState";
import _ from "lodash";

const DEFAULT_PAGE_SIZE = 10;

const sanitizeFilterValue = (value: string): string =>
  value.toLocaleLowerCase().split(" ").join("");

interface FieldConfigurationsTable {
  team: Team;
  fieldConfigurationGroup: FieldConfigurationGroup;
  filterValue: string;
  onUpdateGroupOrderClick: () => void;
  onSave: () => void;
}

export function FieldConfigurationsTable({
  team,
  fieldConfigurationGroup,
  filterValue,
  onUpdateGroupOrderClick,
  onSave,
}: FieldConfigurationsTable) {
  const navigate = useNavigate();

  const { fieldConfigurationGroups } = useTeamFieldConfigurationGroups(
    team.uuid
  );

  const [filteredFieldConfigurations, setFilteredFieldConfigurations] =
    useState<FieldConfiguration[]>(
      fieldConfigurationGroup.field_configurations
    );
  const [isCreateFieldConfigModalOpen, setCreateFieldConfigModalOpen] =
    useState(false);

  const [page, setPage] = useSearchParamsState(
    `${fieldConfigurationGroup.name}_page`,
    "0"
  );

  const [pageSize, setPageSize] = useSearchParamsState(
    `${fieldConfigurationGroup.name}_page_size`,
    DEFAULT_PAGE_SIZE.toString()
  );

  const [crmFieldsRefreshing, setCRMFieldsRefreshing] =
    useState<boolean>(false);

  const syncOrganizationCRMFields = async () => {
    setCRMFieldsRefreshing(true);

    try {
      await refreshOrganizationCRMFields();
      message.success(`Fields Resynced Successfully`);
    } catch (e) {
      message.error(
        `An error occurred while resyncing fields. Please try again.`
      );
    }

    setCRMFieldsRefreshing(false);
  };

  useEffect(() => {
    const filteredResults = fieldConfigurationGroup.field_configurations.filter(
      ({ name, label }) => {
        if (!filterValue) return true;

        const sanitizedFilterValue = sanitizeFilterValue(filterValue);
        const sanitizedName = sanitizeFilterValue(name);
        const sanitizedLabel = sanitizeFilterValue(label);

        return (
          sanitizedLabel.includes(sanitizedFilterValue) ||
          sanitizedName.includes(sanitizedFilterValue)
        );
      }
    );

    setFilteredFieldConfigurations(filteredResults);
  }, [fieldConfigurationGroup, filterValue]);

  const columns: ColumnsType<FieldConfiguration> = [
    {
      title: "Status",
      dataIndex: "automated",
      width: 5,
      align: "center",
      render: (isAutomated: boolean, field: FieldConfiguration) => {
        const icons = [];

        if (isAutomated && !field.prompt_stale) {
          icons.push(
            <Tooltip title="Field automated">
              <CheckCircleTwoTone />
            </Tooltip>
          );
        }

        if (isAutomated && field.prompt_stale) {
          // Prompt stale
          icons.push(
            <Tooltip title="Field updated in CRM. Check prompt is still valid.">
              <WarningTwoTone twoToneColor="#e0990b" />
            </Tooltip>
          );
        }

        if (field.visible) {
          // Field visible
          icons.push(
            <Tooltip title="Field visible on meeting form">
              <EyeOutlined />
            </Tooltip>
          );
        }

        if (!field.visible && field.automated) {
          icons.push(
            <Tooltip title="Field hidden on meeting form">
              <EyeInvisibleOutlined />
            </Tooltip>
          );
        }

        if (!field.active) {
          icons.push(
            <Tooltip title="Field removed from CRM">
              <CloseCircleTwoTone twoToneColor="#ff4d4f" />
            </Tooltip>
          );
        }

        return (
          <Space>
            {icons.map((icon, i) => (
              <div key={i}>{icon}</div>
            ))}
          </Space>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "label",
      width: "15%",
    },
    {
      title: "Field Type",
      dataIndex: "field_type",
      width: "15%",
    },
    {
      title: "Prompt",
      dataIndex: "prompt",
      ellipsis: true,
      width: "50%",
      render: (prompt) => (
        <Tooltip placement="topLeft" title={prompt}>
          {prompt}
        </Tooltip>
      ),
    },
  ];

  const pagination: TablePaginationConfig = {
    current: parseInt(page) + 1,
    pageSize: parseInt(pageSize),
    total: filteredFieldConfigurations.length,
    onChange: (newPage, newPageSize) => {
      if (parseInt(page) !== newPage - 1) {
        setPage((newPage - 1).toString());
      } else {
        setPageSize(newPageSize.toString());
      }
    },
    pageSizeOptions: [10, 20, 50, 100],
    showSizeChanger: true,
  };

  return (
    <>
      <FieldConfigEditorModal
        team={team}
        open={isCreateFieldConfigModalOpen}
        fieldConfigurationGroup={fieldConfigurationGroup}
        fieldConfigurationGroups={fieldConfigurationGroups}
        setOpen={setCreateFieldConfigModalOpen}
        onCreate={(fieldConfiguration) => {
          onSave();
          navigate(fieldConfiguration.uuid);
        }}
      />

      <Row align="middle" gutter={8}>
        <Col>
          <Row align="middle" gutter={[8, 0]}>
            <Col>
              {fieldConfigurationGroup.synced_crm_object?.crm_provider_name ? (
                <CRMIcon
                  crm={
                    fieldConfigurationGroup.synced_crm_object.crm_provider_name
                  }
                  color={getCRMColor(
                    fieldConfigurationGroup.synced_crm_object.crm_provider_name
                  )}
                  style={{ height: 25 }}
                />
              ) : (
                <SwyftIcon inverted width={25} />
              )}
            </Col>

            <Col>
              <Typography.Title level={4} style={{ margin: "20px 0px" }}>
                {fieldConfigurationGroup.label}
              </Typography.Title>
            </Col>
          </Row>
        </Col>

        <Col>
          <Dropdown
            trigger={["click"]}
            menu={{
              items: [
                {
                  key: "update-group-order",
                  label: (
                    <a onClick={onUpdateGroupOrderClick}>Update Group Order</a>
                  ),
                },
                fieldConfigurationGroup.synced_crm_object && {
                  key: "resync-fields",
                  label: (
                    <a onClick={syncOrganizationCRMFields}>Resync Fields</a>
                  ),
                },
                !fieldConfigurationGroup.synced_crm_object && {
                  key: "create-field",
                  label: (
                    <a onClick={() => setCreateFieldConfigModalOpen(true)}>
                      Create Custom Automation
                    </a>
                  ),
                },
                {
                  key: "deactivate-group",
                  danger: true,
                  label: (
                    <a
                      onClick={async () => {
                        try {
                          await deactivateFieldConfigurationGroup({
                            teamUuid: team.uuid,
                            fieldConfigurationGroupUuid:
                              fieldConfigurationGroup.uuid,
                          });

                          message.success(
                            `"${fieldConfigurationGroup.label}"  removed`
                          );

                          onSave();
                        } catch (err) {
                          if (_.get(err, "response.status", 500) < 500) {
                            message.error(
                              _.get(
                                err,
                                "response.data.message",
                                "Oops, something is wrong on our end! Please try again later."
                              )
                            );
                          } else {
                            message.error(
                              `Oops! Something went wrong. Please try again.`
                            );
                          }
                        }
                      }}
                    >
                      Remove Group
                    </a>
                  ),
                },
              ],
            }}
          >
            <Button
              loading={crmFieldsRefreshing}
              icon={<MoreOutlined />}
              size="small"
              shape="circle"
            />
          </Dropdown>
        </Col>
      </Row>

      <SortableTable<FieldConfiguration>
        rowKey={({ uuid }) => uuid}
        dataSource={fieldConfigurationGroup.field_configurations}
        filteredDataSource={filteredFieldConfigurations}
        columns={columns}
        pagination={pagination}
        onRow={({ uuid }) => {
          const field = fieldConfigurationGroup.field_configurations.find(
            (fieldConfiguration) => fieldConfiguration.uuid === uuid
          );

          if (!field) return null;

          if (field.active) {
            return {
              style: { cursor: "pointer" },
              onClick: () => {
                navigate(`${field.uuid}`);
              },
            };
          } else {
            return {
              style: {
                background: "#f0f0f0",
                cursor: "not-allowed",
                "&:hover": {
                  background: "#f0f0f0",
                },
              },
            };
          }
        }}
        onDragStart={() =>
          setPageSize(filteredFieldConfigurations.length.toString())
        }
        onPositionUpdate={async ({ newOrder }) => {
          const fieldConfigurations = newOrder.map(({ uuid }, index) => {
            return { uuid, position: index + 1 };
          });

          try {
            await updateFieldConfigurationOrder({
              teamUuid: team.uuid,
              fieldConfigurationGroupUuid: fieldConfigurationGroup.uuid,
              fieldConfigurations,
            });

            message.success("Field order updated");
          } catch (e) {
            message.error(
              `Error updating field order: ${e.response.data.message}`
            );
          }

          setPage("0");
          setPageSize(DEFAULT_PAGE_SIZE.toString());
        }}
        locale={{
          emptyText: filterValue ? `No results for "${filterValue}"` : null,
        }}
      />
    </>
  );
}
