import { Alert, Select, SelectProps, Space, Typography } from "antd";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { Team } from "../../../util/types";
import { setSelectedTeam } from "../../redux/features/team/teamSlice";

const teamsToOptions = (teams: Team[]): SelectProps["options"] => {
  return teams.map((team) => ({
    label: team.name,
    value: team.uuid,
  }));
};

export function TeamSelectBanner() {
  const dispatch = useAppDispatch();

  const selectedTeam = useAppSelector((state) => state.team.selectedTeam);
  const teamOptions = useAppSelector((state) => state.team.teamOptions);
  const teamSelectLoading = useAppSelector((state) => state.team.isLoading);

  const setTeam = (team: Team) => dispatch(setSelectedTeam(team));

  return (
    <Alert
      type="info"
      style={{ marginBottom: 25 }}
      message={
        <Space>
          <Typography.Text strong>Selected Team</Typography.Text>

          <Select
            size="small"
            options={teamsToOptions(teamOptions || [])}
            loading={teamSelectLoading}
            value={selectedTeam?.uuid}
            onChange={(selectedTeamUuid: string) => {
              setTeam(
                teamOptions.find((team) => team.uuid === selectedTeamUuid)
              );
            }}
            style={{ minWidth: 225 }}
          />
        </Space>
      }
    />
  );
}
