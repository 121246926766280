import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Modal,
  Row,
  Skeleton,
  Space,
  Tooltip,
  Typography,
  message,
  theme,
} from "antd";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ReactFlow, {
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  ReactFlowProvider,
  ControlButton,
  addEdge,
} from "reactflow";
import { useWorkflow } from "../../../util/data_hooks";

import "reactflow/dist/style.css";
import {
  REMOVEABLE_EDGE,
  getInitialWorkflowElements,
} from "./util/get_initial_workflow_elements";
import { getLayoutedWorkflowElements } from "./util/get_layouted_workflow_elements";
import { WorkflowStateNode } from "./WorkflowStateNode";
import { WorkflowInstances } from "./WorkflowInstances";
import { fetchDeleteWorkflow } from "../../../util/api";
import { Workflow } from "../../../util/types";
import { WorkflowTransitionNode } from "./WorkflowTransitionNode";
import { green, red } from "@ant-design/colors";
import {
  DeleteOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  EditOutlined,
} from "@ant-design/icons";
import { getFriendlyWorkflowTriggerLabel } from "../../../helpers/label_maps";
import { WorkflowDetailsEditorModal } from "./shared/WorkflowDetailsEditorModal";
import { usePageTitle } from "../../hooks/usePageTitle";
import RemoveableEdge from "./RemoveableEdge";
import { useAppSelector } from "../../redux/hooks";
import { UserRole, WorkflowTrigger } from "../../../util/enums";
import { AppContext } from "../../App";

interface FlowchartProps {
  workflow: Workflow;
  isLoading: boolean;
  onClose: () => void;
}

function Flowchart({ workflow, isLoading, onClose }: FlowchartProps) {
  const reactFlowWrapper = useRef(null);
  const connectingNodeId = useRef(null);

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  useEffect(() => {
    if (isLoading) return;

    const { initialNodes, initialEdges } = getInitialWorkflowElements(workflow);

    const { nodes: layoutedNodes, edges: layoutedEdges } =
      getLayoutedWorkflowElements(initialNodes, initialEdges);

    setNodes(layoutedNodes);
    setEdges(layoutedEdges);
  }, [isLoading, workflow]);

  const nodeTypes = useMemo(
    () => ({
      workflow_state: WorkflowStateNode,
      workflow_transition: WorkflowTransitionNode,
    }),
    []
  );

  const onConnectStart = useCallback((_, { nodeId }) => {
    connectingNodeId.current = nodeId;
  }, []);

  const onConnect = useCallback(
    (params) =>
      setEdges((els) => addEdge({ ...params, type: REMOVEABLE_EDGE }, els)),
    []
  );

  return (
    <div
      ref={reactFlowWrapper}
      style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }}
    >
      <ReactFlow
        nodes={nodes}
        edges={edges}
        edgeTypes={{
          removeableedge: RemoveableEdge,
        }}
        nodeTypes={nodeTypes}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnectStart={onConnectStart}
        onConnect={onConnect}
        autoPanOnConnect
        fitViewOptions={{ maxZoom: 1 }}
        fitView
        deleteKeyCode={null}
      >
        <Controls position="top-left" showInteractive={false}>
          <Tooltip title="Exit Workflow Builder" placement="left">
            <ControlButton onClick={onClose}>
              <CloseCircleTwoTone />
            </ControlButton>
          </Tooltip>
        </Controls>
        <Background gap={12} size={1} />
      </ReactFlow>
    </div>
  );
}

export function WorkflowEditor() {
  const { workflowUuid } = useParams();
  const { token } = theme.useToken();
  const navigate = useNavigate();
  const context = useContext(AppContext);

  const [searchParams, setSearchParams] = useSearchParams();

  const team = useAppSelector((state) => state.team.selectedTeam);
  const teamLoading = useAppSelector((state) => state.team.isLoading);

  const { workflow, isLoading: workflowLoading } = useWorkflow(workflowUuid);

  const isLoading = workflowLoading || teamLoading;

  const isTechnicalAdmin = context?.user_roles?.includes(
    UserRole.TechnicalAdmin
  );

  usePageTitle(workflow?.name);

  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [workflowDetailsEditorModalOpen, setworkflowDetailsEditorModalOpen] =
    useState<boolean>(false);

  const onDeleteWorkflowClick = () => {
    Modal.confirm({
      title: `Delete Workflow: "${workflow.name}"?`,
      icon: <DeleteOutlined style={{ color: token.colorError }} />,
      content: "This cannot be undone.",
      okText: "Delete Workflow",
      okType: "danger",
      cancelText: "Cancel",
      okButtonProps: { loading: isDeleting },
      onOk: async () => {
        setIsDeleting(true);

        try {
          await fetchDeleteWorkflow(workflowUuid);
          navigate("/dashboard/settings/workflows");
          message.success("Workflow successfully deleted");
        } catch (e) {
          message.error(
            e.response?.data?.error ||
              "An error occurred. Please try again later."
          );
        }

        setIsDeleting(false);
      },
    });
  };

  if (isLoading) return <Skeleton active />;

  return (
    <>
      {searchParams.get("workflow_builder_open") ? (
        <ReactFlowProvider>
          <Flowchart
            workflow={workflow}
            isLoading={isLoading}
            onClose={() =>
              setSearchParams((params) => {
                params.delete("workflow_builder_open");
                return params;
              })
            }
          />
        </ReactFlowProvider>
      ) : (
        <>
          <WorkflowDetailsEditorModal
            open={workflowDetailsEditorModalOpen}
            workflow={workflow}
            team={team}
            setOpen={setworkflowDetailsEditorModalOpen}
          />

          {isTechnicalAdmin && (
            <Breadcrumb
              style={{ marginBottom: 15 }}
              items={[
                {
                  title: (
                    <a
                      onClick={() => navigate(`/dashboard/settings/workflows`)}
                    >
                      Workflows
                    </a>
                  ),
                },
                { title: workflow.name },
              ]}
            />
          )}

          <Card
            title={
              <Space>
                {workflow.name}

                {workflow.enabled ? (
                  <Tooltip title="Workflow Enabled">
                    <CheckCircleTwoTone twoToneColor={green.primary} />
                  </Tooltip>
                ) : (
                  <Tooltip title="Workflow Disabled">
                    <CloseCircleTwoTone twoToneColor={red.primary} />
                  </Tooltip>
                )}
              </Space>
            }
            extra={
              isTechnicalAdmin && (
                <Space>
                  <Button
                    type="primary"
                    icon={<EditOutlined />}
                    style={{ marginRight: "0.5rem" }}
                    onClick={() => setworkflowDetailsEditorModalOpen(true)}
                  />

                  <Button
                    type="primary"
                    danger
                    icon={<DeleteOutlined />}
                    onClick={onDeleteWorkflowClick}
                  />
                </Space>
              )
            }
            actions={
              isTechnicalAdmin && [
                <Button
                  key="workflow-builder-button"
                  style={{ width: 300 }}
                  onClick={() =>
                    setSearchParams((params) => {
                      params.set("workflow_builder_open", "true");
                      return params;
                    })
                  }
                  type="primary"
                >
                  Enter Workflow Builder
                </Button>,
              ]
            }
          >
            <Descriptions>
              <Descriptions.Item label="Trigger">
                {getFriendlyWorkflowTriggerLabel(workflow.trigger)}
              </Descriptions.Item>

              {[WorkflowTrigger.CRMRecordUpdated].includes(
                workflow?.trigger
              ) && (
                <Descriptions.Item label="CRM Field Triggers">
                  <Row gutter={8}>
                    {workflow?.field_configuration_triggers.map(
                      ({ uuid, label, synced_crm_object }) => (
                        <Col key={uuid}>
                          <Typography.Link
                            key={uuid}
                            onClick={() =>
                              navigate(
                                `/dashboard/settings/field-configurations/${uuid}`
                              )
                            }
                          >
                            {label} ({synced_crm_object.crm_object_label})
                          </Typography.Link>
                        </Col>
                      )
                    )}
                  </Row>
                </Descriptions.Item>
              )}
            </Descriptions>
          </Card>

          <Divider />

          <WorkflowInstances />
        </>
      )}
    </>
  );
}
