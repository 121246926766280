import React, { useEffect } from "react";
import { FormInstance } from "antd";

import {
  AutoGeneratedField,
  FieldConfiguration,
  FieldConfigurationGroup,
  Meeting,
} from "../../../util/types";
import {
  convertContentToJson,
  isContentPresent,
} from "../../../util/auto_generated_field_helpers";
import { parseCRMValue } from "../../../util/parseCRMValue";
import { MeetingFormFieldItem } from "../MeetingFormFieldItem";
import { fetchUpdateAutoGeneratedField } from "../../../util/api";
import { isEqual } from "lodash";
import { useAutoGeneratedFields } from "../../../util/data_hooks";

interface AutoGeneratedFieldCardProps {
  form: FormInstance;
  meeting: Meeting;
  fieldConfigurationGroup: FieldConfigurationGroup;
  fieldConfiguration: FieldConfiguration;
  autoGeneratedField: AutoGeneratedField;
}

export function SwyftAutoGeneratedFieldCard({
  meeting,
  form,
  fieldConfigurationGroup,
  fieldConfiguration,
  autoGeneratedField,
}: AutoGeneratedFieldCardProps) {
  const autoGeneratedValue = autoGeneratedField.content;

  const { mutate } = useAutoGeneratedFields(meeting.uuid);

  useEffect(() => {
    if (isContentPresent(autoGeneratedValue, fieldConfiguration.field_type)) {
      const value = parseCRMValue(
        fieldConfiguration.field_type,
        autoGeneratedValue
      );

      form.setFieldsValue({
        [fieldConfigurationGroup.uuid]: { [fieldConfiguration.name]: value },
      });
    }
  }, [fieldConfigurationGroup, fieldConfiguration, autoGeneratedField]);

  const updateAutoGeneratedField = async () => {
    const content = convertContentToJson(
      fieldConfiguration.field_type,
      form.getFieldValue([
        fieldConfigurationGroup.uuid,
        fieldConfiguration.name,
      ])
    );

    if (
      !isEqual(
        form.getFieldValue([
          fieldConfigurationGroup.uuid,
          fieldConfiguration.name,
        ]),
        autoGeneratedValue
      )
    ) {
      await fetchUpdateAutoGeneratedField(
        meeting.uuid,
        autoGeneratedField.uuid,
        {
          user_modified_content: content,
        }
      );
      await mutate();
    }
  };

  return (
    <MeetingFormFieldItem
      name={[fieldConfigurationGroup.uuid, fieldConfiguration.name]}
      label={fieldConfiguration.label}
      fieldType={fieldConfiguration.field_type}
      required={fieldConfiguration.required}
      length={fieldConfiguration.length}
      picklistItems={fieldConfiguration.picklist}
      cardIcon={"⚡️"}
      tooltipText={"The content for this field was generated by Swyft"}
      placeholderText={"Not discussed"}
      onBlur={updateAutoGeneratedField}
    />
  );
}
