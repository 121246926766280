import { Alert, Typography } from "antd";
import React from "react";
import { Team } from "../../../../util/types";

export function RemainingAutomationsBanner({ team }: { team: Team }) {
  if (!team || team.field_automations_available > 0) return null;

  return (
    <Alert
      showIcon
      type="warning"
      style={{ marginBottom: 20 }}
      message={
        <Typography.Text>
          There are{" "}
          <Typography.Text strong>
            {team.field_automations_available} remaining automations
          </Typography.Text>{" "}
          available for this team. Contact{" "}
          <Typography.Link strong href="mailto:hello@swyftai.com">
            hello@swyftai.com
          </Typography.Link>{" "}
          or disable an existing field to add a new automation.
        </Typography.Text>
      }
    />
  );
}
