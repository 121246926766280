import React, { useEffect, useState } from "react";
import {
  Modal,
  Input,
  Form,
  Button,
  InputNumber,
  Skeleton,
  Select,
  Space,
  message,
  Tabs,
  Table,
  Row,
  Col,
} from "antd";
import { get } from "lodash";
import {
  fetchCreateOrganization,
  fetchUpdateOrganization,
  impersonateUser,
} from "../../../util/api";
import { useOrganizationUsers, useProducts } from "../../../util/data_hooks";
import { Organization, Product, User } from "../../../util/types";
import { CRM_PROVIDERS, MEETING_PROVIDERS } from "../../../util/constants";
import {
  getFriendlyIntegrationLabel,
  getFriendlyUserRoleLabel,
} from "../../../helpers/label_maps";
import { UserRole } from "../../../util/enums";
import { UserEditorModal } from "./UserEditorModal";
import { InvitationModal } from "./InvitationModal";
import { CheckCircleTwoTone, StopTwoTone } from "@ant-design/icons";
import { green, red } from "@ant-design/colors";

function OrganizationEditorForm({
  organization,
  afterSave,
  onClose,
}: {
  organization: Organization;
  afterSave: () => void;
  onClose: () => void;
}) {
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);

  const { products, isLoading: isProductLoading } = useProducts(0, 100);

  useEffect(() => {
    form.setFieldsValue(organization);
  }, []);

  const handleSave = async (values) => {
    setLoading(true);

    try {
      if (organization) {
        await fetchUpdateOrganization(organization.uuid, values);
        message.success("Organization saved!");
      } else {
        await fetchCreateOrganization(values);
        message.success("Organization created!");
      }

      afterSave();
      onClose();
    } catch (err) {
      const defaultMessage =
        "Oops, something is wrong on our end! Please try again later.";

      const is500 = get(err, "response.status", 500) >= 500;

      message.error(
        is500 ? defaultMessage : get(err, "response.data.error", defaultMessage)
      );
    }

    setLoading(false);
  };

  const validationMessages = {
    required: "${label} is required!",
    types: {
      number: "${label} is not a valid number!",
    },
    number: {
      min: "${label} must be greater than ${min}",
    },
  };

  return (
    <Form
      onFinish={handleSave}
      layout="vertical"
      form={form}
      style={{ marginTop: "1rem" }}
      validateMessages={validationMessages}
    >
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item label="Name" name="name" rules={[{ required: true }]}>
            <Input placeholder="Enter a name" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label="Domains"
            name="domains"
            rules={[{ required: true }]}
          >
            <Select mode="tags" placeholder="Enter an email domain" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label="CRM"
            name="crm_provider_name"
            rules={[{ required: true }]}
          >
            <Select
              defaultValue={organization?.crm_provider_name}
              options={CRM_PROVIDERS.map((crmProvider) => {
                return {
                  label: getFriendlyIntegrationLabel(crmProvider),
                  value: crmProvider,
                };
              })}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label="Web Conferencing, CI, and Telephony Systems"
            name="meeting_providers"
            rules={[{ required: true }]}
          >
            <Select
              mode="tags"
              defaultValue={organization?.meeting_providers}
              options={MEETING_PROVIDERS.map((meetingProvider) => {
                return {
                  label: getFriendlyIntegrationLabel(meetingProvider),
                  value: meetingProvider,
                };
              })}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label="Seats"
            name="seats"
            rules={[
              {
                required: true,
                type: "number",
                min: 0,
              },
            ]}
          >
            <InputNumber />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="Product" name="product_uuid">
            {isProductLoading ? (
              <Skeleton.Input active />
            ) : (
              <Select
                options={products.map((product: Product) => ({
                  value: product.uuid,
                  label: product.name,
                }))}
                allowClear
              />
            )}
          </Form.Item>
        </Col>
      </Row>

      <Space>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Save
        </Button>

        <Button
          onClick={() => {
            form.resetFields();
            onClose();
          }}
        >
          Cancel
        </Button>
      </Space>
    </Form>
  );
}

interface Props {
  organization: Organization;
  open: boolean;
  setOpen: (value: boolean) => void;
  afterSave: () => void;
}

export function OrganizationEditorModal({
  organization,
  open,
  setOpen,
  afterSave,
}: Props) {
  const onClose = () => setOpen(false);

  const { users, isLoading } = useOrganizationUsers(organization);

  const [editableUser, setEditableUser] = useState<User>(null);
  const [isUserEditorModalOpen, setIsUserEditorModalOpen] =
    useState<boolean>(false);
  const [invitationModalOpen, setInvitationModalOpen] =
    useState<boolean>(false);

  return (
    <Modal
      onCancel={onClose}
      loading={isLoading}
      open={open}
      footer={false}
      destroyOnClose
      width="75rem"
      title={organization ? "Edit Organization" : "Create Organization"}
    >
      {organization && (
        <>
          <UserEditorModal
            user={editableUser}
            organization={organization}
            open={isUserEditorModalOpen}
            setOpen={setIsUserEditorModalOpen}
            afterSave={afterSave}
          />

          <InvitationModal
            open={invitationModalOpen}
            organization={organization}
            setOpen={setInvitationModalOpen}
            afterSave={afterSave}
          />
        </>
      )}

      <Tabs
        tabBarExtraContent={
          organization ? (
            <Button type="primary" onClick={() => setInvitationModalOpen(true)}>
              Invite Users
            </Button>
          ) : null
        }
        defaultActiveKey="editor"
        items={[
          {
            key: "editor",
            label: organization
              ? `Edit ${organization.name}`
              : "Create Organization",
            children: (
              <OrganizationEditorForm
                organization={organization}
                onClose={onClose}
                afterSave={afterSave}
              />
            ),
          },
          {
            key: "users",
            disabled: !organization,
            label: "Users",
            children: (
              <Table<User>
                showHeader
                dataSource={users
                  .sort((a, b) => Number(b.active) - Number(a.active))
                  .sort(
                    (a, b) =>
                      Number(b.roles.includes(UserRole.Admin)) -
                      Number(a.roles.includes(UserRole.Admin))
                  )
                  .sort(
                    (a, b) =>
                      Number(b.roles.includes(UserRole.TechnicalAdmin)) -
                      Number(a.roles.includes(UserRole.TechnicalAdmin))
                  )}
                rowKey={({ uuid }) => uuid}
                columns={[
                  { key: "email", title: "Email", dataIndex: "email" },
                  {
                    key: "roles",
                    title: "Roles",
                    dataIndex: "roles",
                    render: (roles) =>
                      roles
                        .map((role) => getFriendlyUserRoleLabel(role))
                        .join(", "),
                  },
                  {
                    key: "invite_accepted",
                    title: "Invite Accepted",
                    render: (_, { pending_invitations }) => {
                      return pending_invitations.length ? (
                        <StopTwoTone twoToneColor={red.primary} />
                      ) : (
                        <CheckCircleTwoTone twoToneColor={green.primary} />
                      );
                    },
                  },
                  {
                    key: "actions",
                    dataIndex: "uuid",
                    align: "right",
                    render: (uuid, user) => (
                      <Space>
                        <Button
                          size="small"
                          type="link"
                          disabled={
                            !user.roles.includes(UserRole.Admin) &&
                            !user.roles.includes(UserRole.TechnicalAdmin)
                          }
                          onClick={async () => {
                            await impersonateUser(uuid);
                            window.location.href =
                              "/dashboard/settings/organization";
                          }}
                        >
                          Impersonate {user.email}
                        </Button>

                        <Button
                          onClick={() => {
                            setEditableUser(user);
                            setIsUserEditorModalOpen(true);
                          }}
                        >
                          Edit
                        </Button>
                      </Space>
                    ),
                  },
                ]}
              />
            ),
          },
        ]}
      />
    </Modal>
  );
}
