import React, { useState } from "react";
import {
  Button,
  Form,
  GetProp,
  Input,
  Modal,
  Select,
  Space,
  Spin,
  Typography,
  Upload,
  UploadFile,
  UploadProps,
  message,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { Meeting } from "../../util/types";
import { uploadMeetingRecording } from "../../util/api";
import { MeetingType } from "../../util/enums";
import { getFriendlyMeetingTypeLabel } from "../../helpers/label_maps";

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

enum UploadMeetingRecordingFormFieldName {
  Name = "name",
  MeetingType = "meeting_type",
}

interface UploadMeetingRecordingButtonProps {
  requestPredictions?: boolean;
  isTestMeeting?: boolean;
  onSuccess: (meeting: Meeting) => void;
}

export function UploadMeetingRecordingButton({
  requestPredictions = false,
  isTestMeeting = false,
  onSuccess,
}: UploadMeetingRecordingButtonProps) {
  const [form] = Form.useForm();

  const [uploadMeetingRecordingModalOpen, setUploadMeetingRecordingModalOpen] =
    useState<boolean>(false);
  const [file, setFile] = useState<UploadFile>(null);
  const [uploading, setUploading] = useState(false);

  const handleUpload = async () => {
    await form.validateFields();

    const formData = new FormData();

    formData.append("file", file as FileType);

    formData.append(
      "name",
      form.getFieldValue(UploadMeetingRecordingFormFieldName.Name)
    );

    formData.append(
      "meeting_type",
      form.getFieldValue(UploadMeetingRecordingFormFieldName.MeetingType)
    );

    formData.append("request_predictions", JSON.stringify(requestPredictions));
    formData.append("is_test_meeting", JSON.stringify(isTestMeeting));

    setUploading(true);

    try {
      const response = await uploadMeetingRecording(formData);

      setFile(null);
      onSuccess(response.meeting);
      setUploadMeetingRecordingModalOpen(false);
      message.success("Upload Successful");
    } catch (e) {
      message.error(e.response.data.message);
    }

    setUploading(false);
  };

  const props = {
    name: "file",
    accept: "audio/*,video/*",
    maxCount: 1,
    onRemove: () => setFile(null),
    beforeUpload: (file) => {
      setFile(file);

      if (!form.getFieldValue(UploadMeetingRecordingFormFieldName.Name)) {
        form.setFieldValue(UploadMeetingRecordingFormFieldName.Name, file.name);
      }

      return false;
    },
    showUploadList: { showRemoveIcon: !uploading },
    fileList: file ? [file] : [],
  };

  return (
    <>
      <Modal
        title="Upload Recording"
        open={uploadMeetingRecordingModalOpen}
        onCancel={() => setUploadMeetingRecordingModalOpen(false)}
        onOk={handleUpload}
        okButtonProps={{ disabled: !file || uploading, loading: uploading }}
        okText={uploading ? "Uploading..." : "Upload"}
      >
        <Spin spinning={uploading}>
          <Form
            form={form}
            layout="vertical"
            initialValues={{ meeting_type: MeetingType.WebConference }}
          >
            <Form.Item label="Recording">
              <Upload.Dragger {...props}>
                <Space direction="vertical">
                  <Typography.Text strong>
                    Click or drag file here to upload
                  </Typography.Text>

                  <Typography.Text>
                    File types can be audio or video.
                  </Typography.Text>
                </Space>
              </Upload.Dragger>
            </Form.Item>

            <Form.Item
              rules={[{ required: true }]}
              label="Name"
              name={UploadMeetingRecordingFormFieldName.Name}
            >
              <Input placeholder={`"Acme Demo"`} />
            </Form.Item>

            <Form.Item
              rules={[{ required: true }]}
              label="Type"
              name={UploadMeetingRecordingFormFieldName.MeetingType}
            >
              <Select
                placeholder="Recording Type"
                options={Object.values(MeetingType).map((type) => {
                  return {
                    label: getFriendlyMeetingTypeLabel(type),
                    value: type,
                  };
                })}
              />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>

      <Button
        icon={<UploadOutlined />}
        onClick={() => setUploadMeetingRecordingModalOpen(true)}
      >
        Upload Recording
      </Button>
    </>
  );
}
