import React from "react";
import { IntegrationInfo, User } from "../../util/types";
import { Alert, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { getFriendlyIntegrationLabel } from "../../helpers/label_maps";

interface Props {
  meetingIntegration: IntegrationInfo;
}

export function MeetingProviderAlert({ meetingIntegration }: Props) {
  const navigate = useNavigate();

  if (!meetingIntegration.connected) {
    return (
      <Alert
        showIcon
        type="warning"
        message={
          <Typography.Text>
            {getFriendlyIntegrationLabel(meetingIntegration.name)} is not
            connected.{" "}
            <Typography.Link
              onClick={() => navigate("/dashboard/integrations")}
            >
              Please connect here
            </Typography.Link>
          </Typography.Text>
        }
      />
    );
  } else {
    return <></>;
  }
}
