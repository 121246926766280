import React from "react";
import { Button, Empty, Form, Skeleton, Space, Switch, Typography } from "antd";
import { useUser } from "../../../../util/data_hooks";
import { WorkflowStateEditorFieldName } from "../shared/WorkflowStateEditorDrawer";
import { Integration, WorkflowVariableName } from "../../../../util/enums";
import { useIntegrationWindow } from "../../../hooks/useIntegrationWindow";
import { SlackUsersSelectFormItem } from "./shared/SlackUsersSelectFormItem";
import { InfoCircleOutlined } from "@ant-design/icons";
import { DynamicVariableTextarea } from "./shared/dynamic_variables/DynamicVariableTextarea";

export function CreateSlackChannel() {
  const form = Form.useFormInstance();

  const [openIntegrationWindow] = useIntegrationWindow();

  const { user, isLoading: isUserLoading } = useUser();

  if (isUserLoading) return <Skeleton active />;

  if (!user.organization.slack_workspace) {
    return (
      <Empty description="Slack is not connected for your organization">
        <Button
          type="primary"
          onClick={() => openIntegrationWindow(Integration.Slack)}
        >
          Connect Slack
        </Button>
      </Empty>
    );
  }

  return (
    <>
      <Form.Item
        rules={[{ required: true }]}
        label="Channel Name"
        name={[
          WorkflowStateEditorFieldName.Variables,
          WorkflowVariableName.SlackChannelName,
        ]}
        extra={
          <Space size="small">
            <InfoCircleOutlined />

            <Typography.Text type="secondary">
              Swyft will return the existing channel if one already exists with
              this name
            </Typography.Text>
          </Space>
        }
      >
        <DynamicVariableTextarea
          value={form.getFieldValue([
            WorkflowStateEditorFieldName.Variables,
            WorkflowVariableName.SlackChannelName,
          ])}
          onChange={(value) => {
            form.setFieldValue(
              [
                WorkflowStateEditorFieldName.Variables,
                WorkflowVariableName.SlackChannelName,
              ],
              value
            );
          }}
        />
      </Form.Item>

      <Form.Item
        label="Is Private"
        name={[
          WorkflowStateEditorFieldName.Variables,
          WorkflowVariableName.SlackChannelIsPrivate,
        ]}
      >
        <Switch defaultValue={false} />
      </Form.Item>

      <SlackUsersSelectFormItem required label="Users to Invite" />
    </>
  );
}
