import React, { useState } from "react";
import { Meeting } from "../../../../../util/types";
import { Descriptions, Typography } from "antd";
import { getFormattedDate } from "../../../../../helpers/date_helpers";

interface Props {
  meeting: Meeting;
  summary: string;
}

export function MeetingPreview({ meeting, summary }: Props) {
  const [ellipsisHack, setEllipsisHack] = useState(true);

  return (
    <Descriptions style={{ maxWidth: "50rem" }}>
      <Descriptions.Item label="User">{meeting.user_email}</Descriptions.Item>

      <Descriptions.Item label="Date">
        {getFormattedDate(meeting.start_time)}
      </Descriptions.Item>

      <Descriptions.Item label="Duration">
        {meeting.duration_in_minutes} mins
      </Descriptions.Item>

      {summary && (
        <Descriptions.Item label="Summary">
          <Typography.Paragraph
            ellipsis={{
              rows: 3,
              expandable: true,
              symbol: "more",
              onEllipsis: () => setEllipsisHack(false),
            }}
            style={ellipsisHack ? { height: "4rem" } : {}}
          >
            {summary}
          </Typography.Paragraph>
        </Descriptions.Item>
      )}
    </Descriptions>
  );
}
