import { Card, Layout, Space, Steps, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { ConnectMeetingProvider } from "./steps/ConnectMeetingProvider";
import { useSearchParams } from "react-router-dom";
import { SetupComplete } from "./steps/SetupComplete";

enum StepKey {
  SyncMeetings = "sync-meetings",
  SetupComplete = "setup-complete",
}

export function Onboarding() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentStep, setCurrentStep] = useState<number>(0);

  const setNextStep = () => setCurrentStep((step) => step + 1);
  const setPreviousStep = () => setCurrentStep((step) => step - 1);

  const steps = [
    {
      key: StepKey.SyncMeetings,
      title: "Web Conferencing Platform",
      content: <ConnectMeetingProvider setNextStep={setNextStep} />,
    },
    {
      key: StepKey.SetupComplete,
      title: "View Recent Meetings",
      content: <SetupComplete setPreviousStep={setPreviousStep} />,
    },
  ];

  useEffect(() => {
    setSearchParams({ step: steps[currentStep].key });
  }, [currentStep]);

  useEffect(() => {
    const stepSearchParam = searchParams.get("step") as StepKey;
    const stepIndex = steps.map(({ key }) => key).indexOf(stepSearchParam);
    setCurrentStep(stepIndex < 0 ? 0 : stepIndex);
  }, []);

  const items = steps.map(({ key, title }) => ({ key, title }));

  return (
    <Layout style={{ height: "100vh", padding: 50 }}>
      <Space direction="vertical" style={{ display: "flex" }} size="large">
        <div>
          <Typography.Title level={2}>Welcome to Swyft AI!</Typography.Title>

          <Typography.Text>
            Follow the steps below to get started.
          </Typography.Text>
        </div>

        <Steps current={currentStep} items={items} />

        <Card>{steps[currentStep].content}</Card>
      </Space>
    </Layout>
  );
}
