import React, { useContext, useEffect, useState } from "react";
import {
  BackTop,
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Switch,
  Tooltip,
  Typography,
} from "antd";
import { CloudUploadOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { forEach, get, merge, reduce, set } from "lodash";

import { ScreeningConfig } from "./ScreeningConfig";
import { PicklistTable } from "./PicklistTable";
import {
  CRMFieldType,
  FieldConfigurationAppendType,
  GenerationModel,
  UserRole,
} from "../../../../util/enums";
import { FieldConfiguration, PicklistItem } from "../../../../util/types";

import { updateFieldConfiguration } from "../../../../util/api";
import {
  convertContentToJson,
  isFieldTypeAutomated,
} from "../../../../util/auto_generated_field_helpers";
import { useAppDispatch } from "../../../redux/hooks";
import { setOpportunityFieldToTest } from "../../../redux/features/OpportunityFieldTesting/opportunityTestSlice";
import { parseCRMValue } from "../../../../util/parseCRMValue";
import { isMeetingSummary } from "../../../../helpers/standard_fields_helper";
import { useTeam, useUser } from "../../../../util/data_hooks";
import { AppContext } from "../../../App";
import { AppendDropdown } from "./form_components/AppendDropdown";

const DYNAMIC_GENERATION_MODEL_FIELD_TYPES = [
  CRMFieldType.String,
  CRMFieldType.Textarea,
  CRMFieldType.HTML,
];

const parseJsonContent = (type, value) => {
  switch (type) {
    case CRMFieldType.String:
    case CRMFieldType.EncryptedString:
    case CRMFieldType.Textarea:
    case CRMFieldType.HTML: {
      return value ? value : "";
    }
    case CRMFieldType.Multipicklist: {
      try {
        return value ? JSON.parse(value) : [];
      } catch (e) {
        return value;
      }
    }
    default: {
      try {
        return JSON.parse(value);
      } catch (e) {
        return value;
      }
    }
  }
};

const formValuesToPayload = (
  values,
  fieldConfiguration: FieldConfiguration
): FieldConfiguration => {
  const updatePayload: Pick<
    FieldConfiguration,
    | "notes"
    | "prompt"
    | "automated"
    | "chunk_strategy"
    | "append_type"
    | "visible"
    | "screening_prompt"
    | "screening_enabled"
    | "screening_default_enabled"
    | "screening_default"
    | "picklist"
    | "generation_model"
    | "include_organization_context"
    | "include_team_context"
  > & {
    picklist: Pick<PicklistItem, "prompt" | "uuid">[];
  } = {
    notes: values.notes,
    automated: values.automated,
    visible: values.visible,
    chunk_strategy: values.chunk_strategy,
    append_type: values.append_type,
    prompt: values.prompt,
    picklist: [],
    screening_enabled: values.screening_enabled || false,
    screening_prompt: values.screening_prompt || "",
    screening_default_enabled: values.screening_default_enabled || false,
    screening_default: values.screening_default
      ? convertContentToJson(
          fieldConfiguration.field_type,
          values.screening_default
        )
      : null,
    generation_model: values.generation_model || null,
    include_organization_context: values.include_organization_context || false,
    include_team_context: values.include_team_context || false,
  };
  forEach(values, (value, key) => {
    if (
      fieldConfiguration.picklist.some(
        (picklistItem) => picklistItem.uuid === key
      )
    ) {
      updatePayload.picklist.push({
        uuid: key,
        prompt: value,
      });
    }
  });
  return merge({}, fieldConfiguration, updatePayload);
};

interface Props {
  fieldConfiguration: FieldConfiguration;
  refreshFieldConfiguration: () => Promise<any>;
}

export function FieldConfigurationForm({
  fieldConfiguration,
  refreshFieldConfiguration,
}: Props) {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [isFormSubmitting, setFormSubmitting] = useState(false);
  const [isAutomationEnabled, setAutomationEnabled] = useState(false);
  const [isFormReset, setFormReset] = useState(true);

  const { user } = useUser();
  const { team, mutate: mutateTeam } = useTeam(fieldConfiguration?.team_uuid);

  const context = useContext(AppContext);
  const isImpersonating = !!context.user_impersonation_email;

  const showGenerationModelInput =
    DYNAMIC_GENERATION_MODEL_FIELD_TYPES.includes(
      fieldConfiguration.field_type
    ) &&
    (user?.roles?.includes(UserRole.SuperAdmin) || isImpersonating);

  const getInitialFormValues = () => {
    const initFormValues = fieldConfiguration;
    set(
      initFormValues,
      "screening_default",
      parseCRMValue(
        initFormValues.field_type,
        parseJsonContent(
          initFormValues.field_type,
          initFormValues.screening_default
        )
      )
    );
    const initialPicklistValues =
      fieldConfiguration.picklist && fieldConfiguration.picklist.length > 0
        ? reduce(
            fieldConfiguration.picklist.map((picklistItem) => {
              const temp = {};
              temp[picklistItem.uuid] = picklistItem.prompt;
              return temp;
            }),
            (total, value) => merge(total, value)
          )
        : {};

    return merge(initFormValues, initialPicklistValues);
  };

  const handleSave = async (values) => {
    setFormSubmitting(true);
    try {
      const updatePayload: Pick<
        FieldConfiguration,
        | "notes"
        | "prompt"
        | "automated"
        | "chunk_strategy"
        | "append_type"
        | "visible"
        | "screening_prompt"
        | "screening_enabled"
        | "screening_default_enabled"
        | "generation_model"
        | "include_team_context"
        | "include_organization_context"
      > & {
        picklist: Pick<PicklistItem, "prompt" | "uuid">[];
      } & {
        screening_default?: string;
      } = {
        notes: values.notes,
        automated: values.automated,
        visible: values.visible,
        chunk_strategy: values.chunk_strategy,
        append_type: values.append_type,
        prompt: values.prompt,
        picklist: [],
        screening_enabled: values.screening_enabled,
        screening_prompt: values.screening_prompt,
        screening_default_enabled: values.screening_default_enabled,
        generation_model: values.generation_model,
        include_organization_context: values.include_organization_context,
        include_team_context: values.include_team_context,
      };

      if (values.screening_default) {
        updatePayload.screening_default = convertContentToJson(
          fieldConfiguration.field_type,
          values.screening_default
        );
      }

      forEach(values, (value, key) => {
        if (
          fieldConfiguration.picklist.some(
            (picklistItem) => picklistItem.uuid === key
          )
        ) {
          updatePayload.picklist.push({
            uuid: key,
            prompt: value,
          });
        }
      });

      await updateFieldConfiguration(fieldConfiguration.uuid, updatePayload);
      await refreshFieldConfiguration();
      mutateTeam();
      resetForm();
    } catch (err) {
      if (get(err, "response.status", 500) < 500) {
        message.error(
          get(
            err,
            "response.data.error",
            "Oops, something is wrong on our end! Please try again later."
          )
        );
      } else {
        message.error(
          "Oops, something is wrong on our end! Please try again later."
        );
      }
    }
    setFormSubmitting(false);
    await refreshFieldConfiguration();
    resetForm();
  };

  useEffect(() => {
    if (fieldConfiguration) {
      setAutomationEnabled(fieldConfiguration.automated);

      dispatch(
        setOpportunityFieldToTest(
          formValuesToPayload(fieldConfiguration, fieldConfiguration)
        )
      );
    }
  }, [fieldConfiguration]);

  const handleTestingChange = (_, values) => {
    dispatch(
      setOpportunityFieldToTest(formValuesToPayload(values, fieldConfiguration))
    );
    setFormReset(false);
  };

  const validationMessages = {
    required: "Required",
    types: {
      number: "${label} is not a valid number!",
    },
    number: {
      min: "${label} must be greater than ${min}",
    },
  };

  const chunkStrategyDropdownOptions = [
    {
      label: "Start",
      value: "start",
    },
    {
      label: "End",
      value: "end",
    },
  ];

  const generationModelDropdownOptions = [
    {
      label: "GPT-4o-mini",
      value: GenerationModel.Gpt4oMini,
    },
    {
      label: "GPT-4o",
      value: GenerationModel.Gpt4o,
    },
    {
      label: "Haiku",
      value: GenerationModel.Haiku,
    },
    {
      label: "Sonnet",
      value: GenerationModel.Sonnet,
    },
    {
      label: "Opus",
      value: GenerationModel.Opus,
    },
  ];

  const resetForm = () => {
    form.resetFields();
    if (form.getFieldValue("automated")) {
      setAutomationEnabled(true);
    }

    setFormReset(true);
  };

  return (
    <Form
      onFinish={handleSave}
      form={form}
      validateMessages={validationMessages}
      initialValues={getInitialFormValues()}
      name="FieldConfigForm"
      onValuesChange={handleTestingChange}
      scrollToFirstError
      layout="vertical"
    >
      <BackTop />
      <Card
        title="Configure"
        extra={
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Button
              onClick={resetForm}
              style={{ marginRight: "0.5rem", marginLeft: "auto" }}
            >
              Reset Changes
            </Button>
            <Form.Item style={{ marginBottom: "0" }}>
              <Button
                type="primary"
                htmlType="submit"
                loading={isFormSubmitting}
                disabled={isFormReset && !fieldConfiguration?.prompt_stale}
                icon={<CloudUploadOutlined />}
              >
                {isFormReset && fieldConfiguration?.prompt_stale
                  ? "Revalidate"
                  : "Commit and Save"}
              </Button>
            </Form.Item>
          </div>
        }
      >
        <Row gutter={[12, 0]} align="bottom">
          <Col span={12}>
            <Form.Item
              label="Visible on Meeting Form"
              name="visible"
              valuePropName={"checked"}
            >
              <Switch />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={
                !isMeetingSummary(fieldConfiguration) ? (
                  "Automation Enabled"
                ) : (
                  <Tooltip
                    title={
                      "A meeting summary is required to be automated. Swyft uses meeting summaries to generate insights and automate workflows."
                    }
                  >
                    Automation Enabled
                    <InfoCircleOutlined style={{ marginLeft: "0.25rem" }} />
                  </Tooltip>
                )
              }
              name="automated"
              valuePropName="checked"
            >
              <Switch
                onChange={(isAutomating) => {
                  setAutomationEnabled(isAutomating);
                  if (!isAutomating) form.validateFields();
                }}
                disabled={
                  !isFieldTypeAutomated(fieldConfiguration.field_type) ||
                  isMeetingSummary(fieldConfiguration) ||
                  (!fieldConfiguration.automated &&
                    team?.field_automations_available === 0)
                }
                style={{ marginRight: "0" }}
              />
            </Form.Item>
          </Col>

          {isAutomationEnabled && (
            <>
              <Divider orientation="left">Step 1. Screen Field</Divider>

              <Col span={24}>
                <ScreeningConfig
                  fieldConfiguration={fieldConfiguration}
                  isAutomationEnabled={isAutomationEnabled}
                  isEditing={true}
                  form={form}
                />
              </Col>

              <Divider orientation="left">Step 2. Prompt Field</Divider>

              <Col span={24}>
                <Form.Item
                  label="Prompt"
                  name="prompt"
                  rules={[{ required: isAutomationEnabled }]}
                  extra={
                    <div style={{ marginTop: "0.25rem" }}>
                      <Typography.Link
                        type="secondary"
                        href={
                          "https://help.swyftai.com/en/articles/9821286-field-prompts"
                        }
                        target="_blank"
                      >
                        <InfoCircleOutlined /> Need help writing prompts?
                      </Typography.Link>
                    </div>
                  }
                >
                  <Input.TextArea rows={3} maxLength={2000} />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label={
                    <Space>
                      <Typography.Text>
                        Include organization context?
                      </Typography.Text>
                      <Typography.Link
                        type="secondary"
                        href={
                          "https://help.swyftai.com/en/articles/9821424-field-configuration-settings#h_c3d7f4393d"
                        }
                        target="_blank"
                      >
                        <InfoCircleOutlined />
                      </Typography.Link>
                    </Space>
                  }
                  name="include_organization_context"
                  valuePropName={"checked"}
                >
                  <Switch />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label={
                    <Space>
                      <Typography.Text>Include team context?</Typography.Text>
                      <Typography.Link
                        type="secondary"
                        href={
                          "https://help.swyftai.com/en/articles/9821424-field-configuration-settings#h_c3d7f4393d"
                        }
                        target="_blank"
                      >
                        <InfoCircleOutlined />
                      </Typography.Link>
                    </Space>
                  }
                  name="include_team_context"
                  valuePropName={"checked"}
                >
                  <Switch />
                </Form.Item>
              </Col>

              <AppendDropdown
                required={isAutomationEnabled}
                fieldConfiguration={fieldConfiguration}
              />

              {showGenerationModelInput && (
                <Col span={12}>
                  <Form.Item label="Generation Model" name="generation_model">
                    <Select
                      options={generationModelDropdownOptions}
                      defaultValue={GenerationModel.Gpt4oMini}
                    />
                  </Form.Item>
                </Col>
              )}

              {fieldConfiguration.picklist.length > 0 && (
                <Col span={24}>
                  <Typography.Title level={5}>
                    Picklist Configuration
                  </Typography.Title>

                  <PicklistTable
                    picklist={fieldConfiguration.picklist}
                    isEditing={true}
                    isPicklistRequired={isAutomationEnabled}
                  />
                </Col>
              )}

              <Divider />

              <Col span={24}>
                <Form.Item label="Notes" name="notes">
                  <Input.TextArea
                    rows={4}
                    placeholder="Enter any contextual information about what this field does. For internal use only. Does not affect insight generation."
                  />
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
      </Card>
    </Form>
  );
}
