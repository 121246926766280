import { useState } from "react";
import { Workflow, WorkflowState } from "../../../../util/types";
import { useWorkflowStates } from "../../../../util/data_hooks";

const getSourceWorkflowState = (
  workflowStates: WorkflowState[],
  workflowState: WorkflowState,
  appendUpstreamWorkflowStates: (workflowState: WorkflowState) => void
) => {
  const sourceTransition = workflowState?.source_workflow_transition;

  if (!sourceTransition) return;

  const sourceWorkflowState = workflowStates.find(
    ({ uuid }) => uuid === sourceTransition.workflow_state_uuid
  );

  appendUpstreamWorkflowStates(sourceWorkflowState);

  getSourceWorkflowState(
    workflowStates,
    sourceWorkflowState,
    appendUpstreamWorkflowStates
  );
};

export function useUpstreamWorkflowStates({
  workflow,
  workflowStateUuid,
  includeCurrentWorkflowState = false,
}: {
  workflow: Workflow;
  workflowStateUuid: string;
  includeCurrentWorkflowState: boolean;
}): [boolean, WorkflowState[]] {
  const { workflowStates, isLoading } = useWorkflowStates(workflow.uuid);

  const [upstreamWorkflowStates, appendUpstreamWorkflowStates] = useState([]);

  if (isLoading) return [true, []];

  const workflowState = workflowStates.find(
    ({ uuid }) => uuid === workflowStateUuid
  );

  if (upstreamWorkflowStates.length === 0) {
    getSourceWorkflowState(workflowStates, workflowState, (newState) =>
      appendUpstreamWorkflowStates((prevStates) => [newState, ...prevStates])
    );
  }

  return [
    false,
    includeCurrentWorkflowState
      ? [...upstreamWorkflowStates, workflowState].filter(Boolean)
      : upstreamWorkflowStates,
  ];
}
