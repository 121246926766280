import React from "react";
import { Form, Input, Popover, Table } from "antd";

import { PicklistItem } from "../../../../util/types";
import { ColumnsType } from "antd/es/table";

interface Props {
  picklist: PicklistItem[];
  isEditing: boolean;
  isPicklistRequired: boolean;
}

export function PicklistTable({
  picklist,
  isEditing,
  isPicklistRequired,
}: Props) {
  const columns: ColumnsType<PicklistItem> = [
    {
      title: "Name",
      dataIndex: "label",
      key: "name",
      width: "15%",
    },
    {
      key: "Description",
      title: "Description",
      render: (_, record) => (
        <Form.Item
          name={record.uuid}
          style={{ margin: 0 }}
          rules={[
            {
              required: isPicklistRequired && record.active,
            },
          ]}
        >
          <Input.TextArea autoSize={{ maxRows: 10 }} />
        </Form.Item>
      ),
    },
  ];

  return (
    <Table
      dataSource={picklist}
      columns={columns}
      pagination={false}
      style={{
        marginBottom: "1rem",
        marginTop: "1rem",
      }}
    />
  );
}
