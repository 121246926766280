import { MeetingStatusType } from "../src/shared/MeetingStatus";
import {
  ConditionalOperator,
  Integration,
  MeetingType,
  UserRole,
  WorkflowActionType,
  WorkflowStatus,
  WorkflowTrigger,
} from "../util/enums";

export const getFriendlyWorkflowStatusLabel = (
  workflowStatus: WorkflowStatus
): string => {
  const labels: { [key in WorkflowStatus]: string } = {
    [WorkflowStatus.Triggered]: "Triggered",
    [WorkflowStatus.InProgress]: "In Progress",
    [WorkflowStatus.Complete]: "Workflow Complete",
    [WorkflowStatus.Error]: "Error",
  };

  return labels[workflowStatus];
};

export const getFriendlyMeetingStatusLabel = (
  meetingStatus: MeetingStatusType
): string => {
  const labels: { [key in MeetingStatusType]: string } = {
    [MeetingStatusType.Analyzing]: "Analyzing",
    [MeetingStatusType.Viewed]: "Viewed",
    [MeetingStatusType.Unreviewed]: "Unreviewed",
    [MeetingStatusType.Synced]: "Manually Synced to CRM",
    [MeetingStatusType.AutoSynced]: "Auto-Synced to CRM",
  };

  return labels[meetingStatus];
};

export const getFriendlyWorkflowTriggerLabel = (
  workflowTrigger: WorkflowTrigger
): string => {
  const labels: { [key in WorkflowTrigger]: string } = {
    [WorkflowTrigger.MeetingAnalyzed]: "Meeting Analyzed",
    [WorkflowTrigger.MeetingSaved]: "Meeting Saved",
    [WorkflowTrigger.CRMRecordUpdated]: "CRM Record Updated",
  };

  return labels[workflowTrigger];
};

export const getFriendlyWorkflowActionLabel = (
  actionType: WorkflowActionType
): string => {
  const labels: { [key in WorkflowActionType]: string } = {
    [WorkflowActionType.Trigger]: "Workflow Triggered",

    [WorkflowActionType.FindCRMRecord]: "Find CRM Record",
    [WorkflowActionType.UpdateCRMRecord]: "Update CRM Record",
    [WorkflowActionType.CreateCRMRecord]: "Create CRM Record",

    [WorkflowActionType.CreateSlackChannel]: "Create Slack Channel",
    [WorkflowActionType.SendSlackMessage]: "Send Slack Message",
  };

  return labels[actionType];
};

export const getFriendlyOperatorLabel = (
  operator: ConditionalOperator
): string => {
  const labels: { [key in ConditionalOperator]: string } = {
    [ConditionalOperator.Exists]: "Exists",
    [ConditionalOperator.NotExists]: "Does not exist",
    [ConditionalOperator.Equals]: "Is equal to",
    [ConditionalOperator.NotEquals]: "Is not equal to",
    [ConditionalOperator.Includes]: "Includes or is one of",
    [ConditionalOperator.NotIncludes]: "Does not include and is not one of",
    [ConditionalOperator.GreaterThan]: "Is greater than",
    [ConditionalOperator.LessThan]: "Is less than",
    [ConditionalOperator.GreaterThanEqualTo]: "Is greater than or equal to",
    [ConditionalOperator.LessThanEqualTo]: "Is less than or equal to",
  };

  return labels[operator];
};

export const getFriendlyIntegrationLabel = (
  integration: Integration
): string => {
  const labels: { [key in Integration]: string } = {
    [Integration.Gong]: "Gong",
    [Integration.Avoma]: "Avoma",
    [Integration.Zoom]: "Zoom",
    [Integration.ZoomAdmin]: "Zoom (Admin Managed)",
    [Integration.Microsoft]: "Microsoft Teams",
    [Integration.Slack]: "Slack",
    [Integration.Salesforce]: "Salesforce",
    [Integration.Hubspot]: "HubSpot",
    [Integration.Salesloft]: "Salesloft",
  };

  return labels[integration];
};

export const getFriendlyUserRoleLabel = (role: UserRole): string => {
  const labels: { [key in UserRole]: string } = {
    [UserRole.SuperAdmin]: "Super Admin",
    [UserRole.Collaborator]: "Collaborator",
    [UserRole.Admin]: "Admin",
    [UserRole.TechnicalAdmin]: "Technical Admin",
    [UserRole.User]: "User",
  };

  return labels[role];
};

export const getFriendlyMeetingTypeLabel = (
  meetingType: MeetingType
): string => {
  const labels: { [key in MeetingType]: string } = {
    [MeetingType.WebConference]: "Web Conference",
    [MeetingType.InboundCall]: "Inbound Phone Call",
    [MeetingType.OutboundCall]: "Outbound Phone Call",
  };

  return labels[meetingType];
};
