import React from "react";
import { Form, Select } from "antd";
import { useCRMObjects } from "../../../../../util/data_hooks";
import { CRMProvider } from "../../../../../util/types";
import { WorkflowStateEditorFieldName } from "../../shared/WorkflowStateEditorDrawer";
import { WorkflowVariableName } from "../../../../../util/enums";

interface CRMObjectSelectFormItemProps {
  crm: CRMProvider;
  name?: (WorkflowStateEditorFieldName | WorkflowVariableName)[];
  label?: string;
  required?: boolean;
}

export function CRMObjectSelectFormItem({
  crm,
  name = [
    WorkflowStateEditorFieldName.Variables,
    WorkflowVariableName.CRMObjectType,
  ],
  label = "Object type",
  required = true,
}: CRMObjectSelectFormItemProps) {
  const { objects } = useCRMObjects(crm);

  return (
    <Form.Item rules={[{ required }]} label={label} name={name}>
      <Select
        allowClear
        showSearch
        placeholder="Select an object type..."
        options={objects.map(({ crm_object_label, crm_object_name }) => {
          return {
            label: `${crm_object_label} (${crm_object_name})`,
            value: crm_object_name,
          };
        })}
      />
    </Form.Item>
  );
}
