import React, { useState } from "react";
import {
  Button,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Select,
  Space,
} from "antd";
import { CRMFieldType, CRMFieldTypeLabel } from "../../../util/enums";
import { get } from "lodash";
import {
  createFieldConfiguration,
  editFieldConfigurationInfo,
} from "../../../util/api";
import {
  FieldConfiguration,
  FieldConfigurationGroup,
  Team,
} from "../../../util/types";

type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never };

type XOR<T, U> = T | U extends object
  ? (Without<T, U> & U) | (Without<U, T> & T)
  : T | U;

interface CommonProps {
  open: boolean;
  setOpen: (value: boolean) => void;
}

interface CreateProps {
  team: Team;
  fieldConfigurationGroup: FieldConfigurationGroup;
  fieldConfigurationGroups: FieldConfigurationGroup[];
  onCreate?: (fieldConfig: FieldConfiguration) => void;
}

interface EditProps {
  fieldConfiguration: FieldConfiguration;
  fieldConfigurationGroups: FieldConfigurationGroup[];
  onEdit?: (fieldConfig: FieldConfiguration) => void;
}

type FieldType = {
  name: string;
  type: CRMFieldType;
  length?: number;
  picklist_options?: string[];
};

const fieldTypeOptions: { label: string; value: string }[] = [
  {
    label: CRMFieldTypeLabel.Textarea,
    value: CRMFieldType.Textarea,
  },
  {
    label: CRMFieldTypeLabel.Boolean,
    value: CRMFieldType.Boolean,
  },
  {
    label: CRMFieldTypeLabel.Picklist,
    value: CRMFieldType.Picklist,
  },
  {
    label: CRMFieldTypeLabel.Multipicklist,
    value: CRMFieldType.Multipicklist,
  },
  {
    label: CRMFieldTypeLabel.Double,
    value: CRMFieldType.Double,
  },
  {
    label: CRMFieldTypeLabel.Date,
    value: CRMFieldType.Date,
  },
  {
    label: CRMFieldTypeLabel.DateTime,
    value: CRMFieldType.DateTime,
  },
];

export function FieldConfigEditorModal({
  open,
  fieldConfiguration,
  fieldConfigurationGroup,
  fieldConfigurationGroups,
  team,
  onCreate,
  onEdit,
  setOpen,
}: XOR<CreateProps, EditProps> & CommonProps) {
  const [form] = Form.useForm();
  const typeValue = Form.useWatch("type", form);
  const [isLoading, setLoading] = useState(false);
  const createMode = fieldConfiguration === undefined;
  const closeModal = () => {
    form.resetFields();
    setOpen(false);
  };

  const handleEdit = async (values) => {
    setLoading(true);

    try {
      const newFieldConfig = await editFieldConfigurationInfo(
        fieldConfiguration.uuid,
        values
      );
      message.success("Field configuration updated!");

      if (onEdit) onEdit(newFieldConfig);
      closeModal();
    } catch (err) {
      await handleError(err);
    }

    setLoading(false);
  };

  const handleCreate = async (values) => {
    setLoading(true);

    try {
      const newFieldConfig = await createFieldConfiguration({
        team_uuid: team.uuid,
        ...values,
      });

      message.success("Automation Created");

      if (onCreate) onCreate(newFieldConfig);
      closeModal();
    } catch (err) {
      await handleError(err);
    }

    setLoading(false);
  };

  const handleError = async (err) => {
    const defaultMessage =
      "Oops, something is wrong on our end! Please try again later.";

    const is500 = get(err, "response.status", 500) >= 500;
    message.error(
      is500 ? defaultMessage : get(err, "response.data.error", defaultMessage)
    );
  };

  return (
    <Modal
      title={createMode ? `Create Custom Automation` : `Edit Automation`}
      onCancel={closeModal}
      open={open}
      footer={false}
      width={"60%"}
      destroyOnClose
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={createMode ? handleCreate : handleEdit}
        initialValues={
          createMode
            ? { field_configuration_group_uuid: fieldConfigurationGroup?.uuid }
            : {
                field_configuration_group_uuid:
                  fieldConfiguration.field_configuration_group.uuid,
                name: fieldConfiguration.label,
                type: fieldConfiguration.field_type,
                length: fieldConfiguration.length,
                picklist_options: fieldConfiguration.picklist.map(
                  (p) => p.label
                ),
              }
        }
      >
        <Form.Item
          label="Field Configuration Group"
          name="field_configuration_group_uuid"
          rules={[{ required: true }]}
        >
          <Select
            options={fieldConfigurationGroups
              .filter((group) => !group.synced_crm_object)
              .map((group) => {
                return { label: group.label, value: group.uuid };
              })}
          />
        </Form.Item>

        <Form.Item<Partial<FieldType>>
          label="Name"
          name="name"
          rules={[{ required: true }]}
        >
          <Input placeholder="Enter a name" />
        </Form.Item>

        <Form.Item<Partial<FieldType>>
          label="Field Type"
          name="type"
          rules={[{ required: true }]}
        >
          <Select options={fieldTypeOptions} disabled={!createMode} />
        </Form.Item>

        {[CRMFieldType.Multipicklist, CRMFieldType.Picklist].includes(
          typeValue
        ) && (
          <Form.Item<Partial<FieldType>>
            label="Picklist Options"
            name="picklist_options"
            rules={[{ required: true }]}
          >
            <Select
              mode="tags"
              placeholder="Enter a picklist option and press enter"
            />
          </Form.Item>
        )}

        {CRMFieldType.Textarea == typeValue && (
          <Form.Item<Partial<FieldType>>
            label="Max Length"
            name="length"
            rules={[{ required: true }]}
            tooltip={
              "Enter the maximum amount of characters that this field can contain. Must be between 1-32000"
            }
          >
            <InputNumber min={1} max={32000} />
          </Form.Item>
        )}

        <Space>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Save
          </Button>

          <Button onClick={closeModal}>Cancel</Button>
        </Space>
      </Form>
    </Modal>
  );
}
