import React from "react";
import { Organization, Team, User } from "../../../util/types";
import { Modal, Table, Tabs } from "antd";
import { ColumnsType } from "antd/es/table";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import { green, red } from "@ant-design/colors";
import { TeamEditorForm } from "./TeamEditorForm";

interface TeamEditorModalProps {
  organization: Organization;
  team: Team;
  open: boolean;
  setOpen: (value: boolean) => void;
  afterSave: () => Promise<void>;
}

export function TeamEditorModal({
  organization,
  team,
  open,
  setOpen,
  afterSave,
}: TeamEditorModalProps) {
  const columns: ColumnsType<User> = [
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: `CRM Role`,
      dataIndex: ["crm_role", "name"],
    },
    {
      title: `Active`,
      dataIndex: "active",
      align: "center",
      render: (active) =>
        active ? (
          <CheckCircleTwoTone twoToneColor={green.primary} />
        ) : (
          <CloseCircleTwoTone twoToneColor={red.primary} />
        ),
    },
  ];

  return (
    <Modal
      title={team ? team.name : "Add New Team"}
      onCancel={() => setOpen(false)}
      open={open}
      destroyOnClose
      width={"75%"}
      footer={false}
    >
      {team ? (
        <Tabs
          items={[
            {
              key: "users",
              label: "Team Members",
              children: (
                <Table<User>
                  rowKey={({ uuid }) => uuid}
                  dataSource={team?.users}
                  columns={columns}
                  pagination={false}
                />
              ),
            },
            {
              key: "edit",
              label: "Edit Team",
              children: (
                <TeamEditorForm
                  team={team}
                  organization={organization}
                  afterSave={afterSave}
                />
              ),
            },
          ]}
        />
      ) : (
        <TeamEditorForm organization={organization} afterSave={afterSave} />
      )}
    </Modal>
  );
}
