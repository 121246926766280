import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Empty,
  Form,
  Radio,
  Skeleton,
  Typography,
} from "antd";
import { useSlackChannels, useUser } from "../../../../util/data_hooks";
import { WorkflowStateEditorFieldName } from "../shared/WorkflowStateEditorDrawer";
import { Integration, WorkflowVariableName } from "../../../../util/enums";
import { useIntegrationWindow } from "../../../hooks/useIntegrationWindow";
import { SlackUsersSelectFormItem } from "./shared/SlackUsersSelectFormItem";
import { DynamicVariableSelect } from "./shared/dynamic_variables/DynamicVariableSelect";
import { DynamicVariableTextarea } from "./shared/dynamic_variables/DynamicVariableTextarea";

enum DestinationType {
  ExistingChannel = "existing_channel",
  MessageThread = "message_thread",
  DirectMessage = "direct_message",
}

function SlackChannelsSelectFormItem() {
  const form = Form.useFormInstance();

  const { channels } = useSlackChannels();

  return (
    <Form.Item
      rules={[{ required: true }]}
      label="Channel Name"
      name={[
        WorkflowStateEditorFieldName.Variables,
        WorkflowVariableName.SlackChannelId,
      ]}
    >
      <DynamicVariableSelect
        staticOptions={channels.map(({ channel_id, channel_name }) => {
          return {
            label: `#${channel_name}`,
            value: channel_id,
          };
        })}
        value={form.getFieldValue([
          WorkflowStateEditorFieldName.Variables,
          WorkflowVariableName.SlackChannelId,
        ])}
        onChange={(value) => {
          form.setFieldValue(
            [
              WorkflowStateEditorFieldName.Variables,
              WorkflowVariableName.SlackChannelId,
            ],
            value
          );
        }}
      />
    </Form.Item>
  );
}

export function SendSlackMessage() {
  const form = Form.useFormInstance();

  const [openIntegrationWindow] = useIntegrationWindow();

  const { user, isLoading: isUserLoading } = useUser();

  const [destinationType, setDestinationType] = useState<DestinationType>(null);

  useEffect(() => {
    const dynamicNames = {
      [DestinationType.ExistingChannel]: "Send Slack Message",
      [DestinationType.DirectMessage]: "Send Slack DM",
      [DestinationType.MessageThread]: "Send Slack Message to Thread",
    };

    if (!form.getFieldValue(WorkflowStateEditorFieldName.Name)) {
      form.setFieldValue(
        WorkflowStateEditorFieldName.Name,
        dynamicNames[destinationType]
      );
    }
  }, [destinationType]);

  Form.useWatch((formValues) => {
    const destinationType =
      formValues?.variables?.slack_message_destination_type;

    if (destinationType) setDestinationType(destinationType);
  }, form);

  if (isUserLoading) return <Skeleton active />;

  if (!user.organization.slack_workspace) {
    return (
      <Empty description="Slack is not connected for your organization">
        <Button
          type="primary"
          onClick={() => openIntegrationWindow(Integration.Slack)}
        >
          Connect Slack
        </Button>
      </Empty>
    );
  }

  return (
    <>
      <div style={{ marginBottom: 15 }}>
        <Typography.Title level={4}>
          Select Message Destination
        </Typography.Title>

        <Typography.Text type="secondary">
          Where should the Slack message be sent?
        </Typography.Text>
      </div>

      <Form.Item
        rules={[{ required: true }]}
        label="Message Destination"
        name={[
          WorkflowStateEditorFieldName.Variables,
          WorkflowVariableName.SlackMessageDestinationType,
        ]}
      >
        <Radio.Group
          optionType="button"
          options={[
            {
              label: "Existing Channel",
              value: DestinationType.ExistingChannel,
            },
            {
              label: "Direct Message",
              value: DestinationType.DirectMessage,
            },
            {
              label: "Thread",
              value: DestinationType.MessageThread,
            },
          ]}
        />
      </Form.Item>

      {destinationType === DestinationType.ExistingChannel && (
        <>
          <SlackChannelsSelectFormItem />
          <SlackUsersSelectFormItem label="Users to Invite" />
        </>
      )}

      {destinationType === DestinationType.DirectMessage && (
        <SlackUsersSelectFormItem required label="Users" />
      )}

      {destinationType === DestinationType.MessageThread && (
        <>
          <SlackChannelsSelectFormItem />

          <Form.Item
            rules={[{ required: true }]}
            label="Message Id"
            name={[
              WorkflowStateEditorFieldName.Variables,
              WorkflowVariableName.SlackMessageId,
            ]}
          >
            <DynamicVariableSelect
              value={form.getFieldValue([
                WorkflowStateEditorFieldName.Variables,
                WorkflowVariableName.SlackMessageId,
              ])}
              onChange={(value) => {
                form.setFieldValue(
                  [
                    WorkflowStateEditorFieldName.Variables,
                    WorkflowVariableName.SlackMessageId,
                  ],
                  value
                );
              }}
            />
          </Form.Item>
        </>
      )}

      <Divider />

      <Form.Item
        rules={[{ required: true }]}
        label="Message Body"
        name={[
          WorkflowStateEditorFieldName.Variables,
          WorkflowVariableName.SlackMessageBody,
        ]}
      >
        <DynamicVariableTextarea
          value={form.getFieldValue([
            WorkflowStateEditorFieldName.Variables,
            WorkflowVariableName.SlackMessageBody,
          ])}
          onChange={(value) => {
            form.setFieldValue(
              [
                WorkflowStateEditorFieldName.Variables,
                WorkflowVariableName.SlackMessageBody,
              ],
              value
            );
          }}
        />
      </Form.Item>
    </>
  );
}
