import React from "react";
import { merge } from "lodash";

import swyftLogo from "../../../assets/images/logo-small.png";
import swyftLogoInverted from "../../../assets/images/logo-small-inverted.png";

interface Props {
  inverted?: boolean;
  width?: number;
  style?: React.CSSProperties;
}

export function SwyftIcon({ inverted = false, style, width = 40 }: Props) {
  return (
    <img
      src={inverted ? swyftLogoInverted : swyftLogo}
      sizes="small"
      style={merge(style, { width, display: "flex", alignSelf: "center" })}
      alt={"Swyft Logo"}
    />
  );
}
