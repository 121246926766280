import {
  Button,
  Card,
  Col,
  Collapse,
  Descriptions,
  Drawer,
  Row,
  Skeleton,
  Space,
  Tag,
  Timeline,
  Tooltip,
  Typography,
  message,
} from "antd";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useWorkflowInstance,
  useWorkflowInstances,
} from "../../../util/data_hooks";
import { getFormattedDate } from "../../../helpers/date_helpers";
import JsonView from "react18-json-view";
import { isEmpty } from "lodash";
import {
  StopTwoTone,
  CheckCircleTwoTone,
  RedoOutlined,
  RightOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import { retryWorkflowInstance } from "../../../util/api";
import {
  getFriendlyOperatorLabel,
  getFriendlyWorkflowActionLabel,
  getFriendlyWorkflowStatusLabel,
} from "../../../helpers/label_maps";
import { WorkflowStatus } from "../../../util/enums";
import "react18-json-view/src/style.css";
import { getFriendlyVariableName } from "./util/helpers";
import { green, red, blue } from "@ant-design/colors";
import { truncate } from "lodash";
import { isNullOrUndefined } from "../../../helpers/isNullOrUndefined";
import { TransitionConditionalDataRuleGroup } from "../../../util/types";

function ConditionalResultIcon({ result }: { result: boolean }) {
  if (result === true) {
    return (
      <Tooltip title="True">
        <CheckCircleTwoTone twoToneColor={green.primary} />
      </Tooltip>
    );
  } else if (result === false) {
    return (
      <Tooltip title="False">
        <StopTwoTone twoToneColor={red.primary} />
      </Tooltip>
    );
  }

  return null;
}

function ConditionalResults({
  query,
}: {
  query: TransitionConditionalDataRuleGroup;
}) {
  return (
    <Card
      size="small"
      title={
        <Space>
          <Typography.Text>
            {query.combinator.toUpperCase()} Rule Group
          </Typography.Text>
        </Space>
      }
    >
      <Row gutter={[0, 12]}>
        {query.rules.map((query, i) => {
          const { result, combinator } = query;
          if (combinator) {
            return <ConditionalResults key={i} query={query} />;
          } else {
            const {
              rule_field,
              rule_value,
              left_operand,
              right_operand,
              operator_name,
            } = query;
            const parsedRuleFieldVariable = getFriendlyVariableName(
              rule_field?.toString()
            );
            const parsedRuleValueVariable = getFriendlyVariableName(
              rule_value?.toString()
            );

            return (
              <Col key={i} span={24}>
                <Tag
                  style={{ padding: 5 }}
                  icon={<ConditionalResultIcon result={result} />}
                >
                  <Space>
                    <>
                      {parsedRuleFieldVariable ? (
                        <Tooltip
                          title={
                            isNullOrUndefined(left_operand)
                              ? "Empty"
                              : truncate(left_operand, {
                                  length: 100,
                                })
                          }
                        >
                          <Typography.Text
                            strong
                            style={{
                              backgroundColor: blue[0],
                              padding: 2,
                              borderRadius: 2,
                            }}
                          >
                            {`{{${parsedRuleFieldVariable}}}`}
                          </Typography.Text>
                        </Tooltip>
                      ) : (
                        <Typography.Text>
                          {rule_field?.toString()}
                        </Typography.Text>
                      )}
                    </>

                    <Typography.Text italic>
                      {getFriendlyOperatorLabel(operator_name)}
                    </Typography.Text>

                    {right_operand && (
                      <>
                        {parsedRuleValueVariable ? (
                          <Tooltip
                            title={
                              isNullOrUndefined(right_operand)
                                ? "Empty"
                                : truncate(right_operand, { length: 100 })
                            }
                          >
                            <Typography.Text
                              strong
                              style={{
                                backgroundColor: blue[0],
                                padding: 2,
                                borderRadius: 2,
                              }}
                            >
                              {`{{${parsedRuleValueVariable}}}`}
                            </Typography.Text>
                          </Tooltip>
                        ) : (
                          <Typography.Text strong>
                            {rule_value?.toString()}
                          </Typography.Text>
                        )}
                      </>
                    )}
                  </Space>
                </Tag>
              </Col>
            );
          }
        })}
      </Row>
    </Card>
  );
}

export function WorkflowInstanceDrawer() {
  const navigate = useNavigate();
  const { workflowUuid, workflowInstanceUuid } = useParams();

  const [retryingWorkflow, setRetryingWorkflow] = useState<boolean>();
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const { workflowInstances, mutate: mutateWorkflowInstances } =
    useWorkflowInstances(workflowUuid);

  const {
    workflowInstance,
    isLoading,
    mutate: mutateWorkflowInstance,
  } = useWorkflowInstance(workflowUuid, workflowInstanceUuid);

  const currentIndex = workflowInstances.findIndex(
    ({ uuid }) => uuid === workflowInstance?.uuid
  );

  return (
    <Drawer
      open={isOpen}
      onClose={() => setIsOpen(false)}
      afterOpenChange={(open) =>
        !open && navigate(`/dashboard/settings/workflows/${workflowUuid}`)
      }
      width="90rem"
      footer={null}
      title={
        <Row align="middle" justify="space-between">
          <Col>
            <Typography.Title level={5} style={{ margin: 0 }}>
              Workflow log for {workflowInstance?.user_email} on{" "}
              {getFormattedDate(workflowInstance?.created_at)}
            </Typography.Title>
          </Col>

          <Col>
            <Space>
              <Button
                disabled={currentIndex === 0}
                onClick={() => {
                  const prevInstance = workflowInstances[currentIndex - 1];
                  if (!prevInstance) return;
                  navigate(
                    `/dashboard/settings/workflows/${workflowUuid}/workflow-instances/${prevInstance.uuid}`
                  );
                }}
                shape="circle"
                icon={<LeftOutlined />}
              />

              <Button
                onClick={() => {
                  const nextInstance = workflowInstances[currentIndex + 1];
                  if (!nextInstance) return;
                  navigate(
                    `/dashboard/settings/workflows/${workflowUuid}/workflow-instances/${nextInstance.uuid}`
                  );
                }}
                disabled={currentIndex === workflowInstances?.length - 1}
                shape="circle"
                icon={<RightOutlined />}
              />
            </Space>
          </Col>
        </Row>
      }
    >
      {isLoading ? (
        <Skeleton />
      ) : (
        <Timeline
          items={[
            ...workflowInstance.workflow_logs.map(
              ({
                uuid,
                status,
                action_type,
                variables,
                transition_conditional_data,
                error_message,
                mapped_response,
                raw_response,
                created_at,
                workflow_state,
              }) => {
                let color: string;

                switch (status) {
                  case WorkflowStatus.InProgress: {
                    color = "blue";
                    break;
                  }
                  case WorkflowStatus.Complete: {
                    color = "green";
                    break;
                  }
                  case WorkflowStatus.Error: {
                    color = "red";
                    break;
                  }
                }

                const collapsibleItems = [
                  ...(variables
                    ? [
                        {
                          label: "Request",
                          children: <JsonView src={variables} collapsed={2} />,
                        },
                      ]
                    : []),

                  ...(!isEmpty(mapped_response)
                    ? [
                        {
                          label: "Mapped Response",
                          children: <JsonView src={mapped_response} />,
                        },
                      ]
                    : []),

                  ...(raw_response
                    ? [
                        {
                          label: "Response",
                          children: (
                            <JsonView src={raw_response} collapsed={2} />
                          ),
                        },
                      ]
                    : []),

                  ...(transition_conditional_data
                    ? [
                        {
                          label: (
                            <Space>
                              <ConditionalResultIcon
                                result={transition_conditional_data.result}
                              />

                              <Typography.Text>
                                {transition_conditional_data.name}
                              </Typography.Text>
                            </Space>
                          ),
                          children: transition_conditional_data.query ? (
                            <ConditionalResults
                              query={transition_conditional_data.query}
                            />
                          ) : (
                            "No Data"
                          ),
                        },
                      ]
                    : []),

                  ...(error_message
                    ? [
                        {
                          label: "Error",
                          children: JSON.parse(error_message),
                        },
                      ]
                    : []),
                ];

                return {
                  color,
                  children: (
                    <Descriptions
                      key={uuid}
                      size="small"
                      layout="vertical"
                      colon={false}
                      column={1}
                    >
                      <Descriptions.Item label={getFormattedDate(created_at)}>
                        {transition_conditional_data ? (
                          <Typography.Text strong>Condition</Typography.Text>
                        ) : (
                          <Typography.Text strong>
                            {workflow_state?.name ||
                              getFriendlyWorkflowActionLabel(action_type) ||
                              getFriendlyWorkflowStatusLabel(status)}
                            {status === WorkflowStatus.Error ? " (Error)" : ""}
                          </Typography.Text>
                        )}
                      </Descriptions.Item>

                      {collapsibleItems.length > 0 && (
                        <Descriptions.Item>
                          <Collapse
                            accordion
                            style={{ width: "100%" }}
                            items={collapsibleItems}
                          />
                        </Descriptions.Item>
                      )}
                    </Descriptions>
                  ),
                };
              }
            ),

            ...(workflowInstance.status === WorkflowStatus.Complete
              ? [
                  {
                    color: "green",
                    children: (
                      <Typography.Text strong>
                        Workflow Completed Successfully
                      </Typography.Text>
                    ),
                  },
                ]
              : []),

            ...(workflowInstance.status === WorkflowStatus.Error
              ? [
                  {
                    color: "red",
                    children: (
                      <Row gutter={[0, 12]}>
                        <Col span={24}>
                          <Typography.Text strong>
                            Workflow Completed with Errors
                          </Typography.Text>
                        </Col>

                        <Col span={24}>
                          <Button
                            type="primary"
                            icon={<RedoOutlined />}
                            disabled={isLoading || retryingWorkflow}
                            loading={retryingWorkflow}
                            onClick={async () => {
                              setRetryingWorkflow(true);

                              try {
                                await retryWorkflowInstance(
                                  workflowUuid,
                                  workflowInstanceUuid
                                );

                                await mutateWorkflowInstance();
                                await mutateWorkflowInstances();
                              } catch (e) {
                                message.error(
                                  e.response?.data?.message ||
                                    "An error occurred. Please try again."
                                );
                              }

                              setRetryingWorkflow(false);
                            }}
                          >
                            {retryingWorkflow
                              ? "Retrying Workflow..."
                              : "Retry"}
                          </Button>
                        </Col>
                      </Row>
                    ),
                  },
                ]
              : []),
          ]}
        />
      )}
    </Drawer>
  );
}
