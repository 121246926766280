import React from "react";

import { FieldConfiguration } from "../../../../util/types";
import { CRMFieldType } from "../../../../util/enums";
import { Form, Input, InputNumber, Select, Typography } from "antd";

import { getContentPreview } from "../../../../util/auto_generated_field_helpers";
import DatePicker from "../../../shared/DatePicker";

interface Props {
  fieldConfiguration: FieldConfiguration;
  isEditing: boolean;
  isScreeningEnabled: boolean;
  isDefaultEnabled: boolean;
}

export function DefaultValue({
  fieldConfiguration,
  isEditing,
  isScreeningEnabled,
  isDefaultEnabled,
}: Props) {
  const {
    field_type: type,
    picklist,
    screening_default: screeningDefault,
    length,
  } = fieldConfiguration;

  const isDisabled = !isScreeningEnabled || !isDefaultEnabled;
  const picklist_options = picklist.map((item) => ({
    label: item.label,
    value: item.value,
  }));

  let formItem = <></>;
  const validationRules: any[] = [
    {
      required: isDefaultEnabled && isScreeningEnabled,
    },
  ];
  switch (type) {
    case CRMFieldType.String:
    case CRMFieldType.Textarea:
    case CRMFieldType.HTML:
    case CRMFieldType.EncryptedString: {
      formItem = (
        <Input.TextArea
          placeholder="Default insight content"
          autoSize={{ maxRows: 19 }}
          disabled={isDisabled}
        />
      );
      validationRules.push({
        type: "string",
        max: length || 255,
        message: "${label} must be up to ${max} characters",
      });
      break;
    }
    case CRMFieldType.Picklist:
    case CRMFieldType.Reference: {
      formItem = <Select options={picklist_options} disabled={isDisabled} />;
      break;
    }
    case CRMFieldType.Multipicklist: {
      formItem = (
        <Select
          mode="multiple"
          options={picklist_options}
          disabled={isDisabled}
        /> // TODO: Undefined initial value shows up. Add array to initial values of form...
      );
      break;
    }
    case CRMFieldType.Double:
    case CRMFieldType.Currency:
    case CRMFieldType.Percent: {
      formItem = (
        <InputNumber
          step={1}
          placeholder="Default insight content"
          style={{ width: "100%" }}
          changeOnWheel={false}
        />
      );
      break;
    }
    case CRMFieldType.Date: {
      formItem = (
        <DatePicker
          placeholder={"Default insight content"}
          style={{ width: "100%" }}
          format="MM/DD/YYYY"
        />
      );
      break;
    }
    case CRMFieldType.DateTime: {
      formItem = (
        <DatePicker
          placeholder={"Default insight content"}
          showTime
          use12Hours
          format="MM/DD/YYYY h:mm A"
          style={{ width: "100%" }}
        />
      );
      break;
    }
  }

  return isEditing ? (
    <Form.Item
      name="screening_default"
      rules={validationRules}
      label="Default Value"
      hidden={!isDefaultEnabled}
    >
      {formItem}
    </Form.Item>
  ) : (
    <>
      <Typography.Text type="secondary" style={{ marginBottom: "1rem" }}>
        Default Content:{" "}
        <Typography.Text>
          {" "}
          {screeningDefault
            ? getContentPreview(screeningDefault, type, picklist)
            : "--"}
        </Typography.Text>
      </Typography.Text>
    </>
  );
}
