import React, { useEffect } from "react";
import { Modal, Form, message, Checkbox, Select } from "antd";
import { get } from "lodash";
import { updateUser } from "../../../util/api";
import { Organization, User } from "../../../util/types";
import { UserRole } from "../../../util/enums";
import { useUser } from "../../../util/data_hooks";
import { RolesTooltip } from "./RolesTooltip";

interface Props {
  user: User;
  organization: Organization;
  open: boolean;
  setOpen: (value: boolean) => void;
  afterSave: () => void;
}

export function UserEditorModal({ user, open, setOpen, afterSave }: Props) {
  const { user: currentUser } = useUser();

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(user);
  }, [open, user]);

  const handleSave = async () => {
    try {
      await updateUser(user.uuid, form.getFieldsValue());
      message.success("User updated");
      afterSave();
      setOpen(false);
    } catch (err) {
      const defaultMessage =
        "Oops, something is wrong on our end! Please try again later.";

      const is500 = get(err, "response.status", 500) >= 500;

      message.error(
        is500 ? defaultMessage : get(err, "response.data.error", defaultMessage)
      );
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setOpen(false);
  };

  return (
    <Modal
      title={`Edit ${user?.email}`}
      onCancel={handleCancel}
      open={open}
      destroyOnClose
      onOk={handleSave}
      okText="Save"
      maskClosable={false}
    >
      <Form form={form} layout="vertical" style={{ marginTop: "1rem" }}>
        <Form.Item label="Active" name="active" valuePropName="checked">
          <Checkbox disabled={currentUser?.uuid === user?.uuid} />
        </Form.Item>

        <Form.Item label="User Roles" name="roles" extra={<RolesTooltip />}>
          <Select
            mode="multiple"
            options={[
              { value: UserRole.User, label: "Standard User (Paid Seat)" },
              { value: UserRole.Collaborator, label: "Collaborator" },
              { value: UserRole.Admin, label: "Admin" },
              {
                disabled: !currentUser?.roles?.includes(UserRole.SuperAdmin), // TODO: Remove feature flag
                value: UserRole.TechnicalAdmin,
                label: "Technical Admin",
              },
              ...(user?.roles.includes(UserRole.SuperAdmin)
                ? [
                    {
                      disabled: true,
                      value: UserRole.SuperAdmin,
                      label: "Super Admin",
                    },
                  ]
                : []),
            ]}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
