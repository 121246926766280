import React from "react";
import { Tag } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import { getFormattedDate } from "../../helpers/date_helpers";

export function DateTag({ dateTime }: { dateTime: Date }) {
  return (
    <Tag color="default" icon={<CalendarOutlined />} style={{ margin: 0 }}>
      {getFormattedDate(dateTime)}
    </Tag>
  );
}
