import { Button, Card, Col, message, Row, Typography } from "antd";
import { useIntegrationWindow } from "../../hooks/useIntegrationWindow";
import React, { ReactElement } from "react";
import { IntegrationLogo } from "../../shared/IntegrationLogo";
import { IntegrationInfo } from "../../../util/types";
import { getFriendlyIntegrationLabel } from "../../../helpers/label_maps";

export interface IntegrationCardProps {
  integration: IntegrationInfo;
  name: string;
  description: string | ReactElement;
  adminManaged?: boolean;
  updateIntegrations: () => void;
}

export function IntegrationCard({
  integration,
  name,
  description,
  updateIntegrations,
}: IntegrationCardProps) {
  const [openIntegrationWindow] = useIntegrationWindow();

  const onClick = async () => {
    await openIntegrationWindow(integration.name);
    updateIntegrations();
    message.success(
      `${getFriendlyIntegrationLabel(integration.name)} successfully connected!`
    );
  };

  return (
    <>
      <Card key={integration.name} style={{ marginBottom: 10 }}>
        <Row justify="space-between" align="middle" gutter={[0, 20]}>
          <Row align="middle" gutter={24} wrap={false}>
            <Col>
              <IntegrationLogo type={integration.name} />
            </Col>

            <Col>
              <Row gutter={8} align="middle" style={{ marginBottom: 8 }}>
                <Col>
                  <Typography.Title level={5} style={{ margin: 0 }}>
                    {name}
                  </Typography.Title>
                </Col>
              </Row>

              <Typography.Text type="secondary">{description}</Typography.Text>
            </Col>
          </Row>

          <Col>
            <Button
              type={integration.connected ? "primary" : "default"}
              onClick={onClick}
            >
              {integration.connected ? "Connected" : "Connect"}
            </Button>
          </Col>
        </Row>
      </Card>
    </>
  );
}
