import React, { useEffect, useState } from "react";
import { Modal, Input, Form, Button, message, Space, InputNumber } from "antd";
import { get } from "lodash";
import { createProduct, updateProduct } from "../../../util/api";
import { Product } from "../../../util/types";

interface Props {
  product: Product;
  open: boolean;
  onClose: () => void;
  afterSave: () => void;
}

export function ProductEditorModal({
  product,
  open,
  onClose,
  afterSave,
}: Props) {
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  useEffect(() => {
    form.setFieldsValue(product);
  }, [product]);

  const handleSave = async (values: any) => {
    setLoading(true);

    try {
      const body = {
        name: values.name,
        max_field_automations: values.max_field_automations,
      };

      product
        ? await updateProduct(product.uuid, body)
        : await createProduct(body);

      form.resetFields();
      message.success("Product saved");

      afterSave();
      onClose();
    } catch (err) {
      const defaultMessage =
        "Oops, something is wrong on our end! Please try again later.";

      const is500 = get(err, "response.status", 500) >= 500;

      message.error(
        is500 ? get(err, "response.data.error", defaultMessage) : defaultMessage
      );
    }

    setLoading(false);
  };

  return (
    <Modal
      title={product ? "Edit Product" : "Create Product"}
      onCancel={handleCancel}
      open={open}
      footer={false}
      destroyOnClose
    >
      <Form
        form={form}
        onFinish={handleSave}
        layout="vertical"
        style={{ marginTop: "1rem" }}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please input a product name!",
            },
          ]}
        >
          <Input placeholder="Enter a name" />
        </Form.Item>

        <Form.Item
          label="Field Automations"
          name="max_field_automations"
          rules={[{ required: true }]}
        >
          <InputNumber />
        </Form.Item>

        <Space>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Submit
          </Button>

          <Button onClick={handleCancel}>Cancel</Button>
        </Space>
      </Form>
    </Modal>
  );
}
