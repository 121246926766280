import React from "react";
import { Form, Select } from "antd";
import { useMyIntegrations } from "../../../../../util/data_hooks";
import { CRM_PROVIDERS } from "../../../../../util/constants";
import { getFriendlyIntegrationLabel } from "../../../../../helpers/label_maps";
import { WorkflowStateEditorFieldName } from "../../shared/WorkflowStateEditorDrawer";
import { WorkflowVariableName } from "../../../../../util/enums";

export function CRMSelectFormItem() {
  const { crmIntegration, isLoading } = useMyIntegrations();

  return (
    <Form.Item
      rules={[{ required: true }]}
      label="CRM"
      name={[WorkflowStateEditorFieldName.Variables, WorkflowVariableName.CRM]}
    >
      <Select
        placeholder="Select a CRM..."
        loading={isLoading}
        options={CRM_PROVIDERS.map((value) => {
          return {
            label: getFriendlyIntegrationLabel(value),
            value,
            disabled: isLoading ? true : crmIntegration.name !== value,
          };
        })}
      />
    </Form.Item>
  );
}
