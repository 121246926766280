import React, { useState } from "react";
import {
  Button,
  Typography,
  message,
  Space,
  Row,
  Col,
  Empty,
  Skeleton,
  Divider,
  Modal,
  Input,
  Tooltip,
  Tabs,
} from "antd";
import {
  useOrganization,
  useTeam,
  useTeamFieldConfigurationGroups,
} from "../../../util/data_hooks";
import { updateTeamFieldConfigurationGroupOrder } from "../../../util/api";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";
import { FieldConfigurationsTable } from "./FieldConfigurationsTable";
import { FieldConfigurationGroupModal } from "./FieldConfigurationGroupModal";
import {
  SearchOutlined,
  PlusCircleOutlined,
  WarningTwoTone,
} from "@ant-design/icons";
import { SortableTable } from "../../shared/SortableTable";
import { usePageTitle } from "../../hooks/usePageTitle";
import { useSearchParamsState } from "../../hooks/useSearchParamsState";
import { TeamSelectBanner } from "../shared/TeamSelectBanner";
import { ContextTab } from "./field_configuration/context_tab/ContextTab";

enum ConfigurationTab {
  FieldConfigurations = "field-configurations",
  Context = "context",
}

export function FieldConfigurations() {
  usePageTitle("Field Configurations");

  const { organizationUuid } = useParams();

  // Team dropdown selector state
  const selectedTeam = useAppSelector((state) => state.team.selectedTeam);
  const teamSelectLoading = useAppSelector((state) => state.team.isLoading);

  const [syncCRMObjectModalOpen, setSyncCRMObjectModalOpen] =
    useState<boolean>(false);
  const [fieldGroupOrderModalOpen, setFieldGroupModalOpen] =
    useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<ConfigurationTab>(
    ConfigurationTab.FieldConfigurations
  );

  const [filterValue, setFilterValue] = useSearchParamsState(
    "filter_value",
    null
  );

  const {
    organization,
    isLoading: isOrganizationLoading,
    mutate: refreshOrganization,
  } = useOrganization(organizationUuid);

  const {
    team,
    isLoading: isTeamLoading,
    mutate: refreshTeam,
  } = useTeam(selectedTeam?.uuid);

  const {
    fieldConfigurationGroups,
    isLoading: isTeamFieldConfigurationGroupsLoading,
    mutate: mutateTeamFieldConfigurationGroups,
  } = useTeamFieldConfigurationGroups(selectedTeam?.uuid);

  const isLoading =
    isOrganizationLoading ||
    isTeamLoading ||
    teamSelectLoading ||
    isTeamFieldConfigurationGroupsLoading;

  if (isLoading) return <Skeleton active />;

  return (
    <>
      <FieldConfigurationGroupModal
        open={syncCRMObjectModalOpen}
        organization={organization}
        team={team}
        onClose={() => setSyncCRMObjectModalOpen(false)}
        onSave={() => {
          mutateTeamFieldConfigurationGroups();
          setSyncCRMObjectModalOpen(false);
        }}
      />

      <Modal
        open={fieldGroupOrderModalOpen}
        title="Update Group Order"
        okText="Done"
        onOk={() => setFieldGroupModalOpen(false)}
        onCancel={() => setFieldGroupModalOpen(false)}
      >
        <SortableTable
          pagination={false}
          dataSource={fieldConfigurationGroups}
          filteredDataSource={fieldConfigurationGroups}
          columns={[{ title: "Group Label", dataIndex: "label" }]}
          onPositionUpdate={async ({ newOrder }) => {
            const fieldConfigurationGroups = newOrder.map(({ uuid }, index) => {
              return { uuid, position: index + 1 };
            });

            try {
              await updateTeamFieldConfigurationGroupOrder({
                teamUuid: team.uuid,
                fieldConfigurationGroups,
              });

              mutateTeamFieldConfigurationGroups();
              message.success("Group order updated");
            } catch (e) {
              message.error(
                `Error updating group order: ${e.response.data.message}`
              );
            }
          }}
        />
      </Modal>

      <TeamSelectBanner />

      <Tabs
        activeKey={activeTab}
        defaultActiveKey={ConfigurationTab.FieldConfigurations}
        onChange={(key) => setActiveTab(key as ConfigurationTab)}
        items={[
          {
            key: ConfigurationTab.FieldConfigurations,
            label: "Field Configurations",
            children: (
              <>
                <Row justify="space-between">
                  <Col>
                    <Space>
                      <Typography.Text type="secondary">
                        Enabled Field Automations:{" "}
                        {team.field_automations_allocated} /{" "}
                        {organization.max_field_automations}
                      </Typography.Text>

                      {team.field_automations_available === 0 && (
                        <Tooltip title="The max number of automations for this team has been reached">
                          <WarningTwoTone twoToneColor="#e0990b" />
                        </Tooltip>
                      )}
                    </Space>
                  </Col>

                  <Col>
                    <Space>
                      <div style={{ width: "30rem" }}>
                        <Input
                          allowClear
                          value={filterValue}
                          addonBefore={<SearchOutlined />}
                          onChange={(e) => setFilterValue(e.target.value)}
                          placeholder="Filter fields by name or label"
                        />
                      </div>

                      <Button
                        icon={<PlusCircleOutlined />}
                        onClick={() => setSyncCRMObjectModalOpen(true)}
                        type="primary"
                      >
                        Create New Group
                      </Button>
                    </Space>
                  </Col>
                </Row>

                <>
                  {fieldConfigurationGroups.length > 0 ? (
                    <>
                      {fieldConfigurationGroups?.map(
                        (fieldConfigurationGroup) => {
                          return (
                            <div key={fieldConfigurationGroup.uuid}>
                              <FieldConfigurationsTable
                                team={team}
                                fieldConfigurationGroup={
                                  fieldConfigurationGroup
                                }
                                filterValue={filterValue}
                                onUpdateGroupOrderClick={() =>
                                  setFieldGroupModalOpen(true)
                                }
                                onSave={() =>
                                  mutateTeamFieldConfigurationGroups()
                                }
                              />

                              <Divider />
                            </div>
                          );
                        }
                      )}
                    </>
                  ) : (
                    <Empty
                      style={{ marginTop: 200 }}
                      description={
                        <Space direction="vertical">
                          <Typography.Title level={5}>
                            No Field Configurations Found
                          </Typography.Title>

                          <Button
                            onClick={() => setSyncCRMObjectModalOpen(true)}
                          >
                            Create Field Configuration Group
                          </Button>
                        </Space>
                      }
                    />
                  )}
                </>
              </>
            ),
          },
          {
            key: ConfigurationTab.Context,
            label: "Context",
            children: (
              <ContextTab
                organization={organization}
                team={team}
                refreshOrganization={refreshOrganization}
                refreshTeam={refreshTeam}
              />
            ),
          },
        ]}
      />
    </>
  );
}
