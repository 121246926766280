import { PhoneOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { MeetingType } from "../../util/enums";
import React from "react";
import { Tooltip } from "antd";
import { getFriendlyMeetingTypeLabel } from "../../helpers/label_maps";

export function MeetingTypeIcon({ meetingType }: { meetingType: MeetingType }) {
  let icon = null;

  switch (meetingType) {
    case MeetingType.InboundCall:
    case MeetingType.OutboundCall:
      icon = <PhoneOutlined />;
      break;
    case MeetingType.WebConference:
      icon = <VideoCameraOutlined />;
      break;
  }

  if (!icon) return null;

  return (
    <Tooltip title={getFriendlyMeetingTypeLabel(meetingType)}>{icon}</Tooltip>
  );
}
