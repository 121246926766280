import { Typography } from "antd";
import React from "react";
import { InfoCircleOutlined } from "@ant-design/icons";

export function RolesTooltip() {
  return (
    <div style={{ margin: "7px 0" }}>
      <Typography.Link
        target="_blank"
        href="https://help.swyftai.com/en/articles/9547083-roles-permissions"
      >
        <InfoCircleOutlined /> Learn more about roles & permissions
      </Typography.Link>
    </div>
  );
}
