import React from "react";
import { Meeting } from "../../util/types";
import {
  CheckCircleOutlined,
  EyeOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { blue } from "@ant-design/colors";
import { Space, Tag, Typography } from "antd";
import { getFriendlyMeetingStatusLabel } from "../../helpers/label_maps";

export enum MeetingStatusType {
  Analyzing = "analyzing",
  Unreviewed = "unreviewed",
  Viewed = "viewed",
  Synced = "synced",
  AutoSynced = "auto_synced",
}

export const meetingStatus = (meeting: Meeting): MeetingStatusType | null => {
  const isSynced = !!meeting.associated_crm_records.find(
    ({ last_synced_at }) => !!last_synced_at
  );

  const autoSynced = !!meeting.associated_crm_records.find(
    ({ auto_synced_at }) => !!auto_synced_at
  );

  const {
    predictions_requested_at,
    predictions_completed_at,
    reviewed_at,
    append_requested_at,
    append_completed_at,
  } = meeting;

  if (
    (predictions_requested_at && !predictions_completed_at) ||
    (append_requested_at && !append_completed_at)
  ) {
    return MeetingStatusType.Analyzing;
  }

  if (isSynced) return MeetingStatusType.Synced;
  if (autoSynced) return MeetingStatusType.AutoSynced;
  if (reviewed_at) return MeetingStatusType.Viewed;

  return MeetingStatusType.Unreviewed;
};

export function MeetingStatus({ meeting }: { meeting: Meeting }) {
  if (!meeting) return null;

  const status = meetingStatus(meeting);

  if (status === MeetingStatusType.Analyzing) {
    return (
      <Space>
        <LoadingOutlined style={{ color: blue.primary }} spin />
        <Typography.Text>Analyzing...</Typography.Text>
      </Space>
    );
  }

  if (status === MeetingStatusType.Unreviewed) {
    return (
      <Tag icon={<InfoCircleOutlined />} color="blue">
        {getFriendlyMeetingStatusLabel(status)}
      </Tag>
    );
  }

  if (status === MeetingStatusType.Viewed) {
    return (
      <Tag icon={<EyeOutlined />}>{getFriendlyMeetingStatusLabel(status)}</Tag>
    );
  }

  if (status === MeetingStatusType.Synced) {
    return (
      <Tag icon={<CheckCircleOutlined />} color="green">
        {getFriendlyMeetingStatusLabel(status)}
      </Tag>
    );
  }

  if (status === MeetingStatusType.AutoSynced) {
    return (
      <Tag icon={<SyncOutlined />} color="green">
        {getFriendlyMeetingStatusLabel(status)}
      </Tag>
    );
  }
}
