import React, { useEffect, useState } from "react";
import { Col, Divider, Form, Row, Skeleton, Typography } from "antd";
import { WorkflowStateEditorFieldName } from "../shared/WorkflowStateEditorDrawer";
import { useCRMObjectFields } from "../../../../util/data_hooks";
import { CRMProvider, WorkflowState } from "../../../../util/types";
import { CRMObjectSelectFormItem } from "./shared/CRMObjectSelectFormItem";
import { CRMSelectFormItem } from "./shared/CRMSelectFormItem";
import { CRMFieldType, WorkflowVariableName } from "../../../../util/enums";
import { CRMFieldValuesFormItem } from "./shared/CRMFieldValuesFormItem";
import { getFriendlyIntegrationLabel } from "../../../../helpers/label_maps";
import { DynamicVariableSelect } from "./shared/dynamic_variables/DynamicVariableSelect";

interface UpdateCRMRecordProps {
  workflowState: WorkflowState;
}

export function UpdateCRMRecord({ workflowState }: UpdateCRMRecordProps) {
  const form = Form.useFormInstance();

  const [selectedCRM, setSelectedCRM] = useState<CRMProvider>(null);
  const [selectedObjectType, setSelectedObjectType] = useState<string>(null);

  const { objectFields, isLoading: isCRMObjectFieldsLoading } =
    useCRMObjectFields(selectedCRM, selectedObjectType, true);

  useEffect(() => {
    form.setFieldValue(
      WorkflowStateEditorFieldName.Name,
      `Update ${getFriendlyIntegrationLabel(selectedCRM) || "CRM"} ${
        selectedObjectType || "Record"
      }`
    );
  }, [selectedCRM, selectedObjectType]);

  Form.useWatch((formValues) => {
    const crm = formValues?.variables?.crm;
    const objectType = formValues?.variables?.crm_object_type;

    if (crm) setSelectedCRM(crm);
    if (objectType) setSelectedObjectType(objectType);
  }, form);

  if (isCRMObjectFieldsLoading) return <Skeleton active />;

  return (
    <>
      <div style={{ marginBottom: 15 }}>
        <Typography.Title level={4}>
          Select CRM, Object Type, and Record Id
        </Typography.Title>

        <Typography.Text type="secondary">
          This will be used to find the correct record in the CRM to be updated.
        </Typography.Text>
      </div>

      <Row gutter={12}>
        <Col span={12}>
          <CRMSelectFormItem />
        </Col>

        <Col span={12}>
          <CRMObjectSelectFormItem crm={selectedCRM} />
        </Col>
      </Row>

      <Form.Item
        rules={[{ required: true }]}
        label="Record Id"
        name={[
          WorkflowStateEditorFieldName.Variables,
          WorkflowVariableName.CRMRecordId,
        ]}
      >
        <DynamicVariableSelect
          fieldType={CRMFieldType.Id}
          value={form.getFieldValue([
            WorkflowStateEditorFieldName.Variables,
            WorkflowVariableName.CRMRecordId,
          ])}
          onChange={(value) =>
            form.setFieldValue(
              [
                WorkflowStateEditorFieldName.Variables,
                WorkflowVariableName.CRMRecordId,
              ],
              value
            )
          }
        />
      </Form.Item>

      {form.getFieldValue([
        WorkflowStateEditorFieldName.Variables,
        WorkflowVariableName.CRMObjectType,
      ]) && (
        <>
          <Divider />

          <div style={{ marginBottom: 15 }}>
            <Typography.Title level={4}>Field Values</Typography.Title>
            <Typography.Text type="secondary">
              Which fields should be updated?
            </Typography.Text>
          </div>

          <CRMFieldValuesFormItem
            workflowState={workflowState}
            objectFields={objectFields}
          />
        </>
      )}
    </>
  );
}
