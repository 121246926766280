import React, { useState } from "react";
import { Organization, Team } from "../../../../../util/types";
import { ContextData } from "./ContextData";
import { message, Space } from "antd";
import {
  updateOrganizationContext,
  updateTeamContext,
} from "../../../../../util/api";
import { get } from "lodash";

interface Props {
  organization: Organization;
  refreshOrganization: () => void;
  team: Team;
  refreshTeam: () => void;
}

export function ContextTab({
  organization,
  team,
  refreshTeam,
  refreshOrganization,
}: Props) {
  const [organizationContextLoading, setOrganizationContextLoading] =
    useState<boolean>(false);
  const [teamContextLoading, setTeamContextLoading] = useState<boolean>(false);

  const saveOrganizationContext = async (context: string) => {
    setOrganizationContextLoading(true);
    try {
      await updateOrganizationContext(context);
      refreshOrganization();
    } catch (error) {
      handleError(error);
    }
    setOrganizationContextLoading(false);
  };

  const saveTeamContext = async (context: string) => {
    setTeamContextLoading(true);
    try {
      await updateTeamContext(team.uuid, context);
      refreshTeam();
    } catch (error) {
      handleError(error);
    }
    setTeamContextLoading(false);
  };

  const handleError = (error: any) => {
    if (get(error, "response.status", 500) < 500) {
      message.error(
        get(
          error,
          "response.data.error",
          "Oops, something is wrong on our end! Please try again later."
        )
      );
    } else {
      message.error(
        "Oops, something is wrong on our end! Please try again later."
      );
    }
  };

  return (
    <Space direction={"vertical"} style={{ display: "flex" }}>
      <ContextData
        label="Organization Context"
        hint={
          "Enter relevant context for your organization. This should include general information " +
          "about what your organization does as well as any data relevant to ALL calls across your organization. " +
          "This text will be included during insight generation to provide better context around what your " +
          "organization does, and to generate more accurate insights."
        }
        context={organization.context}
        loading={organizationContextLoading}
        onSave={(value) => saveOrganizationContext(value)}
      />
      <ContextData
        label={`${team.name} Team Context`}
        context={team.context}
        loading={teamContextLoading}
        hint={
          "Enter relevant context for your team. This should include information " +
          "about what your teams primary function is as well as what types of calls/customer interactions they perform. " +
          "This text will be included during insight generation to provide better context around what your " +
          "team does, and to generate more accurate insights."
        }
        onSave={(value) => saveTeamContext(value)}
      />
    </Space>
  );
}
