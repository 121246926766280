import { Button, Card, Modal, Space, Typography } from "antd";
import React, { useState } from "react";
import { Connection, Handle, Position } from "reactflow";
import { WorkflowState, WorkflowTransition } from "../../../util/types";
import { useWorkflow } from "../../../util/data_hooks";
import { WorkflowStateEditorDrawer } from "./shared/WorkflowStateEditorDrawer";
import { WorkflowTransitionEditorDrawer } from "./shared/WorkflowTransitionEditorDrawer";
import { gold } from "@ant-design/colors";
import { fetchUpdateWorkflowTransition } from "../../../util/api";

interface WorkflowTransitionNodeProps {
  data: {
    workflowUuid: string;
    workflowState: WorkflowState;
    workflowTransition: WorkflowTransition;
  };
}

export function WorkflowTransitionNode(props: WorkflowTransitionNodeProps) {
  const {
    data: { workflowUuid, workflowState, workflowTransition },
  } = props;

  const { mutate } = useWorkflow(workflowUuid);

  const [workflowStateEditorDrawerOpen, setWorkflowStateEditorDrawerOpen] =
    useState<boolean>(false);

  const [isCreatingFallbackWorkflowState, setIsCreatingFallbackWorkflowState] =
    useState<boolean>(false);

  const [isCreatingWorkflowTransition, setIsCreatingWorkflowTransition] =
    useState<boolean>(false);

  const [
    workflowTransitionEditorModalOpen,
    setWorkflowTransitionEditorDrawerOpen,
  ] = useState<boolean>(false);

  const onConnectConfirm = async ({
    isTarget,
    workflowStateUuid,
  }: {
    isTarget: boolean;
    workflowStateUuid: string;
  }) => {
    const body: Partial<WorkflowTransition> = isTarget
      ? { target_workflow_state_uuid: workflowStateUuid }
      : { fallback_target_workflow_state_uuid: workflowStateUuid };

    await fetchUpdateWorkflowTransition(workflowTransition.uuid, body);

    mutate();
    Modal.destroyAll();
  };

  return (
    <>
      <WorkflowStateEditorDrawer
        workflowUuid={workflowUuid}
        workflowState={workflowState}
        workflowTransition={workflowTransition}
        shouldCreateNewWorkflowState
        isFallback={isCreatingFallbackWorkflowState}
        open={workflowStateEditorDrawerOpen}
        setOpen={setWorkflowStateEditorDrawerOpen}
        afterSave={mutate}
      />

      <WorkflowTransitionEditorDrawer
        workflowUuid={workflowUuid}
        workflowStateUuid={workflowState.uuid}
        workflowTransition={workflowTransition}
        shouldCreateWorkflowTransition={isCreatingWorkflowTransition}
        open={workflowTransitionEditorModalOpen}
        setOpen={setWorkflowTransitionEditorDrawerOpen}
        afterSave={mutate}
      />

      <Handle type="target" position={Position.Top} />

      <Card
        title="Condition"
        size="small"
        styles={{
          header: { backgroundColor: gold[2] },
        }}
        style={{ width: 300, textAlign: "center" }}
        actions={[
          ...(workflowTransition.target_workflow_state_name
            ? []
            : [
                <Typography.Link
                  key="true"
                  onClick={() => {
                    setWorkflowStateEditorDrawerOpen(true);
                    setIsCreatingFallbackWorkflowState(false);
                  }}
                >
                  Add True Action
                </Typography.Link>,
              ]),

          ...(workflowTransition.fallback_target_workflow_state_uuid
            ? []
            : [
                <Typography.Link
                  disabled={
                    !!workflowTransition.fallback_target_workflow_state_uuid
                  }
                  key="false"
                  onClick={() => {
                    setWorkflowStateEditorDrawerOpen(true);
                    setIsCreatingFallbackWorkflowState(true);
                  }}
                >
                  Add False Action
                </Typography.Link>,
              ]),
        ]}
      >
        <Typography.Link
          onClick={() => {
            setWorkflowTransitionEditorDrawerOpen(true);
            setIsCreatingWorkflowTransition(false);
          }}
        >
          {workflowTransition.name}
        </Typography.Link>
      </Card>

      <Handle
        type="source"
        position={Position.Bottom}
        onConnect={(connection: Connection) =>
          Modal.info({
            title: "Run when Condition is True or False?",
            footer: (
              <Space style={{ margin: "15px 0px" }}>
                <Button
                  onClick={() =>
                    onConnectConfirm({
                      isTarget: true,
                      workflowStateUuid: connection.target,
                    })
                  }
                >
                  Run when True
                </Button>

                <Button
                  onClick={() =>
                    onConnectConfirm({
                      isTarget: false,
                      workflowStateUuid: connection.target,
                    })
                  }
                >
                  Run when False
                </Button>
              </Space>
            ),
          })
        }
      />
    </>
  );
}
