import { useContext } from "react";
import { Integration } from "../../util/enums";
import { AppContext } from "../App";

export function useIntegrationWindow(): [
  (integration: Integration) => Promise<void>
] {
  const context = useContext(AppContext);

  const getIntegrationAuthorizationEndpoint = (integration: Integration) => {
    switch (integration) {
      case Integration.Zoom:
        return context.zoom_authorization_endpoint;
      case Integration.ZoomAdmin:
        return context.zoom_admin_authorization_endpoint;
      case Integration.Gong:
        return context.gong_authorization_endpoint;
      case Integration.Microsoft:
        return context.microsoft_authorization_endpoint;
      case Integration.Slack:
        return context.slack_authorization_endpoint;
      case Integration.Salesloft:
        return context.salesloft_authorization_endpoint;
    }
  };

  const openWindow = async (integration: Integration) => {
    await new Promise((resolve) => {
      const width = 1000;
      const height = 800;

      const y = window.top.outerHeight / 2 + window.top.screenY - height / 2;
      const x = window.top.outerWidth / 2 + window.top.screenX - width / 2;

      const popup = window.open(
        getIntegrationAuthorizationEndpoint(integration),
        "window",
        `toolbar=no, menubar=no, resizable=yes, height=${height}, width=${width}, top=${y}, left=${x}`
      );

      const interval = setInterval(() => {
        const pathName = popup.location.pathname;

        if (!pathName) return;

        if (pathName.includes("/success")) {
          clearInterval(interval);
          popup.close();
          resolve(true);
        }
      }, 500);
    });
  };

  return [openWindow];
}
