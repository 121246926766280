import React, { useEffect, useState } from "react";
import { Col, Divider, Form, Row, Skeleton, Typography } from "antd";
import { WorkflowStateEditorFieldName } from "../shared/WorkflowStateEditorDrawer";
import { useCRMObjectFields } from "../../../../util/data_hooks";
import { CRMProvider } from "../../../../util/types";
import { WorkflowQueryEditor } from "./shared/WorkflowQueryEditor";
import { CRMObjectSelectFormItem } from "./shared/CRMObjectSelectFormItem";
import { CRMSelectFormItem } from "./shared/CRMSelectFormItem";
import { WorkflowVariableName } from "../../../../util/enums";
import { getFriendlyIntegrationLabel } from "../../../../helpers/label_maps";

export function FindCRMRecord() {
  const form = Form.useFormInstance();

  const [selectedCRM, setSelectedCRM] = useState<CRMProvider>(null);
  const [selectedObjectType, setSelectedObjectType] = useState<string>(null);

  const { objectFields, isLoading: isCRMObjectFieldsLoading } =
    useCRMObjectFields(selectedCRM, selectedObjectType, true);

  useEffect(() => {
    form.setFieldValue(
      WorkflowStateEditorFieldName.Name,
      `Find ${getFriendlyIntegrationLabel(selectedCRM) || "CRM"} ${
        selectedObjectType || "Record"
      }`
    );
  }, [selectedCRM, selectedObjectType]);

  Form.useWatch((formValues) => {
    const crm = formValues?.variables?.crm;
    const objectType = formValues?.variables?.crm_object_type;

    if (crm) setSelectedCRM(crm);
    if (objectType) setSelectedObjectType(objectType);
  }, form);

  if (isCRMObjectFieldsLoading) return <Skeleton active />;

  return (
    <>
      <div style={{ marginBottom: 15 }}>
        <Typography.Title level={4}>
          Select CRM and Object Type
        </Typography.Title>

        <Typography.Text type="secondary">
          What type of record should be found?
        </Typography.Text>
      </div>

      <Row gutter={12}>
        <Col span={12}>
          <CRMSelectFormItem />
        </Col>

        <Col span={12}>
          <CRMObjectSelectFormItem crm={selectedCRM} />
        </Col>
      </Row>

      <Divider />

      <div style={{ marginBottom: 15 }}>
        <Typography.Title level={4}>Build Query Attributes</Typography.Title>

        <Typography.Text type="secondary">
          Add attributes and values that can be used to find the record.
        </Typography.Text>
      </div>

      <Form.Item
        label="Query"
        name={[
          WorkflowStateEditorFieldName.Variables,
          WorkflowVariableName.Query,
        ]}
      >
        <WorkflowQueryEditor
          defaultQuery={form.getFieldValue([
            WorkflowStateEditorFieldName.Variables,
            WorkflowVariableName.Query,
          ])}
          onChange={(query) =>
            form.setFieldValue(
              [
                WorkflowStateEditorFieldName.Variables,
                WorkflowVariableName.Query,
              ],
              query
            )
          }
          fields={objectFields.map((objectField) => {
            return {
              name: objectField.crm_field_name,
              label: objectField.crm_field_label,
            };
          })}
        />
      </Form.Item>
    </>
  );
}
