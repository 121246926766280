import React, { useEffect, useState } from "react";
import {
  Alert,
  AutoComplete,
  Button,
  message,
  Modal,
  Spin,
  Typography,
} from "antd";
import { useDebounce } from "@uidotdev/usehooks";
import {
  AssociatedCRMRecord,
  AutoGeneratedField,
  CRMRecord,
  Meeting,
  SyncedCRMObject,
} from "../../util/types";
import {
  createAssociatedCRMRecord,
  getCRMRecordsByName,
  getCRMRecordById,
} from "../../util/api";
import { getFriendlyIntegrationLabel } from "../../helpers/label_maps";
import { get } from "lodash";

interface OpportunitySearchAutocomplete {
  open: boolean;
  meeting: Meeting;
  autoGeneratedFields: AutoGeneratedField[];
  syncedCRMObjects: SyncedCRMObject[];
  associatedCRMRecords: AssociatedCRMRecord[];
  predictedAssociatedCRMRecords: AssociatedCRMRecord[];
  onSave: () => void;
  onClose: () => void;
}

export function AssociatedCRMRecordModal({
  open,
  meeting,
  associatedCRMRecords,
  syncedCRMObjects,
  predictedAssociatedCRMRecords = [],
  onSave,
  onClose,
}: OpportunitySearchAutocomplete) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [options, setOptions] = useState<
    {
      label: string;
      options: { label: string; value: string }[];
    }[]
  >([]);
  const [query, setQuery] = useState<string | null>(null);

  const [syncedCRMObject, setSyncedCRMObject] =
    useState<SyncedCRMObject | null>(null);
  const [crmRecord, setCRMRecord] = useState<Omit<
    CRMRecord,
    "crm_record_fields"
  > | null>(null);

  const [
    approvedPredictedAssociatedCRMRecord,
    setApprovedPredictedAssociatedCRMRecord,
  ] = useState<AssociatedCRMRecord | null>(null);

  const debouncedQuery = useDebounce(query, 750);

  const setCRMRecordSearchResultOptions = async () => {
    const results: {
      label: string;
      options: { label: string; value: string }[];
    }[] = [];

    for (const syncedCRMObject of syncedCRMObjects) {
      const { crm_object_type, crm_object_label } = syncedCRMObject;

      try {
        const crmRecords = await getCRMRecordsByName(
          crm_object_type,
          syncedCRMObject.searchable_crm_field_name,
          query
        );

        const options = crmRecords.map((crmRecord) => {
          return {
            label: crmRecord.crm_record_name,
            value: JSON.stringify({ syncedCRMObject, crmRecord }),
          };
        });

        if (options.length) results.push({ label: crm_object_label, options });
      } catch (error) {
        if (get(error, "response.status") < 500) {
          message.error(
            get(
              error,
              "response.data.message",
              "Oops! Something went wrong. Please try again."
            )
          );
        } else {
          message.error("Oops! Something went wrong. Please try again.");
        }
      }
    }

    setOptions(results);
    setIsLoading(false);
  };

  const handleSave = async (
    syncedCRMObject: SyncedCRMObject,
    partialCrmRecord: Omit<CRMRecord, "crm_record_fields">
  ) => {
    setIsSaving(true);

    try {
      await createAssociatedCRMRecord({
        meetingUuid: meeting.uuid,
        syncedCrmObjectUuid: syncedCRMObject.uuid,
        crmRecord: partialCrmRecord,
      });

      onSave();
      setQuery(null);
      setIsSaving(false);
    } catch (error) {
      if (get(error, "response.status") < 500) {
        message.error(
          get(
            error,
            "response.data.message",
            "Oops! Something went wrong. Please try again."
          )
        );
      } else {
        message.error("Oops! Something went wrong. Please try again.");
      }
    }
  };

  useEffect(() => {
    setOptions([]);
    setIsLoading(!!query);
  }, [query]);

  useEffect(() => {
    if (!debouncedQuery) {
      setOptions([]);
      setIsLoading(false);
      return;
    }

    setCRMRecordSearchResultOptions();
  }, [debouncedQuery]);

  useEffect(() => {
    if (!approvedPredictedAssociatedCRMRecord) return;

    const unassignedSyncedCRMObjectUuids = syncedCRMObjects
      .filter(
        (syncedCRMObject) =>
          !associatedCRMRecords
            .map((r) => r.synced_crm_object.uuid)
            .includes(syncedCRMObject.uuid)
      )
      .map((o) => o.uuid);

    const pendingPredictedAssociatedCRMRecords =
      predictedAssociatedCRMRecords.filter((r) =>
        unassignedSyncedCRMObjectUuids.includes(r.synced_crm_object.uuid)
      );

    if (pendingPredictedAssociatedCRMRecords.length === 0) {
      onClose();
      setApprovedPredictedAssociatedCRMRecord(null);
    }
  }, [
    approvedPredictedAssociatedCRMRecord,
    predictedAssociatedCRMRecords,
    associatedCRMRecords,
    syncedCRMObjects,
  ]);

  return (
    <Modal
      title={`Associate a ${getFriendlyIntegrationLabel(
        syncedCRMObjects?.[0]?.crm_provider_name
      )} Record with this Meeting`}
      open={open}
      onCancel={onClose}
      okButtonProps={{
        disabled: !syncedCRMObject || !crmRecord || !query || isSaving,
      }}
      onOk={async () => {
        await handleSave(syncedCRMObject, crmRecord);
        onClose();
      }}
      okText="Save"
      cancelText="Close"
    >
      <Spin spinning={isSaving}>
        {predictedAssociatedCRMRecords.map((predictedAssociatedCRMRecord) => {
          const { uuid, crm_record_id, crm_record_name, synced_crm_object } =
            predictedAssociatedCRMRecord;

          const doesAssociatedCRMRecordExist = associatedCRMRecords.find(
            (associatedCRMRecord) =>
              associatedCRMRecord.synced_crm_object.crm_object_type ===
              synced_crm_object.crm_object_type
          );

          if (doesAssociatedCRMRecordExist) return null;

          return (
            <Alert
              key={uuid}
              style={{ margin: "8px 0px" }}
              message={
                <>
                  <b>{crm_record_name}</b> (Suggested{" "}
                  {synced_crm_object.crm_object_label})
                </>
              }
              type="info"
              action={
                <Button
                  size="small"
                  type="primary"
                  onClick={async () => {
                    setIsSaving(true);
                    setApprovedPredictedAssociatedCRMRecord(
                      predictedAssociatedCRMRecord
                    );

                    const crmRecord = await getCRMRecordById(
                      synced_crm_object.crm_object_type,
                      crm_record_id
                    );

                    await handleSave(synced_crm_object, crmRecord);
                  }}
                >
                  Accept
                </Button>
              }
            />
          );
        })}

        <AutoComplete
          id="find-crm-record-autocomplete"
          allowClear
          value={query}
          style={{ width: "100%", margin: "10px 0px" }}
          notFoundContent={
            isLoading ? (
              <Spin size="small" style={{ padding: 8, width: "100%" }} />
            ) : query ? (
              <Typography.Text>No results found.</Typography.Text>
            ) : null
          }
          options={options}
          onSelect={async (value, { label }) => {
            const { syncedCRMObject, crmRecord } = JSON.parse(value) as {
              syncedCRMObject: SyncedCRMObject;
              crmRecord: CRMRecord;
            };

            setQuery(label);
            setSyncedCRMObject(syncedCRMObject);
            setCRMRecord(crmRecord);
          }}
          onSearch={setQuery}
          placeholder="Start typing to search for a record..."
        />
      </Spin>
    </Modal>
  );
}
