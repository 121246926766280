import React, { useEffect, useState } from "react";
import { Space, Table, Typography, Button, Row } from "antd";
import type { ColumnsType } from "antd/es/table";
import { Organization } from "../../../util/types";
import { useOrganizations } from "../../../util/data_hooks";
import { OrganizationEditorModal } from "../shared/OrganizationEditorModal";

export function Organizations() {
  const [selectedOrganization, setSelectedOrganization] =
    useState<Organization>(null);

  const [isOrganizationEditorModalOpen, setOrganizationEditorModalOpen] =
    useState<boolean>(false);

  const {
    organizations,
    isLoading,
    mutate: mutateOrganizations,
  } = useOrganizations();

  useEffect(() => {
    if (!selectedOrganization) return;

    setSelectedOrganization(
      organizations.find(({ uuid }) => uuid === selectedOrganization.uuid)
    );
  }, [organizations]);

  const columns: ColumnsType<Organization> = [
    {
      width: "25%",
      title: "Name",
      dataIndex: "name",
    },
    {
      width: "25%",
      title: "Domains",
      dataIndex: "domains",
      render: (domains) => domains.join(", "),
    },
    {
      width: "50%",
      title: "Seats",
      dataIndex: "seats",
    },
    {
      dataIndex: "actions",
      title: "Actions",
      align: "center",
      render: (_, organization) => {
        const width = 150;

        return (
          <Space>
            <Button
              target="_blank"
              href={`/api/analytics?organization_uuid=${organization.uuid}`}
              style={{ width }}
            >
              View Analytics
            </Button>

            <Button
              style={{ width }}
              type="primary"
              onClick={() => {
                setSelectedOrganization(organization);
                setOrganizationEditorModalOpen(true);
              }}
            >
              Edit {organization.name}
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      {isOrganizationEditorModalOpen && (
        <OrganizationEditorModal
          organization={selectedOrganization}
          open={isOrganizationEditorModalOpen}
          setOpen={setOrganizationEditorModalOpen}
          afterSave={mutateOrganizations}
        />
      )}

      <Space direction="vertical" style={{ display: "flex" }} size="large">
        <Row justify="space-between">
          <Typography.Title level={3} style={{ margin: 0 }}>
            All Organizations
          </Typography.Title>

          <Button
            type="primary"
            style={{ marginLeft: "auto" }}
            onClick={() => {
              setSelectedOrganization(null);
              setOrganizationEditorModalOpen(true);
            }}
          >
            Create Organization
          </Button>
        </Row>

        <Table<Organization>
          rowKey={({ uuid }) => uuid}
          loading={isLoading}
          dataSource={organizations.sort((a, b) => b.seats - a.seats)}
          columns={columns}
          onRow={({ seats }) => {
            if (seats > 0) return;

            return {
              style: {
                background: "#f0f0f0",
                "&:hover": {
                  background: "#f0f0f0",
                },
              },
            };
          }}
        />
      </Space>
    </>
  );
}
