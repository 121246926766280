import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Team } from "../../../../util/types";
import _ from "lodash";

export interface TeamSlice {
  selectedTeam: Team;
  teamOptions: Team[];
  isLoading: boolean;
}

const initialState: TeamSlice = {
  selectedTeam: null,
  teamOptions: [],
  isLoading: false,
};

export const teamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {
    setSelectedTeam: (state, action: PayloadAction<Team>) => {
      state.selectedTeam = action.payload;
    },
    setTeamOptions: (state, action: PayloadAction<Team[]>) => {
      state.teamOptions = action.payload;
      if (!_.find(action.payload, (team: Team) => state.selectedTeam?.uuid === team.uuid)) {
        // Only set selected team to default if a new organization is selected
        state.selectedTeam = defaultTeam(state);
      }
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSelectedTeam, setTeamOptions, setIsLoading } =
  teamSlice.actions;

export const defaultTeam = (state) => {
  const defaultTeam = state.teamOptions.find((team: Team) => team.is_default);
  return defaultTeam ? defaultTeam : _.head(state.teamOptions);
};

export default teamSlice.reducer;
