import { Card, Typography, message } from "antd";
import React, { useState } from "react";
import { Connection, Handle, Position } from "reactflow";
import { WorkflowState } from "../../../util/types";
import { useWorkflow } from "../../../util/data_hooks";
import { WorkflowStateEditorDrawer } from "./shared/WorkflowStateEditorDrawer";
import { WorkflowTransitionEditorDrawer } from "./shared/WorkflowTransitionEditorDrawer";
import { WorkflowActionType } from "../../../util/enums";
import { fetchCreateWorkflowTransition } from "../../../util/api";

interface WorkflowStateNodeProps {
  data: {
    workflowUuid: string;
    workflowState: WorkflowState;
  };
}

export function WorkflowStateNode(props: WorkflowStateNodeProps) {
  const {
    data: { workflowUuid, workflowState },
  } = props;

  const [workflowStateEditorDrawerOpen, setWorkflowStateEditorDrawerOpen] =
    useState<boolean>(false);

  const [isCreatingWorkflowState, setIsCreatingWorkflowState] =
    useState<boolean>(false);

  const [
    workflowTransitionEditorModalOpen,
    setWorkflowTransitionEditorDrawerOpen,
  ] = useState<boolean>(false);

  const { mutate } = useWorkflow(workflowUuid);

  const handleConnect = async (connection: Connection) => {
    try {
      await fetchCreateWorkflowTransition({
        workflow_state_uuid: connection.source,
        target_workflow_state_uuid: connection.target,
      });
    } catch (e) {
      message.error("Error connecting actions. Please try again");
    }
  };

  const isTrigger = workflowState.action_type === WorkflowActionType.Trigger;

  return (
    <>
      <WorkflowStateEditorDrawer
        workflowUuid={workflowUuid}
        workflowState={workflowState}
        shouldCreateNewWorkflowState={isCreatingWorkflowState}
        open={workflowStateEditorDrawerOpen}
        setOpen={setWorkflowStateEditorDrawerOpen}
        afterSave={mutate}
      />

      <WorkflowTransitionEditorDrawer
        workflowUuid={workflowUuid}
        workflowStateUuid={workflowState.uuid}
        workflowTransition={null}
        shouldCreateWorkflowTransition
        open={workflowTransitionEditorModalOpen}
        setOpen={setWorkflowTransitionEditorDrawerOpen}
        afterSave={mutate}
      />

      {!isTrigger && (
        <Handle
          type="target"
          position={Position.Top}
          onConnect={handleConnect}
          isConnectable={!workflowState.source_workflow_transition}
        />
      )}

      <Card
        title={isTrigger ? "Trigger" : "Action"}
        size="small"
        style={{ width: 300, textAlign: "center" }}
        actions={[
          <Typography.Link
            key="new-action"
            onClick={() => {
              setWorkflowStateEditorDrawerOpen(true);
              setIsCreatingWorkflowState(true);
            }}
          >
            New Action
          </Typography.Link>,
          <Typography.Link
            key="new-condition"
            onClick={() => {
              setWorkflowTransitionEditorDrawerOpen(true);
              setIsCreatingWorkflowState(true);
            }}
          >
            New Condition
          </Typography.Link>,
        ]}
      >
        {isTrigger ? (
          <Typography.Text strong>{workflowState.name}</Typography.Text>
        ) : (
          <Typography.Link
            onClick={() => {
              setWorkflowStateEditorDrawerOpen(true);
              setIsCreatingWorkflowState(false);
            }}
          >
            {workflowState.name}
          </Typography.Link>
        )}
      </Card>

      <Handle
        type="source"
        position={Position.Bottom}
        onConnect={handleConnect}
      />
    </>
  );
}
