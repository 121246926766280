import React from "react";
import { Typography } from "antd";
import { DiffInformation } from "../../../util/auto_generated_field_helpers";
import { CRMFieldType } from "../../../util/enums";

export function DiffText({
  diff,
}: {
  diff: DiffInformation[];
  crmFieldType: CRMFieldType;
}) {
  return diff.map(({ value, type }, index) => {
    let bg = null;

    switch (type) {
      case "added":
        bg = "#E6FFEC";
        break;
      case "removed":
        bg = "#FFE9E9";
        break;
    }

    return (
      <Typography.Text
        key={index + value}
        style={{ backgroundColor: bg, whiteSpace: "pre-wrap" }}
      >
        {value}
      </Typography.Text>
    );
  });
}
