import React, { useState } from "react";
import { get } from "lodash";
import {
  Meeting,
  FieldConfigurationPlaygroundResponse,
} from "../../../../../util/types";
import { Card, Steps, notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { MeetingSelect } from "./MeetingSelect";
import { createFieldConfigurationPlaygroundTest } from "../../../../../util/api";
import { ReviewResult } from "./ReviewResult";
import { useAppSelector } from "../../../../redux/hooks";

enum TestingSteps {
  MEETING_SELECT = 0,
  ANALYZING = 1,
  REVIEW = 2,
}

export function TestingCard() {
  const fieldToTest = useAppSelector(
    (state) => state.opportunityFieldTest.opportunityFieldToTest
  );
  const [notificationApi, contextHolder] = notification.useNotification();
  const [currentStep, setCurrentStep] = useState<TestingSteps>(
    TestingSteps.MEETING_SELECT
  );
  const [testResult, setTestResult] =
    useState<FieldConfigurationPlaygroundResponse>();
  const [selectedMeeting, setSelectedMeeting] = useState<Meeting>();
  const [selectedMeetingSummary, setSelectedMeetingSummary] =
    useState<string>("");

  const testMeeting = async (
    meeting: Meeting,
    meetingSummary: string
  ): Promise<void> => {
    setCurrentStep(TestingSteps.ANALYZING);

    try {
      setSelectedMeeting(meeting);
      setSelectedMeetingSummary(meetingSummary);

      const testResults = await createFieldConfigurationPlaygroundTest(
        meeting.uuid,
        fieldToTest
      );

      setTestResult(testResults);

      setCurrentStep(TestingSteps.REVIEW);
    } catch (err) {
      if (get(err, "response.status", 500) < 500) {
        showError(
          get(
            err,
            "response.data.error",
            "Ooops, something is wrong on our end! Please try again later."
          )
        );
      } else {
        showError(
          "Ooops, something is wrong on our end! Please try again later."
        );
      }
      setCurrentStep(TestingSteps.MEETING_SELECT);
    }
  };

  const showError = (message: string) => {
    notificationApi["error"]({
      message: "Could not complete test",
      description: message,
      duration: 0,
    });
  };

  return (
    <>
      {contextHolder}
      <Card title="Test">
        <Steps
          size="small"
          labelPlacement="vertical"
          current={currentStep}
          onChange={setCurrentStep}
          items={[
            { title: "Select Meeting" },
            {
              title: "Analyzing",
              icon: currentStep === 1 ? <LoadingOutlined /> : false,
              disabled: true,
            },
            {
              title: "Review Results",
              disabled: true,
            },
          ]}
        />

        {currentStep === 0 && (
          <div style={{ marginTop: "1rem" }}>
            <MeetingSelect testMeeting={testMeeting} />
          </div>
        )}

        {currentStep === 2 && (
          <div style={{ marginTop: "1rem" }}>
            <ReviewResult
              testResult={testResult}
              meetingSummary={selectedMeetingSummary}
              retest={() =>
                testMeeting(selectedMeeting, selectedMeetingSummary)
              }
              pickDifferentMeeting={() =>
                setCurrentStep(TestingSteps.MEETING_SELECT)
              }
            />
          </div>
        )}
      </Card>
    </>
  );
}
