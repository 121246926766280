import React, { useEffect, useState } from "react";
import {
  DEFAULT_FILTERS,
  MeetingTableFilters,
  MeetingsTable,
  getFilteredMeetings,
  getFiltersCount,
} from "../shared/MeetingsTable";
import {
  useOrganizationMeetings,
  useTeams,
  useUser,
} from "../../util/data_hooks";
import {
  Button,
  Card,
  Col,
  Empty,
  Row,
  Select,
  Space,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { Outlet, useNavigate } from "react-router-dom";
import { useLocalStorage } from "@uidotdev/usehooks";
import {
  UserOutlined,
  FilterTwoTone,
  CalendarOutlined,
  UnorderedListOutlined,
  TeamOutlined,
  FileTextOutlined,
  InfoCircleOutlined,
  CloseCircleFilled,
} from "@ant-design/icons";
import moment from "moment";
import DatePicker from "../shared/DatePicker";
import {
  getFriendlyMeetingStatusLabel,
  getFriendlyMeetingTypeLabel,
} from "../../helpers/label_maps";
import { fetchGetOrganizationMeetingAnalytics } from "../../util/api";
import { OrganizationAnalytics } from "../../util/types";
import { usePageTitle } from "../hooks/usePageTitle";

const TEAM_MEETINGS_TABLE_FILTERS = "TEAM_MEETINGS_TABLE_FILTERS";

function StatCard({
  stat,
  title,
  tooltip,
}: {
  stat: number;
  title: string;
  tooltip: string;
}) {
  return (
    <Card
      size="small"
      title={
        <Space size="small">
          <Typography.Text>{title}</Typography.Text>

          <Tooltip title={tooltip}>
            <InfoCircleOutlined />
          </Tooltip>
        </Space>
      }
    >
      <Typography.Title style={{ marginTop: 0, marginBottom: 10 }}>
        {stat?.toLocaleString()}
      </Typography.Title>
    </Card>
  );
}

export function TeamMeetings() {
  usePageTitle("Team Meetings");

  const navigate = useNavigate();

  const [filteredMeetingUuids, setFilteredMeetingUuids] = useState<string[]>(
    []
  );
  const [analytics, setAnalytics] = useState<OrganizationAnalytics>();

  const [savedFilters, setSavedFilters] = useLocalStorage<MeetingTableFilters>(
    TEAM_MEETINGS_TABLE_FILTERS
  );

  const filtersCount = getFiltersCount(savedFilters);

  const { organizationMeetings, isLoading: isMeetingsLoading } =
    useOrganizationMeetings({
      shouldPaginate: false, // Disable pagination for now until we introduce server-side filtering
    });

  const { user, isLoading: isUserLoading } = useUser();
  const { teams, isLoading: isTeamsLoading } = useTeams();

  useEffect(() => {
    if (isMeetingsLoading) return;

    const meetingUuids = getFilteredMeetings(
      organizationMeetings,
      savedFilters
    ).map((meeting) => meeting.uuid);

    if (meetingUuids.join() === filteredMeetingUuids.join()) return;

    (async () => {
      const result = await fetchGetOrganizationMeetingAnalytics(meetingUuids);

      setFilteredMeetingUuids(meetingUuids);
      setAnalytics(result);
    })();
  }, [isMeetingsLoading, savedFilters]);

  const isLoading = isUserLoading || isMeetingsLoading || isTeamsLoading;

  return (
    <>
      <Outlet />

      <Space direction="vertical" style={{ display: "flex" }} size="large">
        <Row justify="space-between" align="middle" gutter={[12, 12]}>
          <Col>
            <Typography.Title level={3}>
              Team Meetings for {user?.organization?.name}
            </Typography.Title>

            <Typography.Text>
              View meeting activity and details across your entire revenue team
            </Typography.Text>
          </Col>

          <Col>
            <Space>
              <Select
                style={{ minWidth: 250 }}
                mode="multiple"
                size="large"
                placeholder="All Teams"
                allowClear
                options={[
                  ...teams.map((team) => {
                    return {
                      label: team.name,
                      value: team.name,
                    };
                  }),
                ]}
                onChange={(team_names) => {
                  setSavedFilters({
                    ...savedFilters,
                    user_team: team_names.length ? team_names : null,
                  });
                }}
                value={savedFilters?.user_team}
              />

              <DatePicker.RangePicker
                onChange={(dates) =>
                  setSavedFilters({ ...savedFilters, date_range: dates })
                }
                size="large"
                presets={[
                  {
                    label: "This month",
                    value: () => [
                      moment().startOf("month"),
                      moment().endOf("month"),
                    ],
                  },
                  {
                    label: "Last month",
                    value: () => [
                      moment().subtract(1, "month").startOf("month"),
                      moment().subtract(1, "month").endOf("month"),
                    ],
                  },
                  {
                    label: "This week",
                    value: () => [
                      moment().startOf("week"),
                      moment().endOf("week"),
                    ],
                  },
                  {
                    label: "Last week",
                    value: () => [
                      moment().subtract(1, "week").startOf("week"),
                      moment().subtract(1, "week").endOf("week"),
                    ],
                  },
                ]}
                value={
                  savedFilters?.date_range
                    ? [
                        moment(savedFilters.date_range[0]),
                        moment(savedFilters.date_range[1]),
                      ]
                    : [null, null]
                }
              />
            </Space>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          {filtersCount > 0 && (
            <Col span={24}>
              <Card
                title={
                  <Space>
                    <FilterTwoTone />

                    <Typography.Text>
                      {filtersCount} {filtersCount === 1 ? "filter" : "filters"}{" "}
                      applied
                    </Typography.Text>
                  </Space>
                }
                size="small"
              >
                <Row align="middle" justify="space-between" gutter={[12, 12]}>
                  <Col>
                    <Row gutter={[12, 12]}>
                      {savedFilters.date_range && (
                        <Col>
                          <Tag icon={<CalendarOutlined />}>
                            Date range:{" "}
                            <b>
                              {moment(savedFilters.date_range[0]).format(
                                "MMMM Do YYYY"
                              )}
                            </b>{" "}
                            to{" "}
                            <b>
                              {moment(savedFilters.date_range[1]).format(
                                "MMMM Do YYYY"
                              )}
                            </b>
                          </Tag>
                        </Col>
                      )}

                      {savedFilters.topic && (
                        <Col>
                          <Tag icon={<UnorderedListOutlined />}>
                            Meeting Type:{" "}
                            <b>
                              {savedFilters.topic
                                .map((type) =>
                                  getFriendlyMeetingTypeLabel(type)
                                )
                                .join(", ")}
                            </b>
                          </Tag>
                        </Col>
                      )}

                      {savedFilters.user_email && (
                        <Col>
                          <Tag icon={<UserOutlined />}>
                            Users: <b>{savedFilters.user_email.join(", ")}</b>
                          </Tag>
                        </Col>
                      )}

                      {savedFilters.user_team && (
                        <Col>
                          <Tag icon={<TeamOutlined />}>
                            Team: <b>{savedFilters.user_team.join(", ")}</b>
                          </Tag>
                        </Col>
                      )}

                      {savedFilters.crm_record_ids && (
                        <Col>
                          <Tag icon={<FileTextOutlined />}>
                            CRM Records:{" "}
                            <b>{savedFilters.crm_record_ids.length}</b>
                          </Tag>
                        </Col>
                      )}

                      {savedFilters.meeting_status && (
                        <Col>
                          <Tag icon={<InfoCircleOutlined />}>
                            Status:{" "}
                            <b>
                              {savedFilters.meeting_status
                                .map((status) =>
                                  getFriendlyMeetingStatusLabel(status)
                                )
                                .join(", ")}
                            </b>
                          </Tag>
                        </Col>
                      )}
                    </Row>
                  </Col>

                  <Col>
                    <Button
                      type="primary"
                      icon={<CloseCircleFilled />}
                      onClick={() => setSavedFilters(DEFAULT_FILTERS)}
                    >
                      Clear Filters
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Col>
          )}

          <Col span={8}>
            <StatCard
              title="Meetings Analyzed"
              tooltip="Number of meetings analyzed by Swyft"
              stat={analytics?.meetings_analyzed || 0}
            />
          </Col>

          <Col span={8}>
            <StatCard
              title="Field Data Generated"
              tooltip="Number of fields where Swyft was able to generate data for analyzed meetings"
              stat={analytics?.fields_generated || 0}
            />
          </Col>

          <Col span={8}>
            <StatCard
              title="Synced CRM Records"
              tooltip="Number of synced CRM records for analyzed meetings"
              stat={analytics?.synced_crm_records || 0}
            />
          </Col>
        </Row>

        <MeetingsTable
          includeUserColumn
          meetings={organizationMeetings}
          user={user}
          savedFiltersKey={TEAM_MEETINGS_TABLE_FILTERS}
          isLoading={isLoading}
          onRowClick={(meetingUuid) => navigate(meetingUuid)}
          empty={
            <div style={{ margin: "100px 0px" }}>
              {isLoading ? (
                <Typography.Text>Loading...</Typography.Text>
              ) : (
                <Empty
                  description={
                    <Typography.Title level={5}>
                      No meetings or calls found
                    </Typography.Title>
                  }
                />
              )}
            </div>
          }
        />
      </Space>
    </>
  );
}
