import React, { ReactElement } from "react";
import { Space, Table, Typography } from "antd";
import { AssociatedCRMRecord, Meeting, User } from "../../util/types";
import { getFormattedDate } from "../../helpers/date_helpers";
import { useLocalStorage } from "@uidotdev/usehooks";
import {
  MeetingStatus,
  MeetingStatusType,
  meetingStatus,
} from "./MeetingStatus";
import { EmailAvatarLabel } from "./EmailAvatarLabel";
import { CRMRecordTag } from "./CRMRecordTag";
import { Moment } from "moment";
import { NoUndefinedRangeValueType } from "rc-picker/lib/PickerInput/RangePicker";
import moment from "moment";
import {
  getFriendlyMeetingStatusLabel,
  getFriendlyMeetingTypeLabel,
} from "../../helpers/label_maps";
import { ColumnsType } from "antd/es/table";
import { MeetingType } from "../../util/enums";
import { MeetingTypeIcon } from "./MeetingTypeIcon";

enum MeetingTableColumn {
  UserEmail = "user_email",
  Team = "user_team",
  Topic = "topic",
  StartTime = "start_time",
  CRMRecordIds = "crm_record_ids",
  MeetingStatus = "meeting_status",
}

export type MeetingTableFilters = {
  [MeetingTableColumn.UserEmail]: string[];
  [MeetingTableColumn.Team]: string[];
  [MeetingTableColumn.CRMRecordIds]: string[];
  [MeetingTableColumn.MeetingStatus]: MeetingStatusType[];
  [MeetingTableColumn.Topic]: MeetingType[];
  date_range: NoUndefinedRangeValueType<Moment>;
};

export const DEFAULT_FILTERS: MeetingTableFilters = {
  user_email: null,
  user_team: null,
  crm_record_ids: null,
  meeting_status: null,
  date_range: null,
  topic: null,
};

export const getFiltersCount = (filters: MeetingTableFilters): number =>
  filters && Object.values(filters).filter(Boolean).length;

export const getFilteredMeetings = (
  meetings: Meeting[],
  filters: MeetingTableFilters
): Meeting[] => {
  if (!filters) return meetings;

  return (
    meetings
      // Filter by date range
      .filter((meeting) => {
        return filters.date_range
          ? moment(meeting.start_time).isBetween(
              filters.date_range[0],
              filters.date_range[1]
            )
          : true;
      })
      // Filter by email
      .filter((meeting) => {
        return filters[MeetingTableColumn.UserEmail]
          ? filters[MeetingTableColumn.UserEmail].includes(meeting.user_email)
          : true;
      })
      // Filter by team
      .filter((meeting) => {
        return filters[MeetingTableColumn.Team]
          ? filters[MeetingTableColumn.Team].includes(meeting.user_team_name)
          : true;
      })
      // Filter by associated crm records
      .filter((meeting) => {
        return filters[MeetingTableColumn.CRMRecordIds]
          ? meeting.associated_crm_records
              .map(({ crm_record_id }) => crm_record_id)
              .filter((value) =>
                filters[MeetingTableColumn.CRMRecordIds].includes(value)
              ).length > 0
          : true;
      })
      // Filter by status
      .filter((meeting) => {
        return filters[MeetingTableColumn.MeetingStatus]
          ? filters[MeetingTableColumn.MeetingStatus].includes(
              meetingStatus(meeting)
            )
          : true;
      })
      // Filter by meeting type
      .filter((meeting) => {
        return filters[MeetingTableColumn.Topic]
          ? filters[MeetingTableColumn.Topic].includes(meeting.meeting_type)
          : true;
      })
  );
};

interface MeetingsTableProps {
  isLoading: boolean;
  title?: ReactElement;
  meetings: Meeting[];
  user: User;
  includeUserColumn?: boolean;
  savedFiltersKey: string;
  empty: ReactElement;
  onRowClick: (meetingUuid: string) => void;
}

export function MeetingsTable({
  isLoading,
  title = null,
  meetings,
  user,
  includeUserColumn = false,
  savedFiltersKey,
  empty,
  onRowClick,
}: MeetingsTableProps) {
  const [savedFilters, setSavedFilters] = useLocalStorage<MeetingTableFilters>(
    savedFiltersKey,
    DEFAULT_FILTERS
  );

  const columns: ColumnsType<Meeting> = [
    ...(includeUserColumn
      ? [
          {
            key: MeetingTableColumn.UserEmail,
            title: "User",
            dataIndex: "user_email",
            render: (user_email: string) => (
              <EmailAvatarLabel email={user_email} />
            ),
            filteredValue: savedFilters[MeetingTableColumn.UserEmail],
            filterSearch: true,
            filters: meetings
              .filter((value, index, self) => {
                return (
                  self.findIndex((v) => v.user_email === value.user_email) ===
                  index
                );
              })
              .map(({ user_email }) => {
                return {
                  text: user_email,
                  value: user_email,
                };
              }),
          },
        ]
      : []),
    {
      key: MeetingTableColumn.Topic,
      title: "Name",
      dataIndex: "topic",
      filteredValue: savedFilters[MeetingTableColumn.Topic],
      filterSearch: true,
      filters: Object.values(MeetingType).map((value) => {
        return {
          text: getFriendlyMeetingTypeLabel(value),
          value,
        };
      }),
      render: (topic, { meeting_type }) => (
        <Space style={{ alignItems: "start" }}>
          <MeetingTypeIcon meetingType={meeting_type} />
          <Typography.Text>{topic}</Typography.Text>
        </Space>
      ),
    },
    {
      key: MeetingTableColumn.StartTime,
      title: "Date & Time",
      dataIndex: "start_time",
      render: (start_time) => getFormattedDate(start_time),
    },
    {
      key: MeetingTableColumn.CRMRecordIds,
      title: "Associated CRM Records",
      dataIndex: "associated_crm_records",
      width: 250,
      render: (associatedCRMRecords: AssociatedCRMRecord[]) => {
        if (associatedCRMRecords.length > 0) {
          const { crm_record_name, synced_crm_object, auto_associated } =
            associatedCRMRecords[0];

          return (
            <Typography.Text>
              <CRMRecordTag
                autoAssociated={auto_associated}
                crm={user?.organization?.crm_provider_name}
                name={`${crm_record_name} (${synced_crm_object.crm_object_label})`}
              />

              <Typography.Text italic>
                {associatedCRMRecords.length > 1 &&
                  ` and ${associatedCRMRecords.length - 1} more`}
              </Typography.Text>
            </Typography.Text>
          );
        }

        return "-";
      },
      filteredValue: savedFilters[MeetingTableColumn.CRMRecordIds],
      filterSearch: true,
      filters: meetings
        .map(({ associated_crm_records }) => associated_crm_records)
        .flat()
        .filter((value, index, self) => {
          const isUnique =
            self.findIndex((v) => v.uuid === value.uuid) === index;

          return isUnique && value.uuid && value.crm_record_name;
        })
        .map(({ crm_record_id, crm_record_name, synced_crm_object }) => {
          return {
            text: `${crm_record_name} (${synced_crm_object.crm_object_label})`,
            value: crm_record_id,
          };
        }),
    },
    {
      key: MeetingTableColumn.MeetingStatus,
      title: "Status",
      align: "center",
      width: 100,
      render: (_, meeting: Meeting) => <MeetingStatus meeting={meeting} />,
      filteredValue: savedFilters[MeetingTableColumn.MeetingStatus],
      filters: Object.values(MeetingStatusType).map((value) => {
        return { text: getFriendlyMeetingStatusLabel(value), value };
      }),
    },
  ];

  return (
    <Table<Meeting>
      showHeader
      title={title ? () => title : null}
      rowKey={({ uuid }) => uuid}
      loading={isLoading}
      dataSource={getFilteredMeetings(meetings, savedFilters)}
      columns={columns}
      locale={{ emptyText: empty }}
      onRow={({ uuid }) => {
        return {
          style: { cursor: "pointer" },
          onClick: () => onRowClick(uuid),
        };
      }}
      onChange={(_, filters) => {
        setSavedFilters({
          ...savedFilters,
          ...filters,
        } as MeetingTableFilters);
      }}
      scroll={{ x: 600 }}
    />
  );
}
