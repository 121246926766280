import React, { useState } from "react";
import {
  Space,
  Typography,
  Skeleton,
  Descriptions,
  Tooltip,
  Button,
  Tabs,
  Badge,
  Empty,
  Alert,
} from "antd";
import { useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import {
  useOrganization,
  useOrganizationUsers,
  useProduct,
} from "../../../util/data_hooks";
import { OrganizationUsers } from "./OrganizationUsers";
import { usePageTitle } from "../../hooks/usePageTitle";
import { InfoCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { InvitationModal } from "../shared/InvitationModal";
import { UserRole } from "../../../util/enums";

enum OrganizationUserTab {
  ConnectedUsers = "connected-users",
  InvitedUsers = "invited-users",
}

export function OrganizationView() {
  usePageTitle("Organization");

  const [activeTab, setActiveTab] = useState<OrganizationUserTab>(
    OrganizationUserTab.ConnectedUsers
  );
  const [invitationModalOpen, setInvitationModalOpen] =
    useState<boolean>(false);

  const { organizationUuid } = useParams();
  const { organization, isLoading, mutate } = useOrganization(organizationUuid);

  const { product, isLoading: isProductLoading } = useProduct(
    organization.product_uuid
  );

  const { users: orgUsers, isLoading: usersLoading } = useOrganizationUsers();

  const connectedUsers = orgUsers.filter(
    ({ pending_invitations }) => pending_invitations.length === 0
  );

  const invitedUsers = orgUsers.filter(
    ({ pending_invitations }) => pending_invitations.length > 0
  );

  const activeInvitedUsers = invitedUsers.filter(({ active }) => active);

  if (isLoading || usersLoading) return <Skeleton active />;

  return (
    <>
      <InvitationModal
        open={invitationModalOpen}
        organization={organization}
        setOpen={setInvitationModalOpen}
        afterSave={() => {
          mutate();
          setActiveTab(OrganizationUserTab.InvitedUsers);
        }}
      />

      <Space direction="vertical" style={{ display: "flex" }} size="large">
        <Descriptions
          title={
            <Typography.Title level={3} style={{ marginTop: 0 }}>
              {organization.name}
            </Typography.Title>
          }
          extra={
            <Button
              icon={<PlusCircleOutlined />}
              type="primary"
              onClick={() => setInvitationModalOpen(true)}
            >
              Invite Team Members
            </Button>
          }
        >
          <Descriptions.Item label="Collaborator Seats">
            <Typography.Text>
              {
                orgUsers.filter(({ roles }) =>
                  roles.includes(UserRole.Collaborator)
                ).length
              }{" "}
              / ∞
            </Typography.Text>
          </Descriptions.Item>

          <Descriptions.Item label="Standard User Seats">
            <Space>
              <Typography.Text>
                {organization.seats - organization.seats_available} /{" "}
                {organization.seats}
              </Typography.Text>

              <Tooltip
                title={
                  <>
                    Number of paid seats currently allocated
                    <br />
                    <br />
                    Contact hello@swyftai.com to adjust seat count
                  </>
                }
              >
                <InfoCircleOutlined />
              </Tooltip>
            </Space>
          </Descriptions.Item>

          <Descriptions.Item label="Plan">
            {isProductLoading ? (
              <Skeleton.Input active />
            ) : isEmpty(product) ? (
              "--"
            ) : (
              product.name
            )}
          </Descriptions.Item>
        </Descriptions>

        {organization.seats_available <= 0 && (
          <Alert
            showIcon
            type="info"
            message={
              <Typography.Text>
                All paid seats are currently allocated. Deactivate a paid seat
                or email{" "}
                <Typography.Link strong href="mailto:hello@swyftai.com">
                  hello@swyftai.com
                </Typography.Link>{" "}
                to add more seats.
              </Typography.Text>
            }
          />
        )}

        <Tabs
          activeKey={activeTab}
          defaultActiveKey={OrganizationUserTab.ConnectedUsers}
          onChange={(key) => setActiveTab(key as OrganizationUserTab)}
          items={[
            {
              key: OrganizationUserTab.ConnectedUsers,
              label: "Team Members",
              children: (
                <OrganizationUsers
                  organization={organization}
                  usersLoading={usersLoading}
                  users={connectedUsers}
                  mutateOrganization={mutate}
                />
              ),
            },
            {
              key: OrganizationUserTab.InvitedUsers,
              label: (
                <Space>
                  <Typography.Text>Pending Invites</Typography.Text>
                  <Badge count={activeInvitedUsers.length} />
                </Space>
              ),
              children:
                invitedUsers.length > 0 ? (
                  <OrganizationUsers
                    usersLoading={usersLoading}
                    organization={organization}
                    users={invitedUsers}
                    mutateOrganization={mutate}
                  />
                ) : (
                  <Empty description="No Pending Invites">
                    <Button onClick={() => setInvitationModalOpen(true)}>
                      Invite Team Members
                    </Button>
                  </Empty>
                ),
            },
          ]}
        />
      </Space>
    </>
  );
}
