import { Skeleton, Space, Table, Typography } from "antd";
import React from "react";
import { getFormattedDate } from "../../../helpers/date_helpers";
import { useWorkflowInstances } from "../../../util/data_hooks";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { WorkflowStatus } from "../../../util/enums";
import {
  LoadingOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
} from "@ant-design/icons";
import { blue, green, red } from "@ant-design/colors";

export function WorkflowInstances() {
  const navigate = useNavigate();
  const { workflowUuid } = useParams();

  const { workflowInstances, isLoading } = useWorkflowInstances(workflowUuid);

  if (isLoading) return <Skeleton />;

  return (
    <>
      <Outlet />

      <Typography.Title level={4}>
        Workflow Runs ({workflowInstances.length})
      </Typography.Title>

      <Table<any>
        style={{ paddingBottom: 150 }}
        rowKey={({ uuid }) => uuid}
        dataSource={workflowInstances}
        onRow={({ uuid }) => {
          return {
            onClick: () => navigate(`workflow-instances/${uuid}`),
            style: { cursor: "pointer" },
          };
        }}
        columns={[
          {
            title: "User",
            dataIndex: "user_email",
          },
          {
            title: "Triggered at",
            dataIndex: "created_at",
            render: (createdAt) => getFormattedDate(createdAt),
          },
          {
            title: "Status",
            dataIndex: "status",
            width: 250,
            render: (workflowStatus: WorkflowStatus) => {
              switch (workflowStatus) {
                case (WorkflowStatus.Triggered, WorkflowStatus.InProgress): {
                  return (
                    <Space>
                      <LoadingOutlined style={{ color: blue.primary }} spin />
                      <Typography.Text>In Progress...</Typography.Text>
                    </Space>
                  );
                }
                case WorkflowStatus.Complete: {
                  return (
                    <Space>
                      <CheckCircleTwoTone twoToneColor={green.primary} />
                      <Typography.Text>Complete</Typography.Text>
                    </Space>
                  );
                }
                case WorkflowStatus.Error: {
                  return (
                    <Space>
                      <CloseCircleTwoTone twoToneColor={red.primary} />
                      <Typography.Text>Error</Typography.Text>
                    </Space>
                  );
                }
                default: {
                  return "-";
                }
              }
            },
          },
        ]}
      />
    </>
  );
}
