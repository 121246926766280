import { CRMFieldType, FieldConfigurationAppendType } from "./enums";
import { AutoGeneratedField, CRMField, PicklistItem } from "./types";
import { diffWordsWithSpace } from "diff";
import { isEmpty } from "lodash";
import moment from "moment";

const decodeHtmlEntities = (string: string) => {
  const textarea = document.createElement("textarea");
  textarea.innerHTML = string;
  return textarea.value;
};

export const getContentPreview = (
  value: any,
  type: CRMFieldType,
  picklistOptions: PicklistItem[] = []
): string => {
  switch (type) {
    case CRMFieldType.Boolean:
      if (value === null || value === undefined) return "";
      return value ? "Yes" : "No";
    case CRMFieldType.Multipicklist:
      if (value === null) return "";
      return value.length > 0 ? value.sort().join(", ") : "";
    case CRMFieldType.Picklist: {
      const label = picklistOptions.find(
        (picklistOption) => picklistOption.value === value
      )?.label;

      return label || value || "";
    }
    case CRMFieldType.Date:
      if (value === null) return "";
      if (moment.isMoment(value)) {
        return value.format("MM/DD/YY");
      } else {
        return moment(value).format("MM/DD/YY");
      }
    case CRMFieldType.Time:
      if (value === null) return "";
      if (moment.isMoment(value)) {
        return value.format("h:mm A");
      } else {
        return moment(value).format("h:mm A");
      }
    case CRMFieldType.DateTime:
      if (value === null) return "";
      if (moment.isMoment(value)) {
        return value.format("dddd, MM/DD/YY, h:mm A");
      } else {
        return moment(value).format("dddd, MM/DD/YY, h:mm A");
      }
    case CRMFieldType.HTML:
      if (value === null) return "";

      return decodeHtmlEntities(
        value
          .replace(/<br\s*\/?>/gi, "\n")
          .replace(/<\/p>/gi, "\n")
          .replace(/<p[^>]*>/gi, "\n")
          .replace(/<\/?[^>]+(>|$)/g, "")
      );
    case CRMFieldType.Currency:
    case CRMFieldType.Double:
    case CRMFieldType.Percent:
      return value === null ? "" : value.toString();
    default:
      return value ? value.toString() : "";
  }
};

export const convertContentToJson = (type: CRMFieldType, value: any) => {
  switch (type) {
    case CRMFieldType.String:
    case CRMFieldType.EncryptedString:
    case CRMFieldType.Textarea:
    case CRMFieldType.HTML:
    case CRMFieldType.Double:
    case CRMFieldType.Currency:
    case CRMFieldType.Percent:
      return value;
    default:
      return JSON.stringify(value);
  }
};

export const isContentPresent = (value: any, type: CRMFieldType): boolean => {
  switch (type) {
    case CRMFieldType.Boolean:
      return value !== null;
    case CRMFieldType.Double:
    case CRMFieldType.Currency:
    case CRMFieldType.Percent:
      return value !== null;
    default:
      return !isEmpty(value);
  }
};

export const constructAppendedAutoGeneratedFieldContent = (
  crmField: CRMField,
  autoGeneratedField: AutoGeneratedField
): string | string[] => {
  const appendType = autoGeneratedField.field_configuration.append_type;
  const valuesPresent =
    !!crmField?.crm_field_value && !!autoGeneratedField?.auto_generated_content;

  if (!valuesPresent || appendType === FieldConfigurationAppendType.Overwrite) {
    return null;
  } else {
    switch (autoGeneratedField.field_configuration.field_type) {
      case CRMFieldType.String:
      case CRMFieldType.Textarea:
      case CRMFieldType.HTML:
        return appendType === FieldConfigurationAppendType.Bottom
          ? `${crmField.crm_field_value}\n\n${autoGeneratedField.auto_generated_content}`
          : `${autoGeneratedField.auto_generated_content}\n\n${crmField.crm_field_value}`;
      case CRMFieldType.Multipicklist:
        return [
          ...autoGeneratedField.auto_generated_content,
          ...crmField.crm_field_value,
        ].filter((value, index, array) => array.indexOf(value) === index);
    }
  }
};

export const getDiffStrings = (
  crmField: CRMField,
  autoGeneratedField: AutoGeneratedField
): {
  crmContent: string;
  autoGeneratedContent: string;
} => {
  return {
    crmContent: getContentPreview(
      crmField.crm_field_value,
      crmField.crm_field_type,
      crmField.crm_field_picklist_values
    ),
    autoGeneratedContent: getContentPreview(
      autoGeneratedField.content,
      autoGeneratedField.field_configuration.field_type,
      autoGeneratedField.field_configuration.picklist
    ),
  };
};

export interface DiffInformation {
  value: string;
  type: "equal" | "added" | "removed";
}

export const getAutoGeneratedFieldDiffs = (
  autoGeneratedField: AutoGeneratedField,
  crmField: CRMField
) => {
  const { crmContent, autoGeneratedContent } = getDiffStrings(
    crmField,
    autoGeneratedField
  );

  const diffArray = diffWordsWithSpace(crmContent, autoGeneratedContent);

  const computedDiff: {
    leftDiff: DiffInformation[];
    rightDiff: DiffInformation[];
  } = {
    leftDiff: [],
    rightDiff: [],
  };

  diffArray.forEach(({ added, removed, value }) => {
    if (removed) {
      computedDiff.leftDiff.push({
        value,
        type: "removed",
      });
    }

    if (added) {
      computedDiff.rightDiff.push({
        value,
        type: "added",
      });
    }

    if (!removed && !added && value) {
      const diffInfo: DiffInformation = {
        value,
        type: "equal",
      };

      computedDiff.rightDiff.push(diffInfo);
      computedDiff.leftDiff.push(diffInfo);
    }
  });

  return { leftDiff: computedDiff.leftDiff, rightDiff: computedDiff.rightDiff };
};

export const isFieldTypeAutomated = (fieldType: CRMFieldType): boolean => {
  return [
    CRMFieldType.String,
    CRMFieldType.Textarea,
    CRMFieldType.HTML,
    CRMFieldType.Currency,
    CRMFieldType.Double,
    CRMFieldType.Percent,
    CRMFieldType.Boolean,
    CRMFieldType.Picklist,
    CRMFieldType.Multipicklist,
    CRMFieldType.Date,
    CRMFieldType.DateTime,
  ].includes(fieldType);
};
