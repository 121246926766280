import React, { useEffect, useState } from "react";
import {
  Space,
  Typography,
  Skeleton,
  Button,
  Segmented,
  ConfigProvider,
  Table,
  Tooltip,
  Modal,
  ModalFuncProps,
  message,
} from "antd";
import { useParams } from "react-router-dom";
import { useOrganization, useTeams } from "../../../util/data_hooks";
import { Team } from "../../../util/types";
import { TeamEditorModal } from "./TeamEditorModal";
import { setTeamOptions } from "../../redux/features/team/teamSlice";
import { useAppDispatch } from "../../redux/hooks";
import { usePageTitle } from "../../hooks/usePageTitle";
import { blue } from "@ant-design/colors";
import type { ColumnsType } from "antd/es/table";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { setTeamsManagedByCRM } from "../../../util/api";
import { Integration } from "../../../util/enums";
import { PlusCircleOutlined } from "@ant-design/icons";

enum TeamManagementType {
  CRM_ROLE_MANAGED = "CRM Role Managed",
  USER_MANAGED = "User Managed",
}

export function Teams() {
  usePageTitle("Teams");
  const dispatch = useAppDispatch();

  const { organizationUuid } = useParams();

  const [modal, contextHolder] = Modal.useModal();

  const [selectedTeam, setSelectedTeam] = useState<Team>(null);
  const [isTeamEditorModalOpen, setIsTeamEditorModalOpen] =
    useState<boolean>(false);

  const [teamManagmentMode, setTeamManagementMode] =
    useState<TeamManagementType>(TeamManagementType.CRM_ROLE_MANAGED);

  const {
    organization,
    isLoading: organizationLoading,
    mutate: refreshOrganization,
  } = useOrganization(organizationUuid);

  const { teams, isLoading: teamsLoading, mutate: mutateTeams } = useTeams();

  const isLoading = organizationLoading || teamsLoading;

  useEffect(() => {
    if (!isLoading && teams && teams.length) {
      // Keep team state up to date
      dispatch(setTeamOptions(teams));
    }
  }, [teams]);

  useEffect(() => {
    if (organization) {
      setTeamManagementMode(
        organization.teams_managed_by_crm
          ? TeamManagementType.CRM_ROLE_MANAGED
          : TeamManagementType.USER_MANAGED
      );
    }
  }, [organization]);

  const patchTeamManagementMode = async (mode: TeamManagementType) => {
    try {
      if (mode === TeamManagementType.CRM_ROLE_MANAGED) {
        await setTeamsManagedByCRM(true);
      } else {
        await setTeamsManagedByCRM(false);
      }
      message.success("Team management set to " + mode);
      await refreshOrganization();
      await mutateTeams();
    } catch (e) {
      message.error(
        "Oops, something is wrong on our end! Please try again later."
      );
      throw e;
    }
  };

  const crmProviderName = (
    name: Integration.Salesforce | Integration.Hubspot
  ): string => {
    return name === Integration.Salesforce ? "Salesforce" : "HubSpot";
  };

  const confirmModalConfig = (
    managementMode: TeamManagementType
  ): ModalFuncProps => {
    if (managementMode === TeamManagementType.USER_MANAGED) {
      return {
        title: "Switch to User Managed Teams",
        content: (
          <Typography>
            <ul>
              <li>
                <Typography.Paragraph>
                  Switching to{" "}
                  <Typography.Text italic>User Managed Teams</Typography.Text>{" "}
                  means users will no longer be assigned to teams based on their{" "}
                  {crmProviderName(organization.crm_provider_name)} roles.
                  Changes in roles within{" "}
                  {crmProviderName(organization.crm_provider_name)} will not
                  update team assignments in Swyft.
                </Typography.Paragraph>
              </li>
              <li>
                <Typography.Paragraph>
                  New users will be associated with the default team.
                </Typography.Paragraph>
              </li>
              <li>
                <Typography.Paragraph>
                  Users will need to be manually added to teams in Swyft.
                </Typography.Paragraph>
              </li>
            </ul>
          </Typography>
        ),
        okText: "Switch to User Managed",
        cancelText: "Cancel",
        autoFocusButton: "cancel",
        onOk: async () => {
          await patchTeamManagementMode(TeamManagementType.USER_MANAGED);
        },
      };
    } else {
      return {
        title: "Switch to CRM Role Managed Teams",
        content: (
          <Typography>
            <ul>
              <li>
                <Typography.Paragraph>
                  Switching to{" "}
                  <Typography.Text italic>
                    CRM Role Managed Teams
                  </Typography.Text>{" "}
                  means users will be assigned to teams based on their{" "}
                  {crmProviderName(organization.crm_provider_name)} roles.
                  Changes in roles within{" "}
                  {crmProviderName(organization.crm_provider_name)} will
                  automatically update users&#39; team assignments in Swyft.
                </Typography.Paragraph>
              </li>
              <li>
                <Typography.Paragraph>
                  New users will be assigned a team based on their{" "}
                  {organization.crm_provider_name} role.
                </Typography.Paragraph>
              </li>
              <li>
                <Typography.Paragraph>
                  This action will{" "}
                  <Typography.Text strong italic>
                    immediately
                  </Typography.Text>{" "}
                  reassign users to group them by{" "}
                  {organization.crm_provider_name} roles.
                </Typography.Paragraph>
              </li>
            </ul>
          </Typography>
        ),
        okText: "Switch to CRM Role Managed",
        cancelText: "Cancel",
        autoFocusButton: "cancel",
        onOk: async () => {
          await patchTeamManagementMode(TeamManagementType.CRM_ROLE_MANAGED);
        },
      };
    }
  };

  if (isLoading) return <Skeleton />;

  const columns: ColumnsType<Team> = [
    {
      title: "Default",
      width: "8%",
      align: "center",
      dataIndex: "is_default",
      render: (isDefault: boolean) => {
        if (isDefault) {
          return (
            <Space>
              <Tooltip title="Default Team">
                <CheckCircleTwoTone />
              </Tooltip>
            </Space>
          );
        } else {
          return <></>;
        }
      },
    },
    {
      width: "25%",
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "CRM Roles Assigned",
      dataIndex: "crm_roles",
      width: "40%",
      render: (roles) => {
        return roles.map((role) => role.name).join(", ");
      },
      ellipsis: true,
    },
    {
      title: "Members",
      dataIndex: "users",
      render: (members) => {
        return members.map((member) => member.email).join(", ");
      },
      ellipsis: true,
    },
  ];

  return (
    <>
      <TeamEditorModal
        organization={organization}
        team={selectedTeam}
        open={isTeamEditorModalOpen}
        setOpen={setIsTeamEditorModalOpen}
        afterSave={async () => {
          mutateTeams();
          setIsTeamEditorModalOpen(false);
        }}
      />

      <Space direction="vertical" style={{ display: "flex" }} size="large">
        <div style={{ display: "flex" }}>
          <Typography.Title level={3} style={{ marginTop: 0 }}>
            {organization.name} Teams
          </Typography.Title>

          <ConfigProvider
            theme={{
              components: {
                Segmented: {
                  itemSelectedBg: blue.primary,
                  itemSelectedColor: "#ffffff",
                },
              },
            }}
          >
            <Segmented<TeamManagementType>
              style={{ marginLeft: "auto", marginRight: "1rem" }}
              options={[
                TeamManagementType.CRM_ROLE_MANAGED,
                TeamManagementType.USER_MANAGED,
              ]}
              value={teamManagmentMode}
              onChange={async (optionClicked) => {
                const confirmed = await modal.confirm(
                  confirmModalConfig(optionClicked)
                );
                if (confirmed) {
                  setTeamManagementMode(optionClicked);
                }
              }}
            />
          </ConfigProvider>

          <Button
            icon={<PlusCircleOutlined />}
            type="primary"
            onClick={() => {
              setSelectedTeam(null);
              setIsTeamEditorModalOpen(true);
            }}
          >
            Add New Team
          </Button>
        </div>

        <Table<Team>
          rowKey={({ uuid }) => uuid}
          loading={teamsLoading}
          dataSource={teams}
          columns={columns}
          onRow={({ uuid }) => ({
            style: { cursor: "pointer" },
            onClick: () => {
              const team = teams.find((team) => team.uuid === uuid);
              setSelectedTeam(team);
              setIsTeamEditorModalOpen(true);
            },
          })}
        />
      </Space>
      {contextHolder}
    </>
  );
}
