import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { FieldConfiguration } from "../../../../util/types";

export interface OpportunityTestSlice {
  opportunityFieldToTest: FieldConfiguration;
}

const initialState: OpportunityTestSlice = {
  opportunityFieldToTest: null,
};

export const opportunityTestSlice = createSlice({
  name: "opportunityFieldTest",
  initialState,
  reducers: {
    setOpportunityFieldToTest: (
      state,
      action: PayloadAction<FieldConfiguration>
    ) => {
      state.opportunityFieldToTest = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setOpportunityFieldToTest } = opportunityTestSlice.actions;

export default opportunityTestSlice.reducer;
