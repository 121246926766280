import { Button, Space, message } from "antd";
import React, { useState } from "react";
import {
  BaseEdge,
  EdgeLabelRenderer,
  EdgeProps,
  getSmoothStepPath,
  useReactFlow,
} from "reactflow";
import { CloseOutlined, CloseCircleOutlined } from "@ant-design/icons";
import {
  fetchDeleteWorkflowTransition,
  fetchUpdateWorkflowTransition,
} from "../../../util/api";
import { useWorkflow } from "../../../util/data_hooks";

export default function RemoveableEdge({
  id,
  source,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  data,
  label,
}: EdgeProps) {
  const { mutate } = useWorkflow(data?.workflowUuid);

  const { setEdges } = useReactFlow();
  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const [loading, setLoading] = useState<boolean>(false);

  const onEdgeClick = async () => {
    setLoading(true);

    try {
      if (data?.isConditionalEdge) {
        const body = data.isTargetConditionalEdge
          ? { target_workflow_state_uuid: null }
          : { fallback_target_workflow_state_uuid: null };

        await fetchUpdateWorkflowTransition(source, body);
      } else {
        await fetchDeleteWorkflowTransition(id);
      }

      setEdges((edges) => edges.filter((edge) => edge.id !== id));

      mutate();
    } catch (e) {
      message.error("An error occurred. Please try again.");
    }

    setLoading(false);
  };

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />

      <EdgeLabelRenderer>
        <div
          style={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            fontSize: 12,
            // everything inside EdgeLabelRenderer has no pointer events by default
            // if you have an interactive element, set pointer-events: all
            pointerEvents: "all",
          }}
          className="nodrag nopan"
        >
          {data?.isConditionalEdge ? (
            <Space direction="vertical" align="center">
              <Button
                loading={loading}
                icon={<CloseCircleOutlined />}
                onClick={onEdgeClick}
                size="small"
              >
                {label}
              </Button>
            </Space>
          ) : (
            <Button
              loading={loading}
              onClick={onEdgeClick}
              icon={<CloseOutlined />}
              shape="circle"
              size="small"
            />
          )}
        </div>
      </EdgeLabelRenderer>
    </>
  );
}
