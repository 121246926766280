import moment from "moment";
import { CRMFieldType } from "./enums";

export const DATE_FORMAT = "YYYY-MM-DD";
export const TIME_FORMAT = "HH:mm:ss.sssZ";
export const DATETIME_FORMAT = "YYYY-MM-DDTHH:mm:ssZ";

export const parseCRMValue = (
  crmFieldType: CRMFieldType,
  crmFieldValue: any
) => {
  let parsedValue;

  switch (crmFieldType) {
    case CRMFieldType.Date:
      parsedValue = crmFieldValue ? moment(crmFieldValue, DATE_FORMAT) : null;
      break;
    case CRMFieldType.DateTime:
      parsedValue = crmFieldValue
        ? moment(crmFieldValue, DATETIME_FORMAT)
        : null;
      break;
    case CRMFieldType.Time:
      parsedValue = crmFieldValue
        ? moment(crmFieldValue, TIME_FORMAT).utc()
        : null;
      break;
    default:
      parsedValue = crmFieldValue;
  }

  return parsedValue;
};
