import React, { useEffect } from "react";
import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import { useTeams } from "../../util/data_hooks";
import { setIsLoading, setTeamOptions } from "../redux/features/team/teamSlice";
import { useAppDispatch } from "../redux/hooks";

export function Settings() {
  const dispatch = useAppDispatch();

  const { teams, isLoading: teamsLoading } = useTeams();

  useEffect(() => {
    if (!teamsLoading && teams && teams.length) {
      dispatch(setTeamOptions(teams)); // Updates team select dropdowns and sets default team as selected
    }
  }, [teams]);

  useEffect(() => {
    dispatch(setIsLoading(teamsLoading));
  }, [teamsLoading]);

  return (
    <Layout style={{ height: "100%" }}>
      <Layout style={{ height: "100%", marginTop: "1rem" }}>
        <Layout.Content>
          <Outlet />
        </Layout.Content>
      </Layout>
    </Layout>
  );
}
