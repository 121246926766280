import React, { ReactNode } from "react";
import { Button, Collapse, Timeline, Typography } from "antd";
import { SyncOutlined, FileAddOutlined } from "@ant-design/icons";
import { filter, isNull } from "lodash";
import { FieldConfigurationPlaygroundResponse } from "../../../../../util/types";
import { getContentPreview } from "../../../../../util/auto_generated_field_helpers";
import Markdown from "react-markdown";

function MarkdownWrapper({ children }: { children: any }) {
  return (
    <Markdown
      components={{
        p: ({ ...rest }) => {
          return (
            <p
              style={{ fontSize: 14, marginTop: 0, lineHeight: 1.5 }}
              {...rest}
            />
          );
        },
        li: ({ ...rest }) => (
          <li
            style={{
              fontSize: 14,
              lineHeight: 1.5,
              paddingBottom: 5,
            }}
            {...rest}
          />
        ),
        ul: ({ ...rest }) => (
          <ul style={{ paddingLeft: 10, marginTop: 0 }} {...rest} />
        ),
        ol: ({ ...rest }) => (
          <ul style={{ paddingLeft: 10, marginTop: 0 }} {...rest} />
        ),
      }}
    >
      {children}
    </Markdown>
  );
}

interface Props {
  testResult: FieldConfigurationPlaygroundResponse;
  meetingSummary: string;
  retest: () => void;
  pickDifferentMeeting: () => void;
}

const getScreeningStage = (
  result: FieldConfigurationPlaygroundResponse
): {
  color: string;
  text: ReactNode;
} => {
  if (result.screening_meta) {
    if (result.screening_meta.screening_passed) {
      return {
        color: "green",
        text: (
          <MarkdownWrapper>
            {result.generated_content.screening_context || "--"}
          </MarkdownWrapper>
        ),
      };
    }

    return {
      color: "red",
      text: (
        <MarkdownWrapper>
          {result.generated_content.screening_context || "--"}
        </MarkdownWrapper>
      ),
    };
  }

  return {
    color: "gray",
    text: "Screening omitted",
  };
};

export function ReviewResult({
  testResult,
  meetingSummary,
  retest,
  pickDifferentMeeting,
}: Props) {
  const summaryNode = (
    <Collapse
      expandIconPosition="start"
      items={[
        {
          key: "summary",
          label: <Typography.Text strong>Summary</Typography.Text>,
          children: <MarkdownWrapper>{meetingSummary || "--"}</MarkdownWrapper>,
        },
      ]}
    />
  );

  const screeningStage = getScreeningStage(testResult);
  const screeningNode = (
    <Collapse
      expandIconPosition="start"
      defaultActiveKey={"screening"}
      items={[
        {
          key: "screening",
          label: <Typography.Text strong>Screening</Typography.Text>,
          children: <>{screeningStage.text}</>,
        },
      ]}
    />
  );

  const textGenerationNode = testResult.meta ? (
    <Collapse
      expandIconPosition="start"
      defaultActiveKey={"text"}
      items={[
        {
          key: "text",
          label: <Typography.Text strong>Generated Text</Typography.Text>,
          children: (
            <MarkdownWrapper>
              {testResult.generated_content.context || "--"}
            </MarkdownWrapper>
          ),
        },
      ]}
    />
  ) : null;

  const contentNode = (
    <Collapse
      expandIconPosition="start"
      defaultActiveKey={"insight"}
      items={[
        {
          key: "insight",
          label: <Typography.Text strong>Generated Insight</Typography.Text>,
          children: (
            <MarkdownWrapper>
              {getContentPreview(
                testResult.generated_content.content,
                testResult.generated_content.field_configuration.field_type,
                testResult.generated_content.field_configuration.picklist
              ) || "--"}
            </MarkdownWrapper>
          ),
        },
      ]}
    />
  );

  const events = [
    {
      children: summaryNode,
    },
    {
      children: screeningNode,
      color: screeningStage.color,
    },
    {
      children: textGenerationNode,
    },
    {
      children: contentNode,
    },
  ];
  filter;

  return (
    <div>
      <div style={{ display: "flex", marginBottom: "2rem" }}>
        <Button
          style={{ marginRight: "0.5rem" }}
          onClick={pickDifferentMeeting}
          icon={<FileAddOutlined />}
        >
          Select different meeting
        </Button>
        <Button type="primary" onClick={retest} icon={<SyncOutlined />}>
          Retest same meeting
        </Button>
      </div>
      <Timeline items={filter(events, (event) => !isNull(event.children))} />
    </div>
  );
}
