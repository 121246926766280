import React from "react";
import { Avatar, Space, Typography } from "antd";
import {
  red,
  volcano,
  orange,
  gold,
  green,
  cyan,
  blue,
  purple,
  magenta,
} from "@ant-design/colors";

const getAvatarBackgroundColor = (email: string): string => {
  const alphabet = "abcdefghijklmnopqrstuvwxyz";

  let charIndexSum = 0;

  email.split("").forEach((char) => {
    charIndexSum += alphabet.indexOf(char.toLowerCase());
  });

  const colors = [
    red[4],
    volcano[4],
    orange[4],
    gold[4],
    green[4],
    cyan[4],
    blue[4],
    purple[4],
    magenta[4],
  ];

  const index = charIndexSum % colors.length;

  return colors[index];
};

interface EmailAvatarLabelProps {
  email: string;
}

export function EmailAvatarLabel({ email }: EmailAvatarLabelProps) {
  return (
    <Space>
      <Avatar
        size="small"
        style={{
          backgroundColor: getAvatarBackgroundColor(email),
        }}
      >
        {email[0].toUpperCase()}
      </Avatar>

      <Typography.Text>{email}</Typography.Text>
    </Space>
  );
}
