import { useParams } from "react-router-dom";
import { useWorkflowState } from "../../../../util/data_hooks";
import { CRMFieldType } from "../../../../util/enums";
import { parseVariable, WorkflowVariableType } from "./helpers";

const deepGet = (obj, keys) => keys.reduce((xs, x) => xs?.[x] ?? null, obj);

export function useWorkflowVariableDescription(variable: string): {
  type: CRMFieldType;
  description: string;
  options: { label: string; value: string }[];
  isLoading: boolean;
} {
  const { workflowUuid } = useParams();
  const { variableType, attributes } = parseVariable(variable);

  const workflowStateUuid = WorkflowVariableType.Action ? attributes[0] : null;

  const { workflowState, isLoading } = useWorkflowState(
    workflowUuid,
    workflowStateUuid
  );

  if (variableType === WorkflowVariableType.Datetime) {
    return {
      isLoading: isLoading,
      type: CRMFieldType.DateTime,
      description: "Date/time relative to when the workflow is triggered.",
      options: [],
    };
  } else if (variableType === WorkflowVariableType.Action) {
    const pathKeys = attributes.slice(1);
    const attribute = deepGet(workflowState?.response_attributes, pathKeys);

    return {
      isLoading: isLoading,
      type: attribute?.type,
      description: attribute?.description,
      options: attribute?.options || [],
    };
  }

  return { isLoading, type: null, description: null, options: [] };
}
