import React, { useEffect } from "react";
import { Space, Typography, Empty, Row, Col, Button, Spin } from "antd";
import { useMeetings, useUser } from "../../util/data_hooks";
import {
  DEFAULT_FILTERS,
  MeetingTableFilters,
  MeetingsTable,
  getFilteredMeetings,
  getFiltersCount,
} from "../shared/MeetingsTable";
import { meetingPath } from "../../util/path_builder";
import { useNavigate } from "react-router-dom";
import { getRelativeTime } from "../../helpers/date_helpers";
import { CheckCircleTwoTone, CloseCircleFilled } from "@ant-design/icons";
import { green } from "@ant-design/colors";
import { UserRole } from "../../util/enums";
import { useLocalStorage } from "@uidotdev/usehooks";
import { usePageTitle } from "../hooks/usePageTitle";
import { UploadMeetingRecordingButton } from "../shared/UploadMeetingRecordingButton";

const MY_MEETINGS_TABLE_FILTERS = "MY_MEETINGS_TABLE_FILTERS";

export function Meetings() {
  usePageTitle("My Meetings");

  const navigate = useNavigate();

  const [savedFilters, setSavedFilters] = useLocalStorage<MeetingTableFilters>(
    MY_MEETINGS_TABLE_FILTERS
  );

  const filtersCount = getFiltersCount(savedFilters);

  const {
    meetings,
    isLoading: isMeetingsLoading,
    meta,
    mutate: mutateMeetings,
  } = useMeetings({
    shouldPaginate: false, // Disable pagination for now until we introduce server-side filtering
  });

  const { user, isLoading: isUserLoading } = useUser();

  const isLoading = isUserLoading || isMeetingsLoading;

  useEffect(() => {
    if (isUserLoading) return;
    if (user.roles.includes(UserRole.User)) return;

    navigate("/dashboard");
  }, [isUserLoading]);

  return (
    <Space direction="vertical" style={{ display: "flex" }} size="large">
      <Row justify="space-between" align="middle">
        <Col>
          <Typography.Title level={3}>My Meetings</Typography.Title>

          {meta.is_syncing ? (
            <Typography.Text italic>Syncing meetings...</Typography.Text>
          ) : (
            <Space>
              <Typography.Text italic>
                Last synced {getRelativeTime(meta.synced_at)}
              </Typography.Text>

              <CheckCircleTwoTone twoToneColor={green["primary"]} />
            </Space>
          )}
        </Col>

        <Col>
          <UploadMeetingRecordingButton
            requestPredictions
            onSuccess={mutateMeetings}
          />
        </Col>
      </Row>

      <MeetingsTable
        title={
          filtersCount > 0 ? (
            <Row justify="space-between" align="middle">
              <Col>
                <Typography.Text style={{ fontSize: 16 }}>
                  <b>{filtersCount}</b>{" "}
                  {filtersCount === 1 ? "Filter" : "Filters"} Applied
                </Typography.Text>
              </Col>

              <Col>
                <Button
                  type="primary"
                  icon={<CloseCircleFilled />}
                  onClick={() => setSavedFilters(DEFAULT_FILTERS)}
                >
                  Clear Filters
                </Button>
              </Col>
            </Row>
          ) : null
        }
        meetings={getFilteredMeetings(meetings, savedFilters)}
        user={user}
        savedFiltersKey={MY_MEETINGS_TABLE_FILTERS}
        isLoading={isLoading}
        onRowClick={(meetingUuid) => navigate(meetingPath(meetingUuid))}
        empty={
          <div style={{ margin: "100px 0px" }}>
            {isLoading ? (
              <Typography.Text>Loading...</Typography.Text>
            ) : (
              <Empty
                description={
                  <Spin spinning={meta.is_syncing}>
                    <Typography.Title level={5}>
                      {meta?.is_syncing
                        ? "Syncing meetings..."
                        : "No meetings found"}
                    </Typography.Title>
                  </Spin>
                }
              />
            )}
          </div>
        }
      />
    </Space>
  );
}
