import React, { useState } from "react";
import { Space, Table, TablePaginationConfig, Typography, Button } from "antd";
import { Product } from "../../../util/types";
import { useProducts } from "../../../util/data_hooks";
import { ProductEditorModal } from "./ProductEditorModal";
import type { ColumnsType } from "antd/es/table";

export function Products() {
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(20);
  const [isProductEditorModalOpen, setIsProductEditorModalOpen] =
    useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<Product>(null);

  const {
    products,
    meta,
    isLoading,
    mutate: mutateProducts,
  } = useProducts(page, pageSize);

  const columns: ColumnsType<Product> = [
    {
      title: "Name",
      dataIndex: "name",
      render: (value, product) => {
        return (
          <Typography.Link
            onClick={() => {
              setSelectedProduct(product);
              setIsProductEditorModalOpen(true);
            }}
          >
            {value}
          </Typography.Link>
        );
      },
    },
    {
      title: "Automations",
      dataIndex: "max_field_automations",
    },
  ];

  const pagination: TablePaginationConfig = {
    current: page + 1,
    pageSize: pageSize,
    total: meta?.total,
    onChange: (newPage, newPageSize) => {
      if (page !== newPage - 1) {
        setPage(newPage - 1);
      } else {
        setPageSize(newPageSize);
      }
    },
    pageSizeOptions: [10, 20, 50, 100],
    showSizeChanger: true,
  };

  const onProductEditorModalClose = () => {
    setIsProductEditorModalOpen(false);
  };

  return (
    <>
      <Space direction="vertical" style={{ display: "flex" }} size="large">
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography.Title level={3} style={{ marginTop: 0 }}>
            Products
          </Typography.Title>

          <Button
            type="primary"
            style={{ marginLeft: "auto" }}
            onClick={() => setIsProductEditorModalOpen(true)}
          >
            Create Product
          </Button>
        </div>

        <Table<Product>
          rowKey={({ uuid }) => uuid}
          loading={isLoading}
          dataSource={products}
          columns={columns}
          pagination={pagination}
        />
      </Space>

      <ProductEditorModal
        product={selectedProduct}
        open={isProductEditorModalOpen}
        onClose={onProductEditorModalClose}
        afterSave={mutateProducts}
      />
    </>
  );
}
