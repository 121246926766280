import { useEffect } from "react";

export function usePageTitle(title: string | undefined | null) {
  useEffect(() => {
    if (title) document.title = `${title} | Swyft AI`;

    return () => {
      document.title = "Swyft AI";
    };
  }, [title, window.location.href]);
}
