import React from "react";
import { FieldConfiguration } from "../../../../../util/types";
import { Col, Form, Select, Space, Typography } from "antd";
import {
  CRMFieldType,
  FieldConfigurationAppendType,
} from "../../../../../util/enums";
import { InfoCircleOutlined } from "@ant-design/icons";

interface Props {
  required: boolean;
  fieldConfiguration: FieldConfiguration;
}

const topOption = {
  label: "Append to Top",
  value: FieldConfigurationAppendType.Top,
};
const bottomOption = {
  label: "Append to Bottom",
  value: FieldConfigurationAppendType.Bottom,
};
const overwriteOption = {
  label: "Overwrite Existing Value",
  value: FieldConfigurationAppendType.Overwrite,
};
const smartOption = {
  label: "Combine Intelligently",
  value: FieldConfigurationAppendType.Smart,
};
const mergeOption = {
  label: "Merge",
  value: FieldConfigurationAppendType.Merge,
};
const APPEND_MAP = {
  [CRMFieldType.String]: [
    topOption,
    bottomOption,
    overwriteOption,
    smartOption,
  ],
  [CRMFieldType.Textarea]: [
    topOption,
    bottomOption,
    overwriteOption,
    smartOption,
  ],
  [CRMFieldType.HTML]: [topOption, bottomOption, overwriteOption, smartOption],
  [CRMFieldType.Multipicklist]: [overwriteOption, mergeOption],
};

export function AppendDropdown({ required, fieldConfiguration }: Props) {
  if (
    !fieldConfiguration.crm_managed ||
    !Object.keys(APPEND_MAP).includes(fieldConfiguration.field_type)
  ) {
    return <></>;
  }

  return (
    <Col span={12}>
      <Form.Item
        label={
          <Space>
            <Typography.Text>How should the field be saved?</Typography.Text>
            <Typography.Link
              type="secondary"
              href={
                "https://help.swyftai.com/en/articles/9821424-field-configuration-settings#h_99a9cfdbf7"
              }
              target="_blank"
            >
              <InfoCircleOutlined />
            </Typography.Link>
          </Space>
        }
        name="append_type"
        rules={[{ required: required }]}
      >
        <Select options={APPEND_MAP[fieldConfiguration.field_type]} />
      </Form.Item>
    </Col>
  );
}
