import React, { useState } from "react";
import {
  Alert,
  Breadcrumb,
  Button,
  Card,
  Descriptions,
  Divider,
  message,
  Modal,
  Skeleton,
  theme,
  Tooltip,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";

import {
  useOrganizationFieldConfiguration,
  useTeam,
  useTeamFieldConfigurationGroups,
} from "../../../../util/data_hooks";
import { FieldConfigurationForm } from "./FieldConfigurationForm";
import { TestingCard } from "./field_testing/TestingCard";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { deactivateFieldConfiguration } from "../../../../util/api";
import { get } from "lodash";
import { FieldConfigEditorModal } from "../FieldConfigEditorModal";
import { usePageTitle } from "../../../hooks/usePageTitle";
import { RemainingAutomationsBanner } from "./RemainingAutomationsBanner";

export function FieldConfigurationPage() {
  const { fieldUuid } = useParams();
  const { token } = theme.useToken();
  const navigate = useNavigate();
  const [isFieldConfigEditModalOpen, setFieldConfigEditModalOpen] =
    useState(false);

  const {
    fieldConfiguration,
    isLoading: isFieldConfigurationLoading,
    mutate: refreshField,
  } = useOrganizationFieldConfiguration(fieldUuid);

  const { team, isLoading: isTeamLoading } = useTeam(
    fieldConfiguration?.team_uuid
  );

  const {
    fieldConfigurationGroups,
    isLoading: isFieldConfigurationGroupsLoading,
  } = useTeamFieldConfigurationGroups(fieldConfiguration?.team_uuid);

  usePageTitle(fieldConfiguration?.label);

  const isLoading =
    isFieldConfigurationLoading ||
    isTeamLoading ||
    isFieldConfigurationGroupsLoading;

  const showDeactivateConfirm = () => {
    Modal.confirm({
      title: `Deactivate ${fieldConfiguration.label}?`,
      icon: <DeleteOutlined style={{ color: token.colorError }} />,
      content:
        "Field configuration will be deactivated. Workflows depending on this field may not function correctly.",
      okText: "Deactivate",
      okType: "danger",
      cancelText: "Cancel",
      onOk: async () => {
        try {
          await deactivateFieldConfiguration(fieldConfiguration.uuid);
          message.success("Field configuration deactivated!");
          navigate(`/dashboard/settings/field-configurations`);
        } catch (err) {
          const defaultMessage =
            "Oops, something is wrong on our end! Please try again later.";

          const is500 = get(err, "response.status", 500) >= 500;

          message.error(
            is500
              ? defaultMessage
              : get(err, "response.data.error", defaultMessage)
          );
        }
      },
    });
  };

  if (isLoading) return <Skeleton active />;

  return (
    <>
      <RemainingAutomationsBanner team={team} />

      <Breadcrumb
        style={{ marginBottom: 20 }}
        items={[
          {
            title: (
              <a
                onClick={() =>
                  navigate(`/dashboard/settings/field-configurations`)
                }
              >
                Field Configurations
              </a>
            ),
          },
          { title: fieldConfiguration.label },
        ]}
      />

      {fieldConfiguration.prompt_stale && (
        <Alert
          message="Field recently changed in CRM. Ensure that Swyft configuration is correct. Revalidate or edit to clear this warning."
          type="warning"
          style={{ marginBottom: "1rem" }}
        />
      )}

      <Card
        title={
          fieldConfiguration.crm_managed ? "CRM Field Info" : "Swyft Field Info"
        }
        extra={
          !fieldConfiguration.crm_managed && (
            <div>
              <Button
                type="primary"
                icon={<EditOutlined />}
                style={{ marginRight: "0.5rem" }}
                onClick={() => setFieldConfigEditModalOpen(true)}
              />
              {fieldConfiguration.standard_field ? (
                <Tooltip title="Standard Swyft fields cannot be deactivated.">
                  <Button
                    type="primary"
                    danger
                    icon={<DeleteOutlined />}
                    disabled
                  />
                </Tooltip>
              ) : (
                <Button
                  type="primary"
                  danger
                  icon={<DeleteOutlined />}
                  onClick={showDeactivateConfirm}
                />
              )}
            </div>
          )
        }
      >
        <Descriptions>
          {fieldConfiguration.synced_crm_object && (
            <Descriptions.Item label="CRM Object">
              {fieldConfiguration.synced_crm_object.crm_object_label}
            </Descriptions.Item>
          )}

          <Descriptions.Item label="Label">
            {fieldConfiguration.label}
          </Descriptions.Item>
          <Descriptions.Item label="Name">
            {fieldConfiguration.name}
          </Descriptions.Item>
          <Descriptions.Item label="Type">
            {fieldConfiguration.field_type}
          </Descriptions.Item>
          <Descriptions.Item label="Length">
            {fieldConfiguration.length}
          </Descriptions.Item>
          <Descriptions.Item label="Is Required">
            {fieldConfiguration.required ? "True" : "False"}
          </Descriptions.Item>
        </Descriptions>
      </Card>
      <Divider />

      <div style={{ display: "flex" }}>
        <div style={{ width: "50%", marginRight: "0.5rem" }}>
          <FieldConfigurationForm
            fieldConfiguration={fieldConfiguration}
            refreshFieldConfiguration={refreshField}
          />
        </div>

        <div style={{ width: "50%", marginLeft: "0.5rem" }}>
          <TestingCard />
        </div>
      </div>

      <FieldConfigEditorModal
        open={isFieldConfigEditModalOpen}
        fieldConfigurationGroups={fieldConfigurationGroups}
        setOpen={setFieldConfigEditModalOpen}
        fieldConfiguration={fieldConfiguration}
        onEdit={refreshField}
      />
    </>
  );
}
