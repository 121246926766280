import { Form, Modal, Select, message } from "antd";
import React, { useState } from "react";
import { UserRole } from "../../../util/enums";
import { createInvitations } from "../../../util/api";
import { Organization } from "../../../util/types";
import { get } from "lodash";
import { RolesTooltip } from "./RolesTooltip";

enum InvitationFormFieldName {
  Emails = "emails",
  Roles = "roles",
}

export function InvitationModal({
  open,
  organization,
  setOpen,
  afterSave,
}: {
  open: boolean;
  organization: Organization;
  setOpen: (open: boolean) => void;
  afterSave: () => void;
}) {
  const [form] = Form.useForm();

  const onClose = () => setOpen(false);

  const [saving, setSaving] = useState<boolean>(false);

  const [emailDropdownOpen, setEmailDropdownOpen] = useState<boolean>(false);

  const handleSubmit = async () => {
    await form.validateFields();

    setSaving(true);

    try {
      const values = form.getFieldsValue();

      const { emails, roles } = values;

      await createInvitations(organization.uuid, emails, roles);

      message.success(
        emails.length === 1 ? "Invitation Sent" : "Invitations Sent"
      );

      afterSave();
      setOpen(false);

      form.resetFields();
    } catch (e) {
      const defaultMessage =
        "Oops, something is wrong on our end! Please try again later.";

      const is500 = get(e, "response.status", 500) >= 500;
      message.error(
        is500 ? defaultMessage : get(e, "response.data.error", defaultMessage)
      );
    }

    setSaving(false);
  };

  return (
    <Modal
      open={open}
      onCancel={onClose}
      title="Invite Team Members"
      okText="Invite"
      okButtonProps={{ loading: saving }}
      onOk={handleSubmit}
      maskClosable={false}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="Team Member Emails"
          name={InvitationFormFieldName.Emails}
          rules={[{ required: true }]}
        >
          <Select
            style={{ width: "100%" }}
            mode="tags"
            placeholder="Enter emails or paste a list of emails separated by commas"
            onChange={() => setEmailDropdownOpen(false)}
            onSearch={() => setEmailDropdownOpen(true)}
            tokenSeparators={[","]}
            notFoundContent={null}
            open={emailDropdownOpen}
            onInputKeyDown={(e) => {
              if (e.key === "Enter") e.stopPropagation();
            }}
          />
        </Form.Item>

        <Form.Item
          label="User Roles"
          name={InvitationFormFieldName.Roles}
          rules={[{ required: true }]}
          extra={<RolesTooltip />}
        >
          <Select
            placeholder="Select roles. You can always change this later."
            mode="multiple"
            style={{ width: "100%" }}
            options={[
              { value: UserRole.User, label: "Standard User (Paid Seat)" },
              { value: UserRole.Collaborator, label: "Collaborator" },
              { value: UserRole.Admin, label: "Admin" },
            ]}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
