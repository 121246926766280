import React, { useEffect, useRef } from "react";
import { Col, Row, Select, Spin, Typography } from "antd";
import { getFriendlyVariableName } from "../../../util/helpers";
import { blue } from "@ant-design/colors";
import { useUpstreamWorkflowStates } from "../../../util/useUpstreamWorkflowStates";
import { WorkflowEditorQueryParam } from "../../../shared/WorkflowStateEditorDrawer";
import { useWorkflow } from "../../../../../../util/data_hooks";
import { useParams, useSearchParams } from "react-router-dom";
import { CRMFieldType } from "../../../../../../util/enums";

const flattenArray = (
  inputArray
): { path: string; type: CRMFieldType; description: string }[] => {
  const results: { path: string; type: CRMFieldType; description: string }[] =
    [];

  const recurse = (current: any, path: string) => {
    if (current?.type && Object.values(CRMFieldType).includes(current.type)) {
      results.push({
        path,
        type: current.type,
        description: current.description,
      });
    } else if (Array.isArray(current)) {
      current.forEach((item) => recurse(item, `${path}`));
    } else if (typeof current === "object" && current !== null) {
      Object.keys(current).forEach((key) => {
        recurse(current[key], path ? `${path}.${key}` : key);
      });
    }
  };

  recurse(inputArray, "");
  return results;
};

interface DynamicVariableSelectProps {
  value?: string;
  isMultiSelect?: boolean;
  staticOptions?: { label: string; value: string }[];
  fieldType?: CRMFieldType;
  autoFocus?: boolean;
  onChange: (value: string) => void;
}

export function DynamicVariableSelect({
  value,
  isMultiSelect = false,
  staticOptions = [],
  fieldType,
  autoFocus = false,
  onChange,
}: DynamicVariableSelectProps) {
  const { workflowUuid } = useParams();
  const { workflow } = useWorkflow(workflowUuid);

  const selectRef = useRef<any>();

  const [searchParams] = useSearchParams();

  const [upstreamWorkflowStatesLoading, upstreamWorkflowStates] =
    useUpstreamWorkflowStates({
      workflow,
      workflowStateUuid: searchParams.get(
        WorkflowEditorQueryParam.WorkflowStateUuid
      ),
      includeCurrentWorkflowState:
        searchParams.get(
          WorkflowEditorQueryParam.IsCurrentWorkflowStateUpstream
        ) === "true",
    });

  const getDropdownOptions = () => {
    return upstreamWorkflowStates
      .map(({ uuid, name, action_type, response_attributes }, i) => {
        const flattenedResponseAttributes = flattenArray(response_attributes);
        const filteredOptions = flattenedResponseAttributes.filter(
          ({ type }) => {
            if (!fieldType) return true;
            return type === fieldType;
          }
        );

        if (filteredOptions.length === 0) return;

        return {
          label: `Step ${i + 1}: ${name} (Variables)`,
          options: filteredOptions.map(({ path, description }) => {
            return {
              key: `${uuid}-${path}`,
              label: path,
              value: `{{action.${uuid}.${path}}}`,
              action_type,
              path,
              workflowStateName: name,
              description,
            };
          }),
        };
      })
      .filter(Boolean);
  };

  useEffect(() => {
    if (autoFocus && selectRef?.current) selectRef.current.focus();
  }, [autoFocus]);

  return (
    <Select
      ref={selectRef}
      defaultOpen={autoFocus}
      allowClear
      labelRender={({ label, value }) => {
        const friendlyVariableName = getFriendlyVariableName(value as string);

        return friendlyVariableName?.length > 0 ? (
          <Typography.Text
            strong
            style={{
              backgroundColor: blue[0],
              padding: 2,
              borderRadius: 2,
            }}
          >
            {`{{${friendlyVariableName}}}`}
          </Typography.Text>
        ) : (
          label
        );
      }}
      optionRender={({ label, data }) => {
        if (data.description) {
          return (
            <Row gutter={[0, 6]} style={{ margin: "8px 0px" }}>
              <Col span={24}>
                <Typography.Text
                  style={{ whiteSpace: "normal", wordBreak: "break-word" }}
                >
                  {data.description}
                </Typography.Text>
              </Col>

              <Col span={24}>
                <Typography.Text
                  type="secondary"
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "block",
                  }}
                >
                  {`{{${data.path}}}`}
                </Typography.Text>
              </Col>
            </Row>
          );
        } else {
          return label;
        }
      }}
      loading={upstreamWorkflowStatesLoading}
      style={{ width: "100%" }}
      value={value}
      optionFilterProp="label"
      placeholder={isMultiSelect ? "Select variables" : "Select a variable"}
      mode={isMultiSelect ? "multiple" : null}
      showSearch
      filterOption={(input, option: any) => {
        const sanitizedDescription = option.description?.toLowerCase() || "";
        const sanitizedLabel = option.label.toLowerCase();
        const sanitizedInput = input.toLowerCase();

        return (
          sanitizedDescription.includes(sanitizedInput) ||
          sanitizedLabel.includes(sanitizedInput)
        );
      }}
      onChange={onChange}
      notFoundContent={
        upstreamWorkflowStatesLoading ? (
          <Spin size="small" style={{ padding: 8, width: "100%" }} />
        ) : (
          <Typography.Text>No results found.</Typography.Text>
        )
      }
      options={[
        ...(staticOptions.length
          ? [{ label: "Default Options", options: staticOptions }]
          : []),
        ...(getDropdownOptions().length ? getDropdownOptions().reverse() : []),
      ]}
    />
  );
}
